import { ProductCode } from '@fizjo-pro/data-account';
import { Permission } from '@fizjo-pro/data-auth';
import { MenuItem, PrimeIcons } from 'primeng/api';

export const settingsMenuItems: MenuItem[] = [
  {
    icon: 'pi pi-cog',
    items: [
      {
        icon: 'pi pi-briefcase',
        label: 'menu.settings.companyData',
        routerLink: ['/settings', 'app-settings'],
        state: {
          permission: Permission.CompanyDataEditor,
        },
      },
      {
        icon: 'pi pi-users',
        label: 'menu.settings.appUsers',
        routerLink: ['/settings', 'app-users'],
        state: {
          permission: Permission.UserEditor,
          disabledForLicenses: [ProductCode.Trial, ProductCode.OneUser, ProductCode.Base],
        },
      },
      // {
      //   icon: 'pi pi-bell',
      //   label: 'menu.settings.notifications',
      //   routerLink: ['/settings', 'notifications'],
      //   state: {
      //     feature: FeatEnum.Notifications,
      //   },
      // },
      {
        icon: 'pi pi-verified',
        label: 'menu.settings.gdpr',
        routerLink: ['/settings', 'gdpr'],
        state: {
          permission: Permission.UserEditor,
        },
      },
    ],
    label: 'menu.settings.app',
  },
  {
    icon: 'pi pi-user',
    items: [
      {
        icon: PrimeIcons.USER_EDIT,
        label: 'menu.settings.myData',
        routerLink: ['/settings', 'user', 'user-data'],
      },
      {
        icon: 'pi pi-lock',
        label: 'menu.settings.changePassword',
        routerLink: ['/settings', 'user', 'change-password'],
      },
      {
        icon: PrimeIcons.MOBILE,
        label: 'menu.settings.pinCode',
        routerLink: ['/settings', 'user', 'pin-code'],
      },
    ],
    label: 'menu.settings.mySettings',
  },
  {
    label: 'menu.settings.definitions',
    icon: 'pi pi-list',
    items: [
      {
        icon: 'pi pi-wrench',
        label: 'menu.settings.equipment',
        routerLink: ['/settings', 'equipment'],
      },
      {
        icon: 'pi pi-inbox',
        label: 'menu.settings.procedures',
        routerLink: ['/settings', 'procedures'],
      },
      {
        icon: 'pi pi-th-large',
        label: 'menu.settings.formTemplates',
        routerLink: ['/settings', 'form-template'],
      },
    ],
  },
  {
    icon: 'pi pi-database',
    label: 'menu.settings.stats',
    routerLink: ['/settings', 'stats'],
    state: {
      isDisabledWhenNoValidLicense: true,
    },
  },
  {
    icon: 'pi pi-info-circle',
    label: 'menu.settings.about',
    routerLink: ['/app-info'],
  },
  {
    icon: 'pi pi-book',
    label: 'menu.settings.terms',
    routerLink: ['/app-info/terms'],
  },
];
