import { EntityState } from '@ngrx/entity';

import { FormTemplateDto } from '../api/models/form-template-dto';

export const FORM_TEMPLATE_FEATURE_KEY = 'form-template';

export enum FormTemplateFilter {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ALL = 'ALL',
}

export interface FormTemplateState extends EntityState<FormTemplateDto> {
  ready: boolean;
  filter: FormTemplateFilter;
}
