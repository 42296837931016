import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ScreenLockService, ScreenLockType } from '@fizjo-pro/data-auth';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RxLet } from '@rx-angular/template/let';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, BlockUIModule, TranslateModule, ButtonModule, DynamicDialogModule, RxLet],
  providers: [DialogService],
  selector: 'fizjo-pro-screen-lock',
  standalone: true,
  styleUrls: ['./screen-lock.component.scss'],
  templateUrl: './screen-lock.component.html',
})
export class ScreenLockComponent {
  private dialogService: DialogService = inject(DialogService);
  private translateService: TranslateService = inject(TranslateService);
  private screenLockService: ScreenLockService = inject(ScreenLockService);
  public screenLockType$: Observable<ScreenLockType> = this.screenLockService.screenLock$;
  public screenLock$: Observable<boolean> = this.screenLockType$.pipe(
    map((lockType: ScreenLockType) => lockType === ScreenLockType.EXP || lockType === ScreenLockType.LOCK)
  );

  public showLoginDialog(): void {
    this.dialogService.open(LoginModalComponent, {
      header: this.translateService.instant('auth.loginAgain'),
      width: '480px',
    });
  }
}
