import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FormTemplateFacade } from './form-template.facade';
import { formTemplateActions } from './state/form-template.actions';

@Injectable()
export class FormTemplateResolver extends ResolverHelper {
  constructor(
    store: Store,
    private formTemplateFacade: FormTemplateFacade
  ) {
    super(store);
  }

  public override resolve(): Observable<unknown> {
    return this.formTemplateFacade.ready$.pipe(this.helpResolve(formTemplateActions.read()));
  }
}
