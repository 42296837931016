/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PatchOwnerDto } from '../models/patch-owner-dto';
import { PatientDto } from '../models/patient-dto';
import { PatientPayloadDto } from '../models/patient-payload-dto';
import { UpdatePatientDto } from '../models/update-patient-dto';
import { WeightPayloadDto } from '../models/weight-payload-dto';

@Injectable({
  providedIn: 'root',
})
export class PatientService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation patientControllerReadAll
   */
  static readonly PatientControllerReadAllPath = '/api/patient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientControllerReadAll$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<PatientDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PatientService.PatientControllerReadAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PatientDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientControllerReadAll(params?: { context?: HttpContext }): Observable<Array<PatientDto>> {
    return this.patientControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PatientDto>>) => r.body as Array<PatientDto>)
    );
  }

  /**
   * Path part for operation patientControllerCreate
   */
  static readonly PatientControllerCreatePath = '/api/patient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patientControllerCreate$Response(params: {
    context?: HttpContext;
    body: PatientPayloadDto;
  }): Observable<StrictHttpResponse<PatientDto>> {
    const rb = new RequestBuilder(this.rootUrl, PatientService.PatientControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PatientDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patientControllerCreate(params: { context?: HttpContext; body: PatientPayloadDto }): Observable<PatientDto> {
    return this.patientControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<PatientDto>) => r.body as PatientDto)
    );
  }

  /**
   * Path part for operation patientControllerCreateWeight
   */
  static readonly PatientControllerCreateWeightPath = '/api/patient/{patientId}/weight';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientControllerCreateWeight()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patientControllerCreateWeight$Response(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
    body: WeightPayloadDto;
  }): Observable<StrictHttpResponse<PatientDto>> {
    const rb = new RequestBuilder(this.rootUrl, PatientService.PatientControllerCreateWeightPath, 'post');
    if (params) {
      rb.path('patientId', params.patientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PatientDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientControllerCreateWeight$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patientControllerCreateWeight(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
    body: WeightPayloadDto;
  }): Observable<PatientDto> {
    return this.patientControllerCreateWeight$Response(params).pipe(
      map((r: StrictHttpResponse<PatientDto>) => r.body as PatientDto)
    );
  }

  /**
   * Path part for operation patientControllerReadOne
   */
  static readonly PatientControllerReadOnePath = '/api/patient/{patientId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientControllerReadOne$Response(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<PatientDto>> {
    const rb = new RequestBuilder(this.rootUrl, PatientService.PatientControllerReadOnePath, 'get');
    if (params) {
      rb.path('patientId', params.patientId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PatientDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientControllerReadOne(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
  }): Observable<PatientDto> {
    return this.patientControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<PatientDto>) => r.body as PatientDto)
    );
  }

  /**
   * Path part for operation patientControllerDelete
   */
  static readonly PatientControllerDeletePath = '/api/patient/{patientId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientControllerDelete$Response(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PatientService.PatientControllerDeletePath, 'delete');
    if (params) {
      rb.path('patientId', params.patientId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientControllerDelete(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.patientControllerDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation patientControllerUpdate
   */
  static readonly PatientControllerUpdatePath = '/api/patient/{patientId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patientControllerUpdate$Response(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
    body: UpdatePatientDto;
  }): Observable<StrictHttpResponse<PatientDto>> {
    const rb = new RequestBuilder(this.rootUrl, PatientService.PatientControllerUpdatePath, 'patch');
    if (params) {
      rb.path('patientId', params.patientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PatientDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patientControllerUpdate(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
    body: UpdatePatientDto;
  }): Observable<PatientDto> {
    return this.patientControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<PatientDto>) => r.body as PatientDto)
    );
  }

  /**
   * Path part for operation patientControllerDeleteOwner
   */
  static readonly PatientControllerDeleteOwnerPath = '/api/patient/{patientId}/owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientControllerDeleteOwner()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientControllerDeleteOwner$Response(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<PatientDto>> {
    const rb = new RequestBuilder(this.rootUrl, PatientService.PatientControllerDeleteOwnerPath, 'delete');
    if (params) {
      rb.path('patientId', params.patientId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PatientDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientControllerDeleteOwner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientControllerDeleteOwner(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
  }): Observable<PatientDto> {
    return this.patientControllerDeleteOwner$Response(params).pipe(
      map((r: StrictHttpResponse<PatientDto>) => r.body as PatientDto)
    );
  }

  /**
   * Path part for operation patientControllerPatchOwner
   */
  static readonly PatientControllerPatchOwnerPath = '/api/patient/{patientId}/owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientControllerPatchOwner()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patientControllerPatchOwner$Response(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
    body: PatchOwnerDto;
  }): Observable<StrictHttpResponse<PatientDto>> {
    const rb = new RequestBuilder(this.rootUrl, PatientService.PatientControllerPatchOwnerPath, 'patch');
    if (params) {
      rb.path('patientId', params.patientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PatientDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientControllerPatchOwner$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patientControllerPatchOwner(params: {
    /**
     * The patient Mongo DB identifier
     */
    patientId: string;
    context?: HttpContext;
    body: PatchOwnerDto;
  }): Observable<PatientDto> {
    return this.patientControllerPatchOwner$Response(params).pipe(
      map((r: StrictHttpResponse<PatientDto>) => r.body as PatientDto)
    );
  }
}
