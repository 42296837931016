/* tslint:disable */
/* eslint-disable */
export enum FormFieldTypeDto {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  OPTIONS = 'options',
  DATE = 'date',
  TRUE_FALSE = 'trueFalse',
  SINGLE_CHOICE = 'singleChoice',
  MULTI_CHOICE = 'multiChoice',
  RTF_EDITOR = 'rtfEditor',
}
