import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { filter, finalize, first, tap } from 'rxjs/operators';

import { AppointmentDto, AppointmentGeneralPayloadDto, ProcedurePayloadDto } from './api/models';
import { appointmentActions, generalActions, procedureActions } from './state/appointment.actions';
import { RangeDto } from './state/appointment.models';
import {
  selectAllAppointments,
  selectAppointment,
  selectAppointmentByPatient,
  selectAppointmentFullReady,
  selectAppointmentsRange,
  selectPatientAppointmentsRange,
} from './state/appointment.selectors';

@Injectable()
export class AppointmentFacade {
  readonly #store: Store = inject(Store);
  public readonly appointments$: Observable<AppointmentDto[]>;
  public readonly range$!: Observable<RangeDto | null>;

  constructor() {
    this.appointments$ = this.#store.select(selectAllAppointments);
    this.range$ = this.#store.select(selectAppointmentsRange);
  }

  public selectAppointment$(appointmentId: string | null): Observable<AppointmentDto | undefined> {
    return this.#store.select(selectAppointment(appointmentId || ''));
  }

  public appointmentsByPatient$(patientId: string | null): Observable<AppointmentDto[]> {
    if (patientId === null) {
      return of([]);
    }

    return this.#store.select(selectAppointmentByPatient(patientId));
  }

  public patientAppointmentsRange$(patientId: string | null): Observable<RangeDto | null> {
    return this.#store.select(selectPatientAppointmentsRange(patientId || ''));
  }

  public updateProcedure(appointmentId: string, payload: ProcedurePayloadDto): void {
    this.#store.dispatch(procedureActions.updateProcedure({ appointmentId, payload }));
  }

  public createGeneral(payload: AppointmentGeneralPayloadDto, files?: File[], eventId?: string): void {
    this.#store.dispatch(generalActions.createGeneral({ payload, eventId: eventId || null, files }));
  }

  public updateGeneral(
    appointmentId: string,
    payload: AppointmentGeneralPayloadDto,
    files?: File[],
    filesToRemove?: string[]
  ): void {
    this.#store.dispatch(generalActions.updateGeneral({ appointmentId, payload, files, filesToRemove }));
  }

  public deleteAppointment(appointmentId: string): void {
    this.#store.dispatch(appointmentActions.delete({ appointmentId }));
  }

  public createNote(appointmentId: string, note: string): void {
    this.#store.dispatch(appointmentActions.createNote({ appointmentId, note }));
  }

  public updateNote(appointmentId: string, noteId: string, note: string): void {
    this.#store.dispatch(appointmentActions.updateNote({ appointmentId, noteId, note }));
  }

  public deleteNote(appointmentId: string, noteId: string): void {
    this.#store.dispatch(appointmentActions.deleteNote({ appointmentId, noteId }));
  }

  public shareReport(appointmentId: string, message: string, emails: string[]): void {
    this.#store.dispatch(appointmentActions.shareReport({ appointmentId, emails, message }));
  }

  public resolveAppointmentData$(appointmentId = ''): Observable<AppointmentDto | undefined> {
    let isLoading = false;

    return this.#store.select(selectAppointmentFullReady(appointmentId)).pipe(
      tap((ready: boolean) => {
        if (!isLoading && !ready) {
          isLoading = true;
          this.#store.dispatch(appointmentActions.fetchSingleAppointment({ appointmentId }));
        }
      }),
      filter((ready: boolean) => ready),
      switchMap(() => this.#store.select(selectAppointment(appointmentId))),
      first(),
      finalize(() => {
        isLoading = false;
      })
    );
  }

  public resolveRange(from: Date, to: Date): void {
    this.#store.dispatch(appointmentActions.fetchRange({ from, to }));
  }

  fetchAllForPatient(patientId: string): void {
    this.#store.dispatch(appointmentActions.fetchAllForPatient({ patientId }));
  }
}
