import { createAction, props } from '@ngrx/store';

import { PROCEDURE_TEMPLATE_FEATURE_KEY, ProcedureTemplateFilter } from './procedure-template.model';
import { ProcedureTemplateDto } from '../api/models/procedure-template-dto';
import { ProcedureTemplatePayloadDto } from '../api/models/procedure-template-payload-dto';

export const create = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] create procedure template`,
  props<{ payload: ProcedureTemplatePayloadDto }>()
);

export const createSuccess = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] create procedure template success`,
  props<{ procedureTemplate: ProcedureTemplateDto }>()
);

export const fetchList = createAction(`[${PROCEDURE_TEMPLATE_FEATURE_KEY}] fetch procedure templates list`);

export const fetchListSuccess = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] fetch procedure templates list success`,
  props<{ templates: ProcedureTemplateDto[] }>()
);

export const update = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] update procedure template`,
  props<{ templateId: string; payload: ProcedureTemplatePayloadDto }>()
);

export const updateSuccess = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] update procedure template success`,
  props<{ procedureTemplate: ProcedureTemplateDto }>()
);

export const message = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] present message`,
  props<{ message: string; severity: string }>()
);

export const deleteTemplate = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] delete procedure template`,
  props<{ templateId: string }>()
);
export const restoreTemplate = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] restore procedure template`,
  props<{ templateId: string }>()
);

export const deleteTemplateSuccess = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] delete procedure template success`,
  props<{ template: ProcedureTemplateDto }>()
);

export const restoreTemplateSuccess = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] restore procedure template success`,
  props<{ template: ProcedureTemplateDto }>()
);

export const setFilter = createAction(
  `[${PROCEDURE_TEMPLATE_FEATURE_KEY}] set templates filter value`,
  props<{ filter: ProcedureTemplateFilter }>()
);
