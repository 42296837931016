import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';

import { Order } from './order';

type AppLayoutState = {
  order: Order | null;
};

export const MarketplaceStore = signalStore(
  { providedIn: 'root' },
  withState<AppLayoutState>({ order: null }),
  withMethods(store => ({
    checkHasOrder(): void {
      try {
        const order: string | null = localStorage.getItem('fizjoPro:order');

        if (order === null) {
          patchState(store, state => ({ ...state, order: null }));
        } else {
          patchState(store, state => ({ ...state, order: JSON.parse(order) }));
        }
      } catch (error) {
        patchState(store, state => ({ ...state, order: null }));
      }
    },
    saveOrder(order: Order | null): void {
      if (order) {
        localStorage.setItem('fizjoPro:order', JSON.stringify(order));
      } else {
        localStorage.removeItem('fizjoPro:order');
      }
      patchState(store, state => ({ ...state, order }));
    },
    clearState(): void {
      localStorage.removeItem('fizjoPro:order');
      patchState(store, state => ({ ...state, order: null }));
    },
  })),
  withComputed(store => ({
    hasOrder: computed(() => store.order() !== null),
  })),
  withHooks({
    onInit(store) {
      store.checkHasOrder();
    },
  })
);
