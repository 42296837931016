/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { selectMyData } from '@fizjo-pro/data-auth';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { APP_USERS_KEY, AppUsersState } from './app-users.model';
import { selectAll } from './app-users.reducers';

export const appUsersSelector = createFeatureSelector<AppUsersState>(APP_USERS_KEY);

export const selectAllUsers = createSelector(appUsersSelector, (state: AppUsersState) => selectAll(state));

export const selectReady = createSelector(appUsersSelector, (state: AppUsersState) => state.ready);

export const selectAllExceptOf = (exceptOf: string) =>
  createSelector(appUsersSelector, (state: AppUsersState) => selectAll(state).filter(user => user._id !== exceptOf));
export const selectUser = (uid: string | null) =>
  createSelector(appUsersSelector, (state: AppUsersState) => (uid ? state.entities[uid] : undefined));

export const selectIsUniqueEmail = (email: string) =>
  createSelector(selectAllUsers, selectMyData, (users, me) => {
    return users.filter(user => user._id !== me?._id && user.email === email).length === 0;
  });
