import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum Breakpoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
  XXXL = 'xxxl',
  XXXXL = 'xxxxl',
}

export class ResponsiveService {
  #breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  static readonly breakpoints: Breakpoints[] = [
    Breakpoints.XS,
    Breakpoints.SM,
    Breakpoints.MD,
    Breakpoints.LG,
    Breakpoints.XL,
    Breakpoints.XXL,
    Breakpoints.XXXL,
    Breakpoints.XXXXL,
  ];

  public gteMd$: Observable<boolean> = this.gte$(Breakpoints.MD);
  public ltMd$: Observable<boolean> = this.lt$(Breakpoints.MD);
  match$(breakpoint: Breakpoints): Observable<boolean> {
    const mq: Record<Breakpoints, string> = {
      [Breakpoints.XS]: '(max-width: 325px)',
      [Breakpoints.SM]: '(min-width: 326px) and (max-width: 767px)',
      [Breakpoints.MD]: '(min-width: 768px) and (max-width: 1023px)',
      [Breakpoints.LG]: '(min-width: 1024px) and (max-width: 1279px)',
      [Breakpoints.XL]: '(min-width: 1280px) and (max-width: 1599px)',
      [Breakpoints.XXL]: '(min-width: 1600px) and (max-width: 1919px)',
      [Breakpoints.XXXL]: '(min-width: 1920px) and (max-width: 2559px)',
      [Breakpoints.XXXXL]: '(min-width: 2560px)',
    };

    return this.#breakpointObserver
      .observe(mq[breakpoint])
      .pipe(map((breakpointState: BreakpointState) => breakpointState.matches));
  }

  lt$(breakpoint: Breakpoints): Observable<boolean> {
    const mq: Record<Breakpoints, string> = {
      [Breakpoints.XS]: '(max-width: 325px)',
      [Breakpoints.SM]: '(max-width: 325px)',
      [Breakpoints.MD]: '(max-width: 767px)',
      [Breakpoints.LG]: '(max-width: 1023px)',
      [Breakpoints.XL]: '(max-width: 1279px)',
      [Breakpoints.XXL]: '(max-width: 1599px)',
      [Breakpoints.XXXL]: '(max-width: 1919px)',
      [Breakpoints.XXXXL]: '(min-width: 2559px)',
    };

    return this.#breakpointObserver
      .observe(mq[breakpoint])
      .pipe(map((breakpointState: BreakpointState) => breakpointState.matches));
  }

  lte$(breakpoint: Breakpoints): Observable<boolean> {
    const mq: Record<Breakpoints, string> = {
      [Breakpoints.XS]: '(max-width: 325px)',
      [Breakpoints.SM]: '(max-width: 767px)',
      [Breakpoints.MD]: '(max-width: 1023px)',
      [Breakpoints.LG]: '(max-width: 1279px)',
      [Breakpoints.XL]: '(max-width: 1599px)',
      [Breakpoints.XXL]: '(max-width: 1919px)',
      [Breakpoints.XXXL]: '(max-width: 2559px)',
      [Breakpoints.XXXXL]: '(min-width: 2560px)',
    };

    return this.#breakpointObserver
      .observe(mq[breakpoint])
      .pipe(map((breakpointState: BreakpointState) => breakpointState.matches));
  }

  gte$(breakpoint: Breakpoints): Observable<boolean> {
    const mq: Record<Breakpoints, string> = {
      [Breakpoints.XS]: '(max-width: 325px)',
      [Breakpoints.SM]: '(min-width: 326px)',
      [Breakpoints.MD]: '(min-width: 768px)',
      [Breakpoints.LG]: '(min-width: 1024px)',
      [Breakpoints.XL]: '(min-width: 1280px)',
      [Breakpoints.XXL]: '(min-width: 1600px)',
      [Breakpoints.XXXL]: '(min-width: 1920px)',
      [Breakpoints.XXXXL]: '(min-width: 2560px)',
    };

    return this.#breakpointObserver
      .observe(mq[breakpoint])
      .pipe(map((breakpointState: BreakpointState) => breakpointState.matches));
  }
}
