import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as TemplateActions from './procedure-template.actions';
import { ProcedureTemplateFilter } from './procedure-template.model';
import * as TemplateSelectors from './procedure-template.selectors';
import { selectMany } from './procedure-template.selectors';
import { ProcedureTemplateDto } from '../api/models/procedure-template-dto';
import { ProcedureTemplatePayloadDto } from '../api/models/procedure-template-payload-dto';

@Injectable({
  providedIn: 'root',
})
export class ProcedureTemplateFacade {
  public procedureTemplate$!: Observable<ProcedureTemplateDto[]>;
  public filter$!: Observable<ProcedureTemplateFilter>;
  public activeOnly$!: Observable<ProcedureTemplateDto[]>;

  constructor(private store: Store) {
    this.procedureTemplate$ = this.store.select(TemplateSelectors.selectTemplates);
    this.activeOnly$ = this.store.select(TemplateSelectors.selectActiveOnly);
    this.filter$ = this.store.select(TemplateSelectors.selectFilterValue);
  }

  public create(payload: ProcedureTemplatePayloadDto): void {
    this.store.dispatch(TemplateActions.create({ payload }));
  }

  public getById$(templateId: string): Observable<ProcedureTemplateDto | undefined> {
    return this.store.select(TemplateSelectors.selectById(templateId));
  }

  public update(templateId: string, payload: ProcedureTemplatePayloadDto): void {
    return this.store.dispatch(TemplateActions.update({ payload, templateId }));
  }

  public deleteTemplate(templateId: string): void {
    this.store.dispatch(TemplateActions.deleteTemplate({ templateId }));
  }

  public restoreTemplate(templateId: string): void {
    this.store.dispatch(TemplateActions.restoreTemplate({ templateId }));
  }

  public selectMany$(ids: string[]): Observable<ProcedureTemplateDto[]> {
    return this.store.select(selectMany(ids));
  }
}
