import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { appUsersActions } from './state/app-users.actions';
import { selectReady } from './state/app-users.selectors';

@Injectable()
export class AppUsersResolver extends ResolverHelper {
  constructor(store: Store) {
    super(store);
  }

  public override resolve(): Observable<any> {
    return this.store.select(selectReady).pipe(this.helpResolve(appUsersActions.fetch()));
  }
}
