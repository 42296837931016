import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { UserDto } from '@fizjo-pro/settings/data-user';
import { AuthService, MeService } from '@fizjo-pro/util-auth';
import { TranslateService } from '@ngx-translate/core';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { filter, map, Observable, switchMap } from 'rxjs';

import { AppUserAvatarComponent } from '../app-user-avatar/app-user-avatar.component';

@Component({
  imports: [CommonModule, AppUserAvatarComponent, RxLet, RxIf, OverlayModule, OverlayPanelModule, MenuModule],
  selector: 'fizjo-pro-switch-app-user',
  standalone: true,
  styleUrls: ['./switch-app-user.component.scss'],
  templateUrl: './switch-app-user.component.html',
})
export class SwitchAppUserComponent implements OnInit {
  #translateService: TranslateService = inject(TranslateService);
  #meService: MeService = inject(MeService);
  #authService: AuthService = inject(AuthService);
  @Output() public closeOverlay: EventEmitter<void> = new EventEmitter<void>();
  public me$!: Observable<UserDto | null>;
  public menuItems$!: Observable<MenuItem[]>;

  public ngOnInit() {
    this.me$ = this.#meService.me$;

    this.menuItems$ = this.me$.pipe(
      filter((user: UserDto | null) => user !== null),
      switchMap((user: UserDto | null) => this.#menuBuilder$(user?._id))
    );
  }

  async #logout(uid?: string): Promise<void> {
    if (uid) {
      this.closeOverlay.emit();
      await this.#authService.logout(uid);
    }
  }

  #menuBuilder$(uid?: string): Observable<MenuItem[]> {
    return this.#translateService.get(['auth.logout']).pipe(
      map((translations: Record<string, string>) => {
        return [
          {
            command: (): void => {
              this.#logout(uid);
            },
            icon: 'pi pi-sign-out',
            label: translations['auth.logout'],
          },
        ];
      })
    );
  }
}
