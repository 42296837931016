<ng-container *ngIf="me$ | async as me">
  <div class="flex flex-column align-items-center gap-3 w-40rem">
    <p-avatar [label]="me.firstName[0] + me.lastName[0]" size="large" shape="circle"></p-avatar>
    <span class="font-bold text-lg">{{me.firstName}} {{me.lastName}}</span>
    <span>
      <span class="font-semibold">{{me.email}}</span>
    </span>
    <span class="flex flex-row justify-content-between w-full">
      <a
        [routerLink]="['/settings/user/user-data']"
        pButton
        icon="pi pi-pencil"
        (click)="closeOverlay.emit()"
        class="p-button p-button-rounded p-button-outlined p-button-success"></a>
      <button
        pButton
        icon="pi pi-sign-out"
        (click)="logout(me._id)"
        class="p-button p-button-rounded p-button-outlined p-button-danger"></button>
    </span>
  </div>
</ng-container>
