/* eslint-disable rxjs-angular/prefer-takeuntil */

import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({ template: '' })
export abstract class AbstractValueAccessorComponent<T extends { [key: string]: any }>
  implements ControlValueAccessor, OnInit
{
  public isDisabled = false;
  public abstract formGroup: FormGroup;
  protected formValue$!: Observable<T>;
  protected isPatch = false;

  public ngOnInit(): void {
    this.formValue$ = this.formGroup.valueChanges;
  }

  public registerOnChange(callback: () => void): void {
    this.formValue$.pipe().subscribe(callback);
  }

  public registerOnTouched(callback: () => void): void {
    this.formValue$.pipe().subscribe(callback);
  }

  public setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  public writeValue(value: T | null): void {
    if (value !== null) {
      if (this.isPatch) {
        this.formGroup.patchValue(value, { emitEvent: false });
      } else {
        this.formGroup.setValue(value, { emitEvent: false });
      }
    }
  }
}
