import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { BillingDataFacade } from './billing-data.facade';
import { BillingDataResolver } from './billing-data.resolver';
import { BillingDataEffects } from './state/billing-data.effects';
import { billingDataFeature } from './state/billing-data.feature';

export const billingDataProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(ApiModule),
  provideState(billingDataFeature),
  provideEffects([BillingDataEffects]),
  BillingDataFacade,
  BillingDataResolver,
];
