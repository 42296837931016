import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, NEVER, Observable } from 'rxjs';

import { CeigdCompanyDto } from './api/models/ceigd-company-dto';
import { StripeBillingDataDto } from './api/models/stripe-billing-data-dto';
import { StripeCustomerDto } from './api/models/stripe-customer-dto';
import { BillingDataService } from './api/services/billing-data.service';
import { billingDataActions } from './state/billing-data.actions';
import { selectHasVat, selectStripeCustomer } from './state/billing-data.feature';

@Injectable()
export class BillingDataFacade {
  #store: Store = inject(Store);
  #appMessageService: MessageService = inject(MessageService);
  #billingDataService: BillingDataService = inject(BillingDataService);
  #translateService: TranslateService = inject(TranslateService);

  public readonly stripeCustomer$: Observable<StripeCustomerDto | null> = this.#store.select(selectStripeCustomer);
  public readonly hasVat$: Observable<boolean> = this.#store.select(selectHasVat);

  public readFromCeigd$(nip: string): Observable<CeigdCompanyDto> {
    return this.#billingDataService.billingDataControllerReadFromCeigd({ nip }).pipe(
      catchError(() => {
        this.#appMessageService.add({
          severity: 'error',
          summary: this.#translateService.instant('billingData.ceigd.error.summary'),
          detail: this.#translateService.instant('billingData.ceigd.error.details'),
        });

        return NEVER;
      })
    );
  }

  public update(payload: StripeBillingDataDto): void {
    this.#store.dispatch(billingDataActions.update({ payload }));
  }
}
