import { Injectable } from '@angular/core';
import { AuthFacade, Permission, Role, UserDto } from '@fizjo-pro/data-auth';
import { combineLatest, Observable, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MeService {
  public readonly me$: Observable<UserDto | null>;
  public readonly role$: Observable<Role>;
  public readonly isOwner$: Observable<boolean>;
  public readonly permissions$: Observable<Permission[]>;
  public readonly isMentor$: Observable<boolean>;

  constructor(private authFacade: AuthFacade) {
    this.me$ = this.authFacade.me$;
    this.role$ = this.authFacade.role$;
    this.isOwner$ = this.role$.pipe(map(role => role === Role.Owner));
    this.permissions$ = this.authFacade.permissions$;

    this.isMentor$ = combineLatest([this.role$, this.permissions$]).pipe(
      map(
        ([role, permissions]) => role === Role.Owner || (role === Role.User && permissions.includes(Permission.Mentor))
      )
    );
  }

  public resolveOwnData(): void {
    this.authFacade.resolveOwnData();
  }

  // checks if the user has a specific permission, for owner it always returns true
  public hasPermission$(permission: Permission): Observable<boolean> {
    return this.permissions$.pipe(
      withLatestFrom(this.isOwner$),
      map(([permissions, isOwner]) => permissions.includes(permission) || isOwner)
    );
  }
}
