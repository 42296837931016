import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'fizjo-pro-hint',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent {
  @Input() type: 'info' | 'warning' | 'error' = 'info';
}
