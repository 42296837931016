import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { AppConfigService } from './app-config.service';

export const featGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  appConfigService: AppConfigService = inject(AppConfigService)
) => appConfigService.hasFeat$(route?.routeConfig?.data?.['feature']);
