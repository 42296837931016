/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProcedureTemplateActiveDto } from '../models/procedure-template-active-dto';
import { ProcedureTemplateDto } from '../models/procedure-template-dto';
import { ProcedureTemplatePayloadDto } from '../models/procedure-template-payload-dto';

@Injectable({
  providedIn: 'root',
})
export class ProcedureTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation procedureTemplateControllerReadAll
   */
  static readonly ProcedureTemplateControllerReadAllPath = '/api/procedure-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureTemplateControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedureTemplateControllerReadAll$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<ProcedureTemplateDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ProcedureTemplateService.ProcedureTemplateControllerReadAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ProcedureTemplateDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureTemplateControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedureTemplateControllerReadAll(params?: { context?: HttpContext }): Observable<Array<ProcedureTemplateDto>> {
    return this.procedureTemplateControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProcedureTemplateDto>>) => r.body as Array<ProcedureTemplateDto>)
    );
  }

  /**
   * Path part for operation procedureTemplateControllerCreate
   */
  static readonly ProcedureTemplateControllerCreatePath = '/api/procedure-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureTemplateControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureTemplateControllerCreate$Response(params: {
    context?: HttpContext;
    body: ProcedureTemplatePayloadDto;
  }): Observable<StrictHttpResponse<ProcedureTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProcedureTemplateService.ProcedureTemplateControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProcedureTemplateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureTemplateControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureTemplateControllerCreate(params: {
    context?: HttpContext;
    body: ProcedureTemplatePayloadDto;
  }): Observable<ProcedureTemplateDto> {
    return this.procedureTemplateControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<ProcedureTemplateDto>) => r.body as ProcedureTemplateDto)
    );
  }

  /**
   * Path part for operation procedureTemplateControllerReadOne
   */
  static readonly ProcedureTemplateControllerReadOnePath = '/api/procedure-template/{templateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureTemplateControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedureTemplateControllerReadOne$Response(params: {
    /**
     * The template MongoDB identifier
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<ProcedureTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProcedureTemplateService.ProcedureTemplateControllerReadOnePath, 'get');
    if (params) {
      rb.path('templateId', params.templateId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProcedureTemplateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureTemplateControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedureTemplateControllerReadOne(params: {
    /**
     * The template MongoDB identifier
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<ProcedureTemplateDto> {
    return this.procedureTemplateControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<ProcedureTemplateDto>) => r.body as ProcedureTemplateDto)
    );
  }

  /**
   * Path part for operation procedureTemplateControllerUpdate
   */
  static readonly ProcedureTemplateControllerUpdatePath = '/api/procedure-template/{templateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureTemplateControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureTemplateControllerUpdate$Response(params: {
    /**
     * The template MongoDB identifier
     */
    templateId: string;
    context?: HttpContext;
    body: ProcedureTemplatePayloadDto;
  }): Observable<StrictHttpResponse<ProcedureTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProcedureTemplateService.ProcedureTemplateControllerUpdatePath, 'put');
    if (params) {
      rb.path('templateId', params.templateId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProcedureTemplateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureTemplateControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureTemplateControllerUpdate(params: {
    /**
     * The template MongoDB identifier
     */
    templateId: string;
    context?: HttpContext;
    body: ProcedureTemplatePayloadDto;
  }): Observable<ProcedureTemplateDto> {
    return this.procedureTemplateControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<ProcedureTemplateDto>) => r.body as ProcedureTemplateDto)
    );
  }

  /**
   * Path part for operation procedureTemplateControllerDelete
   */
  static readonly ProcedureTemplateControllerDeletePath = '/api/procedure-template/{templateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureTemplateControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedureTemplateControllerDelete$Response(params: {
    /**
     * The template MongoDB identifier
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureTemplateService.ProcedureTemplateControllerDeletePath,
      'delete'
    );
    if (params) {
      rb.path('templateId', params.templateId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureTemplateControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedureTemplateControllerDelete(params: {
    /**
     * The template MongoDB identifier
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.procedureTemplateControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation procedureTemplateControllerPatchActive
   */
  static readonly ProcedureTemplateControllerPatchActivePath = '/api/procedure-template/{templateId}/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureTemplateControllerPatchActive()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureTemplateControllerPatchActive$Response(params: {
    /**
     * The template MongoDB identifier
     */
    templateId: string;
    context?: HttpContext;
    body: ProcedureTemplateActiveDto;
  }): Observable<StrictHttpResponse<ProcedureTemplateDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureTemplateService.ProcedureTemplateControllerPatchActivePath,
      'patch'
    );
    if (params) {
      rb.path('templateId', params.templateId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProcedureTemplateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureTemplateControllerPatchActive$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureTemplateControllerPatchActive(params: {
    /**
     * The template MongoDB identifier
     */
    templateId: string;
    context?: HttpContext;
    body: ProcedureTemplateActiveDto;
  }): Observable<ProcedureTemplateDto> {
    return this.procedureTemplateControllerPatchActive$Response(params).pipe(
      map((r: StrictHttpResponse<ProcedureTemplateDto>) => r.body as ProcedureTemplateDto)
    );
  }
}
