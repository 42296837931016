import { Routes } from '@angular/router';
import { appUsersProviders, AppUsersResolver } from '@fizjo-pro/settings/data-user';
import { PermissionMatrixResolver } from '@kate-fizjo/practice-shared/app-config';

export const appUsersRoutes: Routes = [
  {
    loadComponent: () =>
      import('./components/app-users-list/app-users-list.component').then(m => m.AppUsersListComponent),
    path: '',
    providers: [...appUsersProviders],
    resolve: {
      users: AppUsersResolver,
    },
  },
  {
    loadComponent: () =>
      import('./components/app-user-editor/app-user-editor.component').then(m => m.AppUserEditorComponent),
    path: 'create',
    providers: [...appUsersProviders],
    resolve: {
      permissionMatrix: PermissionMatrixResolver,
    },
  },
  {
    loadComponent: () =>
      import('./components/app-user-editor/app-user-editor.component').then(m => m.AppUserEditorComponent),
    path: ':uid/edit',
    providers: [...appUsersProviders],
    resolve: {
      users: AppUsersResolver,
      permissionMatrix: PermissionMatrixResolver,
    },
  },
];
