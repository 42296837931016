import { UserDto } from '../api/models/user-dto';

export interface AuthState {
  authenticated: boolean;
  user: UserDto | null;
  screenLock: ScreenLockType;
  sessionTimeout: number;
}

export enum ScreenLockType {
  EXP = 'exp',
  LOCK = 'lock',
  NONE = 'none',
}

export const AUTH_FEATURE_KEY = 'auth';
