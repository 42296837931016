export const environment = {
    adminRootUrl: `https://admin-api.fizjo.pro`,
    production: true,
    rootUrl: `https://api.fizjo.pro`,
    firebaseOptions: {
        apiKey: "AIzaSyBtzVqHQ2TDn6uIuL8WMTSGYZjIiatnM-0",
        appId: "1:851685634673:web:5a941ba3cb957f0c3c21d0",
        authDomain: "fizjopro.firebaseapp.com",
        measurementId: "G-962KS17SDJ",
        messagingSenderId: "851685634673",
        projectId: "fizjopro",
        storageBucket: "fizjopro.appspot.com"
    }
};
