/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { statsActions } from './actions';
import { ResourceType } from '../api/models/resource-type';
import { StatsItemDto } from '../api/models/stats-item-dto';

export const adapter = createEntityAdapter<StatsItemDto>({
  selectId: (statsItemDto: StatsItemDto) => statsItemDto.type,
});

export const statsFeature = createFeature({
  name: 'stats',
  reducer: createReducer(
    adapter.getInitialState(),
    on(statsActions.readSuccess, (state, { stats }) => adapter.setAll(stats, state))
  ),
  extraSelectors: ({ selectEntities, selectIds }) => ({
    selectStatsItem: (type: ResourceType) => createSelector(selectEntities, entities => entities[type]),
    selectReady: createSelector(selectIds, ids => ids.length > 0),
    selectAll: createSelector(selectEntities, entities =>
      Object.values(entities)
        .filter(Boolean)
        .map(stat => stat as StatsItemDto)
    ),
  }),
});

export const { selectStatsItem, selectReady, selectAll } = statsFeature;
