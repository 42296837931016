<div>
  <div class="flex justify-content-center flex-column md:flex-row align-items-center md:align-items-start">
    <img class="mr-3" width="200px" src="/assets/500_error.svg" />
    <div>
      <h3>{{'internalServerErrorDialog.header' | translate}}</h3>
      <p>{{'internalServerErrorDialog.message' | translate}}</p>
    </div>
  </div>
</div>
<div class="flex justify-content-end gap-2 mt-2">
  <p-button [label]="'internalServerErrorDialog.buttonLabel' | translate" (click)="close()"></p-button>
</div>
