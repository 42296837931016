import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { createFeature, provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { OwnerFacade } from './owner.facade';
import { OwnerResolver } from './owner.resolver';
import { OwnerEffects } from './state/owner.effects';
import { OWNER_FEATURE_KEY } from './state/owner.models';
import { ownerReducer } from './state/owner.reducer';

export const ownerProviders: (Provider | EnvironmentProviders)[] = [
  OwnerResolver,
  OwnerFacade,
  importProvidersFrom(ApiModule),
  provideState(
    createFeature({
      name: OWNER_FEATURE_KEY,
      reducer: ownerReducer,
    })
  ),
  provideEffects([OwnerEffects]),
];
