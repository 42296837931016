import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { authActions } from '../state/auth.actions';
import { selectUserReady } from '../state/auth.selectors';

@Injectable()
export class MeResolver extends ResolverHelper {
  constructor(store: Store) {
    super(store);
  }

  public override resolve(): Observable<any> {
    return this.store.select(selectUserReady).pipe(this.helpResolve(authActions.fetchOwnUser()));
  }
}
