/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MyDataPayloadDto } from '../models/my-data-payload-dto';
import { PasswordDto } from '../models/password-dto';
import { PatchActiveDto } from '../models/patch-active-dto';
import { PatchRoleDto } from '../models/patch-role-dto';
import { PinCodePayloadDto } from '../models/pin-code-payload-dto';
import { UserDto } from '../models/user-dto';
import { UserPayloadDto } from '../models/user-payload-dto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation userControllerReadMe
   */
  static readonly UserControllerReadMePath = '/api/user/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerReadMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerReadMe$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerReadMePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerReadMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerReadMe(params?: { context?: HttpContext }): Observable<UserDto> {
    return this.userControllerReadMe$Response(params).pipe(map((r: StrictHttpResponse<UserDto>) => r.body as UserDto));
  }

  /**
   * Path part for operation ownUserControllerUpdateMe
   */
  static readonly OwnUserControllerUpdateMePath = '/api/user/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownUserControllerUpdateMe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerUpdateMe$Response(params: {
    context?: HttpContext;
    body: MyDataPayloadDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.OwnUserControllerUpdateMePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownUserControllerUpdateMe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerUpdateMe(params: { context?: HttpContext; body: MyDataPayloadDto }): Observable<UserDto> {
    return this.ownUserControllerUpdateMe$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation ownUserControllerRequestMfa
   */
  static readonly OwnUserControllerRequestMfaPath = '/api/user/me/mfa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownUserControllerRequestMfa()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownUserControllerRequestMfa$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.OwnUserControllerRequestMfaPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownUserControllerRequestMfa$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownUserControllerRequestMfa(params?: { context?: HttpContext }): Observable<{}> {
    return this.ownUserControllerRequestMfa$Response(params).pipe(map((r: StrictHttpResponse<{}>) => r.body as {}));
  }

  /**
   * Path part for operation ownUserControllerUpdatePinCode
   */
  static readonly OwnUserControllerUpdatePinCodePath = '/api/user/me/pin-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownUserControllerUpdatePinCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerUpdatePinCode$Response(params: {
    context?: HttpContext;
    body: PinCodePayloadDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.OwnUserControllerUpdatePinCodePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownUserControllerUpdatePinCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerUpdatePinCode(params: { context?: HttpContext; body: PinCodePayloadDto }): Observable<UserDto> {
    return this.ownUserControllerUpdatePinCode$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation ownUserControllerChangePassword
   */
  static readonly OwnUserControllerChangePasswordPath = '/api/user/me/password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownUserControllerChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerChangePassword$Response(params: {
    context?: HttpContext;
    body: PasswordDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.OwnUserControllerChangePasswordPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownUserControllerChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerChangePassword(params: { context?: HttpContext; body: PasswordDto }): Observable<void> {
    return this.ownUserControllerChangePassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userControllerReadAll
   */
  static readonly UserControllerReadAllPath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerReadAll$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<UserDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerReadAllPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerReadAll(params: { 'x-tenant-id': string; context?: HttpContext }): Observable<Array<UserDto>> {
    return this.userControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation userControllerCreate
   */
  static readonly UserControllerCreatePath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCreate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: UserPayloadDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerCreatePath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCreate(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: UserPayloadDto;
  }): Observable<UserDto> {
    return this.userControllerCreate$Response(params).pipe(map((r: StrictHttpResponse<UserDto>) => r.body as UserDto));
  }

  /**
   * Path part for operation userControllerCheckEmail
   */
  static readonly UserControllerCheckEmailPath = '/api/user/check-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCheckEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerCheckEmail$Response(params: {
    /**
     * User email address
     */
    email: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerCheckEmailPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerCheckEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerCheckEmail(params: {
    /**
     * User email address
     */
    email: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.userControllerCheckEmail$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation userControllerPatchRoles
   */
  static readonly UserControllerPatchRolesPath = '/api/user/{uid}/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerPatchRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerPatchRoles$Response(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: PatchRoleDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerPatchRolesPath, 'patch');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerPatchRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerPatchRoles(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: PatchRoleDto;
  }): Observable<UserDto> {
    return this.userControllerPatchRoles$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation userControllerPatchActive
   */
  static readonly UserControllerPatchActivePath = '/api/user/{uid}/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerPatchActive()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerPatchActive$Response(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: PatchActiveDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerPatchActivePath, 'patch');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerPatchActive$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerPatchActive(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: PatchActiveDto;
  }): Observable<UserDto> {
    return this.userControllerPatchActive$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation userControllerUpdate
   */
  static readonly UserControllerUpdatePath = '/api/user/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdate$Response(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: UserPayloadDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerUpdatePath, 'put');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdate(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: UserPayloadDto;
  }): Observable<UserDto> {
    return this.userControllerUpdate$Response(params).pipe(map((r: StrictHttpResponse<UserDto>) => r.body as UserDto));
  }
}
