import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as TemplateActions from './procedure-template.actions';
import { ProcedureTemplateDto } from '../api/models';
import { ProcedureTemplateService } from '../api/services/procedure-template.service';

@Injectable()
export class ProcedureTemplateEffects {
  #action$: Actions = inject(Actions);
  #templateService: ProcedureTemplateService = inject(ProcedureTemplateService);
  #router: Router = inject(Router);
  #messageService: MessageService = inject(MessageService);

  public createEquipment$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.create),
      switchMap(action =>
        this.#templateService.procedureTemplateControllerCreate({
          body: action.payload,
        })
      ),
      map((procedureTemplate: ProcedureTemplateDto) => TemplateActions.createSuccess({ procedureTemplate }))
    );
  });
  public createEquipmentSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(TemplateActions.createSuccess),
        tap(() => {
          this.#router.navigate(['/settings', 'procedures']);
        }),
        map(action =>
          TemplateActions.message({
            message: `Zapisano nowy szablon procedury ${action.procedureTemplate.name}`,
            severity: 'success',
          })
        )
      );
    },
    { dispatch: false }
  );
  public fetchTemplatesList$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.fetchList),
      switchMap(() => this.#templateService.procedureTemplateControllerReadAll()),
      map((templates: ProcedureTemplateDto[]) => TemplateActions.fetchListSuccess({ templates }))
    );
  });
  public updateTemplate$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.update),
      switchMap(action =>
        this.#templateService.procedureTemplateControllerUpdate({
          body: action.payload,
          templateId: action.templateId,
        })
      ),
      map((procedureTemplate: ProcedureTemplateDto) => TemplateActions.updateSuccess({ procedureTemplate }))
    );
  });
  public updateSuccess$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.updateSuccess),
      map(action =>
        TemplateActions.message({
          message: `Zaktualizowano dane urządzenia ${action.procedureTemplate.name}`,
          severity: 'success',
        })
      )
    );
  });
  public delete$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.deleteTemplate),
      switchMap(action =>
        this.#templateService.procedureTemplateControllerPatchActive({
          body: { active: false },
          templateId: action.templateId,
        })
      ),
      map((template: ProcedureTemplateDto) => TemplateActions.deleteTemplateSuccess({ template }))
    );
  });
  public restore$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.restoreTemplate),
      switchMap(action =>
        this.#templateService.procedureTemplateControllerPatchActive({
          body: { active: true },
          templateId: action.templateId,
        })
      ),
      map((template: ProcedureTemplateDto) => TemplateActions.restoreTemplateSuccess({ template }))
    );
  });
  public showMessage$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(TemplateActions.message),
        tap(action => {
          this.#messageService.add({ detail: action.message, severity: action.severity, summary: 'Schematy procedur' });
        }),
        map(() => from(this.#router.navigate(['/settings', 'procedures'])))
      );
    },
    { dispatch: false }
  );
}
