<div class="flex flex-column gap-4" [formGroup]="dateTimeForm">
  <p-calendar
    [minDate]="minDate"
    [maxDate]="maxDate"
    [inline]="true"
    [formControl]="dateTimeForm.controls.date"
    dateFormat="dd-mm-yy"></p-calendar>
  <p-floatLabel>
    <p-calendar
      timeSeparator=":"
      [stepMinute]="10"
      [timeOnly]="true"
      [formControl]="dateTimeForm.controls.time"></p-calendar>
    <label translate>appointment.startTime</label>
  </p-floatLabel>
  <p-button
    (click)="emitValue()"
    [label]="'accept' | translate"
    styleClass="w-full"
    [disabled]="dateTimeForm.pristine || dateTimeForm.invalid || isDisabled()"></p-button>
</div>
