/* tslint:disable */
/* eslint-disable */
export enum SpeciesEnum {
  DOG = 'dog',
  CAT = 'cat',
  HORSE = 'horse',
  KAWIA = 'kawia',
  RAT = 'rat',
  RABBIT = 'rabbit',
  OTHER = 'other',
}
