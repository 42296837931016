/* eslint-disable  @typescript-eslint/explicit-function-return-type */
import { sortByCreatedAt } from '@kate-fizjo/shared/tools';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { APPOINTMENT_FEATURE_KEY, AppointmentState, RangeDto } from './appointment.models';
import { appointmentAdapter } from './appointment.reducer';
import { AppointmentDto, AppointmentType, ProcedureDataDto, TemplatedFormValueDto } from '../api/models';

export const selectAppointmentsState = createFeatureSelector<AppointmentState>(APPOINTMENT_FEATURE_KEY);

const { selectAll } = appointmentAdapter.getSelectors();

export const selectAllAppointments = createSelector(selectAppointmentsState, (state: AppointmentState) =>
  selectAll(state)
);

export const selectReady = createSelector(selectAppointmentsState, (state: AppointmentState) => state.ready);

export const selectAppointment = (appointmentId: string) =>
  createSelector(selectAppointmentsState, (state: AppointmentState) => state.entities[appointmentId]);

export const selectAppointmentFullReady = (appointmentId: string) =>
  createSelector(selectAppointmentsState, (state: AppointmentState) => {
    const appointment: AppointmentDto | undefined = state.entities[appointmentId];

    if (appointment === undefined) {
      return false;
    }
    switch (appointment.type) {
      case AppointmentType.CONSULTATION:
      case AppointmentType.GENERAL:
        return (appointment.data as TemplatedFormValueDto).steps?.length > 0;
      default:
        return !!(appointment.data as ProcedureDataDto).procedures;
    }
  });

export const selectAppointmentByPatient = (patientId: string) =>
  createSelector(selectAllAppointments, (appointments: AppointmentDto[]) =>
    appointments.filter((appointment: AppointmentDto) => appointment.patient === patientId)
  );

export const selectPatientAppointmentsRange = (patientId: string) =>
  createSelector(selectAppointmentByPatient(patientId), (appointments: AppointmentDto[]) =>
    appointmentsRange(appointments)
  );

export const selectAppointmentsRange = createSelector(selectAllAppointments, (appointments: AppointmentDto[]) =>
  appointmentsRange(appointments)
);

export function appointmentsRange(appointments: AppointmentDto[]): RangeDto | null {
  const sorted: AppointmentDto[] = sortByCreatedAt(appointments, 'ASC');

  return sorted.length > 0
    ? {
        from: sorted[0].createdAt,
        to: sorted[sorted.length - 1].createdAt,
      }
    : null;
}
