import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { BlockableUI } from 'primeng/api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'fizjo-pro-blockable-div',
  standalone: true,
  template: ` <div [ngStyle]="style" [ngClass]="class"><ng-content></ng-content></div> `,
})
export class BlockableDivComponent implements BlockableUI {
  @Input() style: any;
  @Input() class: any;

  constructor(private el: ElementRef) {}

  public getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }
}
