<ng-container *rxIf="hasRefreshToken$; else emailLogin">
  <div class="flex flex-row justify-content-center">
    <fizjo-pro-pin-code
      [pinCount]="5"
      [label]="'auth.authenticateByPinCode' | translate"
      (accept)="refreshToken($event)"
      [formControl]="pinCode"></fizjo-pro-pin-code>
  </div>

  <button pButton [label]="'auth.login' | translate" class="w-full mt-3" (click)="refreshToken()"></button>
</ng-container>

<ng-template #emailLogin>
  <form [formGroup]="loginForm">
    <span class="p-float-label w-full mt-5">
      <input pInputText class="w-full" [formControl]="loginForm.controls.email" />
      <label translate>auth.emailAddress</label>
    </span>
    <span class="p-float-label w-full mt-5">
      <input
        pInputText
        type="password"
        class="w-full"
        (keyup.enter)="authenticate()"
        [formControl]="loginForm.controls.password" />
      <label translate>auth.password</label>
    </span>
  </form>
  <button pButton [label]="'auth.login' | translate" class="w-full mt-3" (click)="authenticate()"></button>
</ng-template>
