import { createActionGroup, props } from '@ngrx/store';

import { CalendarEventDto, CalendarEventPayloadDto, MoveCalendarEventPayload } from '../api/models';

export const CalendarActions = createActionGroup({
  events: {
    create: props<{ payload: CalendarEventPayloadDto }>(),
    createSuccess: props<{ calendarEvent: CalendarEventDto }>(),
    delete: props<{ eventId: string }>(),
    deleteSuccess: props<{ eventId: string }>(),
    fetch: props<{ start: string; end: string }>(),
    fetchSuccess: props<{ events: CalendarEventDto[] }>(),
    message: props<{ details: string; severity: string }>(),
    update: props<{ eventId: string; payload: CalendarEventPayloadDto }>(),
    updateSuccess: props<{ calendarEvent: CalendarEventDto }>(),
    dragEvent: props<{ eventId: string; payload: MoveCalendarEventPayload }>(),
    deleteWhenProcedureCreated: props<{ eventId: string }>(),
    deleteWhenGeneralCreated: props<{ eventId: string }>(),
  },
  source: 'CalendarEvent',
});
