import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NormalizePolishPipe } from '../pipes/normalize-polish.pipe';
import { NotificationDateTransformPipe } from '../pipes/notification-date-transform.pipe';

@Component({
  selector: 'fizjo-pro-notification-preview',
  standalone: true,
  imports: [CommonModule, NotificationDateTransformPipe, NormalizePolishPipe, TranslateModule],
  providers: [NotificationDateTransformPipe, NormalizePolishPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'notification-preview.component.html',
  styleUrls: ['./notification-preview.component.scss'],
})
export class NotificationPreviewComponent {
  #notificationDateTransform: NotificationDateTransformPipe = inject(NotificationDateTransformPipe);
  #normalizePolish: NormalizePolishPipe = inject(NormalizePolishPipe);
  @Input({ required: true }) date!: Date;

  @Input({ required: true }) text!: string;
  @Input() limit = 160;

  getTransformedTextLength(): number {
    const transformedText = this.#normalizePolish.transform(
      this.#notificationDateTransform.transform(this.text, this.date)
    );

    return transformedText.length;
  }
}
