import { StripePriceDto } from '@fizjo-pro/data-products';

export class StripePricesSet {
  get oneTime(): StripePriceDto | undefined {
    return this.prices.find(price => !price.recurring);
  }

  get hasRecurring(): boolean {
    return this.prices.filter(price => price.recurring).length > 0;
  }

  constructor(private prices: StripePriceDto[]) {}

  recurring(interval: 'month' | 'year'): StripePriceDto | undefined {
    return this.prices.find(price => price.recurring?.interval === interval);
  }

  static toAmount(price: StripePriceDto | undefined): number {
    if (price === undefined) {
      return 0;
    }

    return price.unit_amount ? price.unit_amount / 100 : 0;
  }
}
