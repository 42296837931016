import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUiFacade } from '@fizjo-pro/shared/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { catchError, NEVER, Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { adminAccountsActions } from './actions';
import { AccountDto } from '../api/models/account-dto';
import { AccountService } from '../api/services/account.service';

@Injectable()
export class AccountEffects {
  #action$: Actions = inject(Actions);
  #accountsService: AccountService = inject(AccountService);
  #messageService: MessageService = inject(MessageService);
  #blockUi: BlockUiFacade = inject(BlockUiFacade);
  #router: Router = inject(Router);

  public read$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(adminAccountsActions.read),
      switchMap(() => this.#accountsService.adminAccountControllerReadAll()),
      map((accounts: AccountDto[]) => adminAccountsActions.readSuccess({ accounts }))
    );
  });

  public create$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(adminAccountsActions.createAccount),
      switchMap(action => this.#accountsService.adminControllerCreateAccount({ body: action.payload })),
      map((account: AccountDto) => adminAccountsActions.createAccountSuccess({ account })),
      catchError(this.#handleError$.bind(this))
    );
  });

  public createSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(adminAccountsActions.createAccountSuccess),
        tap(() => {
          this.#blockUi.block(false);
          this.#messageService.add({ severity: 'success', summary: 'Account created' });
          this.#router.navigate(['/accounts']);
        })
      );
    },
    { dispatch: false }
  );

  #handleError$(error: HttpErrorResponse): Observable<never> {
    this.#blockUi.block(false);
    this.#messageService.add({ severity: 'error', summary: 'Error', detail: error.message });

    return NEVER;
  }
}
