/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountDto } from '../models/account-dto';
import { AccountResourceDto } from '../models/account-resource-dto';
import { LicenseDto } from '../models/license-dto';
import { ResourceType } from '../models/resource-type';
import { TenantIdParamsDto } from '../models/tenant-id-params-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation licensesControllerReadCurrentLicense
   */
  static readonly LicensesControllerReadCurrentLicensePath = '/api/account/licenses/current-license';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensesControllerReadCurrentLicense()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensesControllerReadCurrentLicense$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<LicenseDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.LicensesControllerReadCurrentLicensePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LicenseDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensesControllerReadCurrentLicense$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensesControllerReadCurrentLicense(params?: { context?: HttpContext }): Observable<LicenseDto> {
    return this.licensesControllerReadCurrentLicense$Response(params).pipe(
      map((r: StrictHttpResponse<LicenseDto>) => r.body as LicenseDto)
    );
  }

  /**
   * Path part for operation resourcesControllerGetResources
   */
  static readonly ResourcesControllerGetResourcesPath = '/api/account/resources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourcesControllerGetResources()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourcesControllerGetResources$Response(params?: {
    /**
     * The type of resource
     */
    resourceType?: Array<ResourceType>;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<AccountResourceDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ResourcesControllerGetResourcesPath, 'get');
    if (params) {
      rb.query('resourceType', params.resourceType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AccountResourceDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resourcesControllerGetResources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourcesControllerGetResources(params?: {
    /**
     * The type of resource
     */
    resourceType?: Array<ResourceType>;
    context?: HttpContext;
  }): Observable<Array<AccountResourceDto>> {
    return this.resourcesControllerGetResources$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccountResourceDto>>) => r.body as Array<AccountResourceDto>)
    );
  }

  /**
   * Path part for operation accountControllerReadOne
   */
  static readonly AccountControllerReadOnePath = '/api/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerReadOne$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<AccountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountControllerReadOnePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AccountDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerReadOne(params?: { context?: HttpContext }): Observable<AccountDto> {
    return this.accountControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation accountControllerReadTenantId
   */
  static readonly AccountControllerReadTenantIdPath = '/api/account/{appName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountControllerReadTenantId()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerReadTenantId$Response(params: {
    /**
     * The account human readable name
     */
    appName: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<TenantIdParamsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountControllerReadTenantIdPath, 'get');
    if (params) {
      rb.path('appName', params.appName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TenantIdParamsDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountControllerReadTenantId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerReadTenantId(params: {
    /**
     * The account human readable name
     */
    appName: string;
    context?: HttpContext;
  }): Observable<TenantIdParamsDto> {
    return this.accountControllerReadTenantId$Response(params).pipe(
      map((r: StrictHttpResponse<TenantIdParamsDto>) => r.body as TenantIdParamsDto)
    );
  }
}
