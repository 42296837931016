/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CalendarEventDto } from '../models/calendar-event-dto';
import { CalendarEventPayloadDto } from '../models/calendar-event-payload-dto';
import { MoveCalendarEventPayload } from '../models/move-calendar-event-payload';

@Injectable({
  providedIn: 'root',
})
export class CalendarService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation calendarEventControllerReadMany
   */
  static readonly CalendarEventControllerReadManyPath = '/api/calendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventControllerReadMany()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventControllerReadMany$Response(params: {
    /**
     * The beginning of period of calendar events
     */
    start: string;

    /**
     * The end of period of calendar events
     */
    end: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<CalendarEventDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.CalendarEventControllerReadManyPath, 'get');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CalendarEventDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventControllerReadMany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventControllerReadMany(params: {
    /**
     * The beginning of period of calendar events
     */
    start: string;

    /**
     * The end of period of calendar events
     */
    end: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<Array<CalendarEventDto>> {
    return this.calendarEventControllerReadMany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CalendarEventDto>>) => r.body as Array<CalendarEventDto>)
    );
  }

  /**
   * Path part for operation calendarEventControllerCreate
   */
  static readonly CalendarEventControllerCreatePath = '/api/calendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventControllerCreate$Response(params: {
    context?: HttpContext;
    body: CalendarEventPayloadDto;
  }): Observable<StrictHttpResponse<CalendarEventDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.CalendarEventControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CalendarEventDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventControllerCreate(params: {
    context?: HttpContext;
    body: CalendarEventPayloadDto;
  }): Observable<CalendarEventDto> {
    return this.calendarEventControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventDto>) => r.body as CalendarEventDto)
    );
  }

  /**
   * Path part for operation calendarEventControllerReadOne
   */
  static readonly CalendarEventControllerReadOnePath = '/api/calendar/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventControllerReadOne$Response(params: {
    /**
     * The MongoDB identifier of calendar event
     */
    eventId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<CalendarEventDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.CalendarEventControllerReadOnePath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CalendarEventDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventControllerReadOne(params: {
    /**
     * The MongoDB identifier of calendar event
     */
    eventId: string;
    context?: HttpContext;
  }): Observable<CalendarEventDto> {
    return this.calendarEventControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventDto>) => r.body as CalendarEventDto)
    );
  }

  /**
   * Path part for operation calendarEventControllerUpdate
   */
  static readonly CalendarEventControllerUpdatePath = '/api/calendar/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventControllerUpdate$Response(params: {
    /**
     * The MongoDB identifier of calendar event
     */
    eventId: string;
    context?: HttpContext;
    body: CalendarEventPayloadDto;
  }): Observable<StrictHttpResponse<CalendarEventDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.CalendarEventControllerUpdatePath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CalendarEventDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventControllerUpdate(params: {
    /**
     * The MongoDB identifier of calendar event
     */
    eventId: string;
    context?: HttpContext;
    body: CalendarEventPayloadDto;
  }): Observable<CalendarEventDto> {
    return this.calendarEventControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventDto>) => r.body as CalendarEventDto)
    );
  }

  /**
   * Path part for operation calendarEventControllerDelete
   */
  static readonly CalendarEventControllerDeletePath = '/api/calendar/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventControllerDelete$Response(params: {
    /**
     * The MongoDB identifier of calendar event
     */
    eventId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.CalendarEventControllerDeletePath, 'delete');
    if (params) {
      rb.path('eventId', params.eventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventControllerDelete(params: {
    /**
     * The MongoDB identifier of calendar event
     */
    eventId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.calendarEventControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation calendarEventControllerMoveEvent
   */
  static readonly CalendarEventControllerMoveEventPath = '/api/calendar/{eventId}/move';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventControllerMoveEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventControllerMoveEvent$Response(params: {
    /**
     * The MongoDB identifier of calendar event
     */
    eventId: string;
    context?: HttpContext;
    body: MoveCalendarEventPayload;
  }): Observable<StrictHttpResponse<CalendarEventDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.CalendarEventControllerMoveEventPath, 'patch');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CalendarEventDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventControllerMoveEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventControllerMoveEvent(params: {
    /**
     * The MongoDB identifier of calendar event
     */
    eventId: string;
    context?: HttpContext;
    body: MoveCalendarEventPayload;
  }): Observable<CalendarEventDto> {
    return this.calendarEventControllerMoveEvent$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventDto>) => r.body as CalendarEventDto)
    );
  }
}
