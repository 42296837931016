import { forwardRef, ForwardRefFn, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export function valueAccessorFactory(forwardRefFn: ForwardRefFn): Provider {
  return {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(forwardRefFn),
  };
}
