<div
  class="w-full flex flex-row px-3 py-2 border-bottom-1 border-300 bg-white align-items-center hover:bg-primary-50 cursor-pointer">
  <div class="flex-grow-1 flex flex-row gap-2 align-items-center" (click)="selectItem.emit()">
    <ng-content></ng-content>
  </div>
  <p-speedDial
    *ngIf="speedDialItems && speedDialItems.length"
    direction="left"
    showIcon="pi pi-ellipsis-v"
    buttonClassName="p-button-text"
    [model]="speedDialItems"></p-speedDial>
</div>
