/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PATIENT_FEATURE_KEY, PatientState } from './patient.models';
import { patientAdapter } from './patient.reducer';
import { PatientDto } from '../api/models';

export const getPatientState = createFeatureSelector<PatientState>(PATIENT_FEATURE_KEY);

const { selectAll } = patientAdapter.getSelectors();

export const selectAllPatients = createSelector(getPatientState, (state: PatientState) => selectAll(state));

export const selectPatient = (patientId: string) =>
  createSelector(getPatientState, (state: PatientState) => state.entities[patientId]);

export const selectPatientName = (patientId: string) =>
  createSelector(selectPatient(patientId), (patient: PatientDto | undefined) => patient?.name);
export const selectReady = createSelector(getPatientState, (state: PatientState) => state.ready);

export const selectUnBindPatients = createSelector(selectAllPatients, (patients: PatientDto[]) =>
  patients.filter(
    (patient: PatientDto) => patient.owner === undefined || patient.owner === '' || patient.owner === null
  )
);

export const selectBindPatients = createSelector(selectAllPatients, (parents: PatientDto[]) =>
  parents.filter((patient: PatientDto) => !!patient.owner)
);
export const selectByOwner = (ownerId: string) =>
  createSelector(
    selectAllPatients,
    (patients: PatientDto[]) => patients.filter((patient: PatientDto) => patient.owner === ownerId) || []
  );
