import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { AccountFacade } from './account.facade';
import { ApiModule } from './api/api.module';
import { AccountEffects } from './state/account.effects';
import { accountFeature } from './state/account.feature';

export const accountProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(ApiModule),
  provideState(accountFeature),
  provideEffects([AccountEffects]),
  AccountFacade,
];
