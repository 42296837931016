import { EntityState } from '@ngrx/entity';

import { CalendarEventDto } from '../api/models';

export const CALENDAR_FEATURE_KEY = 'calendar';

export interface CalendarEventState extends EntityState<CalendarEventDto> {
  ready: boolean;
}

export interface CalendarEventsRange {
  start: string;
  end: string;
}

export type CalendarEventType = 'GENERAL' | 'PROCEDURE' | 'LOCK' | 'CONSULTATION';
