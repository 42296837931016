export class Color {
  public static invert(hex: string, bw?: boolean): string {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r: number = parseInt(hex.slice(0, 2), 16);
    const g: number = parseInt(hex.slice(2, 4), 16);
    const b: number = parseInt(hex.slice(4, 6), 16);

    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }

    return `#${Color.padZero((255 - r).toString(16))}${Color.padZero((255 - g).toString(16))}${Color.padZero(
      (255 - b).toString(16)
    )}`;
  }

  public static addAlpha(hex: string, opacity: number): string {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    const _opacity: number = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

    return `#${hex}${_opacity.toString(16).toUpperCase()}`;
  }

  private static padZero(str: string, len = 2): string {
    return (new Array(len).join('0') + str).slice(-len);
  }
}
