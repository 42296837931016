import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
  standalone: true,
})
export class PluralPipe implements PipeTransform {
  public transform(value: string, _count: number): string {
    const count = _count <= 20 ? _count : _count % 10;

    if (count === 1) {
      return `${value}.single`;
    }
    if (count >= 2 && count < 5) {
      return `${value}.double`;
    }

    return `${value}.plural`;
  }
}
