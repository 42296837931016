/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountDto } from '../models/account-dto';
import { AccountRequestPayloadDto } from '../models/account-request-payload-dto';
import { AccountResourcePayloadDto } from '../models/account-resource-payload-dto';
import { CheckDomainPayloadDto } from '../models/check-domain-payload-dto';
import { ReplaceLicensePayloadDto } from '../models/replace-license-payload-dto';
import { StatsDto } from '../models/stats-dto';
import { UpdateLicenseValidUntilDto } from '../models/update-license-valid-until-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation adminAccountControllerReadAll
   */
  static readonly AdminAccountControllerReadAllPath = '/admin/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAccountControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAccountControllerReadAll$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<AccountDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AdminAccountControllerReadAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AccountDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminAccountControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAccountControllerReadAll(params?: { context?: HttpContext }): Observable<Array<AccountDto>> {
    return this.adminAccountControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccountDto>>) => r.body as Array<AccountDto>)
    );
  }

  /**
   * Path part for operation adminControllerCreateAccount
   */
  static readonly AdminControllerCreateAccountPath = '/admin/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerCreateAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerCreateAccount$Response(params: {
    /**
     * The MongoDB identifier of the document
     */
    requestId?: string;
    context?: HttpContext;
    body: AccountRequestPayloadDto;
  }): Observable<StrictHttpResponse<AccountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AdminControllerCreateAccountPath, 'post');
    if (params) {
      rb.query('requestId', params.requestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AccountDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminControllerCreateAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerCreateAccount(params: {
    /**
     * The MongoDB identifier of the document
     */
    requestId?: string;
    context?: HttpContext;
    body: AccountRequestPayloadDto;
  }): Observable<AccountDto> {
    return this.adminControllerCreateAccount$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation schedulerCallbackControllerCheckDomain
   */
  static readonly SchedulerCallbackControllerCheckDomainPath = '/admin/scheduler-callback/check-domain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `schedulerCallbackControllerCheckDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  schedulerCallbackControllerCheckDomain$Response(params: {
    context?: HttpContext;
    body: CheckDomainPayloadDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.SchedulerCallbackControllerCheckDomainPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `schedulerCallbackControllerCheckDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  schedulerCallbackControllerCheckDomain(params: {
    context?: HttpContext;
    body: CheckDomainPayloadDto;
  }): Observable<void> {
    return this.schedulerCallbackControllerCheckDomain$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation resourceControllerUpdateResource
   */
  static readonly ResourceControllerUpdateResourcePath = '/admin/account/{tenantId}/resources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceControllerUpdateResource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceControllerUpdateResource$Response(params: {
    /**
     * Tenant ID
     */
    tenantId: string;
    context?: HttpContext;
    body: AccountResourcePayloadDto;
  }): Observable<StrictHttpResponse<AccountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ResourceControllerUpdateResourcePath, 'put');
    if (params) {
      rb.path('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AccountDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resourceControllerUpdateResource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceControllerUpdateResource(params: {
    /**
     * Tenant ID
     */
    tenantId: string;
    context?: HttpContext;
    body: AccountResourcePayloadDto;
  }): Observable<AccountDto> {
    return this.resourceControllerUpdateResource$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation licenseControllerUpdateLicense
   */
  static readonly LicenseControllerUpdateLicensePath = '/admin/account/{tenantId}/licenses/{licenseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licenseControllerUpdateLicense()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licenseControllerUpdateLicense$Response(params: {
    /**
     * The tenant id
     */
    tenantId: string;

    /**
     * The license id
     */
    licenseId: string;
    context?: HttpContext;
    body: UpdateLicenseValidUntilDto;
  }): Observable<StrictHttpResponse<AccountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.LicenseControllerUpdateLicensePath, 'put');
    if (params) {
      rb.path('tenantId', params.tenantId, {});
      rb.path('licenseId', params.licenseId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AccountDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licenseControllerUpdateLicense$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licenseControllerUpdateLicense(params: {
    /**
     * The tenant id
     */
    tenantId: string;

    /**
     * The license id
     */
    licenseId: string;
    context?: HttpContext;
    body: UpdateLicenseValidUntilDto;
  }): Observable<AccountDto> {
    return this.licenseControllerUpdateLicense$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation licenseControllerReplaceLicense
   */
  static readonly LicenseControllerReplaceLicensePath = '/admin/account/{tenantId}/licenses/{licenseId}/replace';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licenseControllerReplaceLicense()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licenseControllerReplaceLicense$Response(params: {
    /**
     * The tenant id
     */
    tenantId: string;

    /**
     * The license id
     */
    licenseId: string;
    context?: HttpContext;
    body: ReplaceLicensePayloadDto;
  }): Observable<StrictHttpResponse<AccountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.LicenseControllerReplaceLicensePath, 'put');
    if (params) {
      rb.path('tenantId', params.tenantId, {});
      rb.path('licenseId', params.licenseId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AccountDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licenseControllerReplaceLicense$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licenseControllerReplaceLicense(params: {
    /**
     * The tenant id
     */
    tenantId: string;

    /**
     * The license id
     */
    licenseId: string;
    context?: HttpContext;
    body: ReplaceLicensePayloadDto;
  }): Observable<AccountDto> {
    return this.licenseControllerReplaceLicense$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation adminStatsControllerReadStats
   */
  static readonly AdminStatsControllerReadStatsPath = '/admin/account/{tenantId}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatsControllerReadStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatsControllerReadStats$Response(params: {
    tenantId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<StatsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AdminStatsControllerReadStatsPath, 'get');
    if (params) {
      rb.path('tenantId', params.tenantId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StatsDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminStatsControllerReadStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatsControllerReadStats(params: { tenantId: string; context?: HttpContext }): Observable<StatsDto> {
    return this.adminStatsControllerReadStats$Response(params).pipe(
      map((r: StrictHttpResponse<StatsDto>) => r.body as StatsDto)
    );
  }
}
