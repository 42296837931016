import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  HostListener,
  inject,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { selectHasValidLicense } from '@fizjo-pro/data-account';
import { selectReady as selectAccountReady } from '@fizjo-pro/data-account';
import { selectInitials, selectIsAuthenticated } from '@fizjo-pro/data-auth';
import { MarketplaceStore } from '@fizjo-pro/data-marketplace';
import { SwitchAppUserComponent } from '@fizjo-pro/ui-app-user';
import { CheckoutDialogComponent } from '@fizjo-pro/ui-marketplace';
import { MessagesNotificationComponent } from '@fizjo-pro/ui-messages';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenubarModule } from 'primeng/menubar';
import { ScrollPanelModule } from 'primeng/scrollpanel';

import { MenuService } from './menu.service';

@Component({
  selector: 'fizjo-pro-app-layout',
  standalone: true,
  imports: [
    CommonModule,
    MenubarModule,
    MessagesNotificationComponent,
    SharedModule,
    SwitchAppUserComponent,
    TranslateModule,
    ScrollPanelModule,
    ButtonModule,
    DynamicDialogModule,
  ],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
  providers: [MenuService, MarketplaceStore],
})
export class AppLayoutComponent implements AfterViewInit {
  readonly #offset = 100;
  #store: Store = inject(Store);
  #dialog: DialogService = inject(DialogService);
  #menuService: MenuService = inject(MenuService);
  #hasValidLicense: Signal<boolean> = this.#store.selectSignal(selectHasValidLicense);
  #selectAccountReady: Signal<boolean> = this.#store.selectSignal(selectAccountReady);
  #marketPlace = inject(MarketplaceStore);

  #height: WritableSignal<number> = signal(800);

  containerStyle: Signal<Record<string, string>> = computed(() => ({ height: `${this.#height()}px` }));

  protected hasExpiredLicenseBannerVisible = computed(() => !this.#hasValidLicense() && this.#selectAccountReady());
  protected authenticated: Signal<boolean> = this.#store.selectSignal(selectIsAuthenticated);
  protected allMenuItems: Signal<MenuItem[]> = this.#menuService.allMenuItems;
  protected initials: Signal<string | null> = this.#store.selectSignal(selectInitials);
  protected hasOrder: Signal<boolean> = this.#marketPlace.hasOrder;

  public ngAfterViewInit(): void {
    this.resize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.resize();
  }

  resize(): void {
    const noLicenseBannerHeight: number = this.#hasValidLicense() ? 0 : 35;

    this.#height.set(window.innerHeight - (this.#offset + noLicenseBannerHeight));
  }

  orderHandler(): void {
    this.#dialog.open(CheckoutDialogComponent, {
      width: '800px',
      closable: false,
      showHeader: false,
      closeOnEscape: true,
      styleClass: 'checkout-dialog',
      data: this.#marketPlace.order(),
    });
  }
}
