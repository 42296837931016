import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AbstractValueAccessorComponent, valueAccessorFactory } from '@kate-fizjo/practice-shared/tools';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

import { AddressForm, addressFormFactory } from './adress.form';
import { AddressPayloadDto } from '../../api/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, DropdownModule],
  providers: [valueAccessorFactory(() => AddressComponent)],
  selector: 'kf-address',
  standalone: true,
  templateUrl: './address.component.html',
})
export class AddressComponent extends AbstractValueAccessorComponent<AddressPayloadDto> {
  public readonly types: { label: string; value: string }[] = [
    { label: 'Inne', value: 'other' },
    { label: 'Służbowy', value: 'office' },
    { label: 'Domowy', value: 'home' },
  ];

  protected override isPatch = true;
  public formGroup: FormGroup<AddressForm> = addressFormFactory();
}
