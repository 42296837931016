<div class="container line-height-3">
  <p-card header="Polityka prywatności fizjo.pro">
    <p>Data aktualizacji: ……</p>
    <p>
      W niniejszym dokumencie wyjaśniamy nasze podejście do ochrony danych osobowych oraz zasady i sposób przetwarzania
      informacji na temat osób korzystających z naszych usług.
    </p>
    <p>
      Niniejsza Polityka Prywatności stanowi również realizację obowiązków informacyjnych, o których mowa w art. 13 (w
      przypadku zbierania danych od osoby, której dotyczą) i 14 (w przypadku pozyskiwania danych w inny sposób niż od
      osoby, której dotyczą) RODO.
    </p>
    <ol class="p-0">
      <li>
        <strong>1.&nbsp;Definicje</strong>
        <ol>
          <li>
            <strong>1.1.&nbsp;Administrator</strong> – ANIMALWARE.PRO Sp. z o.o. z siedzibą w Warszawie, pod adresem ul.
            Aleja Jana Pawła II, nr 27, 00-867 Warszawa, wpisany do rejestru przedsiębiorców prowadzonego przez Sąd
            Rejonowy dla M.St. Warszawy, XIII Wydział Gospodarczy pod numerem KRS: 0001111201, REGON: 528918717, NIP:
            5273116833;
          </li>
          <li>
            <strong>1.2.&nbsp;Strona Internetowa</strong> – strona internetowa prowadzona przez Administratora w domenie
            fizjo.pro (wraz z ewentualnymi subdomenami), do których stosuje się niniejszą Politykę;
          </li>
          <li>
            <strong>1.3.&nbsp;Aplikacja</strong> – aplikacja internetowa o nazwie fizjo.pro udostępnianą przez
            Administratora pod adresem https://[domena-klienta].fizjo.pro oraz
            <a href="https://mypet.fizjo.pro" target="_blank">https://mypet.fizjo.pro</a>, umożliwiająca korzystanie z
            funkcjonalności opisanych w Regulaminie Usługi fizjo.pro;
          </li>
          <li><strong>1.4.&nbsp;Serwis</strong> – Aplikacja oraz Strona Internetowa;</li>
          <li>
            <strong>1.5.&nbsp;Klient</strong> - podmiot, z którym Administrator zawarł lub negocjuje zawarcie umowy, na
            mocy której ma świadczyć Usługi, w szczególności usługi drogą elektroniczną na mocy Regulaminu Usługi
            fizjo.pro;
          </li>
          <li>
            <strong>1.6.&nbsp; Użytkownik</strong> – osoba odwiedzająca Stronę Internetową lub korzystająca z
            funkcjonalności tam udostępnianych;
          </li>
          <li>
            <strong>1.7.&nbsp; Użytkownik Aplikacji</strong> - osoba fizyczna, którą Klient wyznaczył i upoważnił do
            korzystania z Aplikacji w ramach Konta Klienta, w ramach zarejestrowanego Konta Użytkownika, i po jego
            aktywacji dokonanej indywidualnie przez tę osobę, zgodnie z Warunkami Korzystania z Aplikacji;
          </li>
          <li>
            <strong>1.8.&nbsp;Usługi</strong> – świadczenia realizowane przez Administratora za pomocą Aplikacji lub
            wskutek interakcji korzystającego z funkcjonalności dostępnych na Stronie Internetowej, w tym usługi
            świadczone drogą elektroniczną w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
            elektroniczną;
          </li>
          <li>
            <strong>1.9.&nbsp;Dane osobowe</strong> – wszystkie informacje o zidentyfikowanej lub możliwej do
            zidentyfikowania osobie fizycznej poprzez jeden bądź kilka szczególnych czynników określających fizyczną,
            fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej, w tym
            nr IP urządzenia, dane o lokalizacji, identyfikatory internetowe oraz informacje gromadzone za pośrednictwem
            plików cookie lub Aplikacji;
          </li>
          <li>
            <strong>1.10.&nbsp;RODO</strong> – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 7
            kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE;
          </li>
          <li>
            <strong>1.11.&nbsp;Pliki cookie</strong> – niewielkie pliki tekstowe zapisywane na urządzeniu Użytkownika
            podczas korzystania ze Strony Internetowej oraz wszelkie inne podobne technologie służące do zbierania
            informacji o aktywności Użytkownika na Stronie Internetowej. Pliki cookie mogą pochodzić od Administratora
            lub od zaufanych partnerów Administratora;
          </li>
          <li>
            <strong>1.12.&nbsp;Urządzenie</strong> – każde urządzenie, poprzez które możliwe jest korzystanie z Usług,
            m.in. komputer, tablet czy smartfon;
          </li>
          <li>
            <strong>1.13.&nbsp;Świadczeniodawcy</strong> – podmioty, ze świadczeń których Administrator korzysta
            prowadząc Stronę Internetową lub świadcząc Usługi, w związku z czym dokonuje powierzenia przetwarzania
            Danych osobowych w niezbędnym zakresie, zgodnie z obowiązującymi przepisami.
          </li>
          <li><strong>1.14.&nbsp;Polityka</strong> – Polityka prywatności - niniejszy dokument.</li>
          <li>
            <strong>1.15.&nbsp;</strong>Jak można kontaktować się z Administratorem?
            <ol>
              <li>
                Z ANIMALWARE.PRO Sp. z o.o. można kontaktować się mailowo wysyłając maila na adres:
                kontakt&commat;fizjo.pro lub pisemnie przesyłając korespondencję na adres ul. Aleja Jana Pawła II, nr
                27, 00-867 Warszawa.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <strong>2.&nbsp;Jakie dane przetwarzamy?</strong>
        <ol>
          <li>
            W trakcie korzystania z naszych Usług Użytkownik, a także Użytkownik Aplikacji może przekazywać nam Dane
            osobowe w postaci:
            <ol>
              <li><strong>2.1.&nbsp;</strong>imienia i nazwiska,</li>
              <li><strong>2.2.&nbsp;</strong>adresu e-mail,</li>
              <li><strong>2.3.&nbsp;</strong>numeru telefonu,</li>
              <li>
                <strong>2.4.&nbsp;</strong>
                dane do rozliczeń płatności – dane Klienta niezbędne do dokonywania rozliczeń w ramach płatnych Usług,
                m.in. adres siedziby/zamieszkania czy NIP,
              </li>
              <li><strong>2.5.&nbsp;</strong>treść przekazanej przez Użytkownika informacji w ramach kontaktu,</li>
              <li>
                <strong>2.6.&nbsp;</strong>treść informacji wprowadzanej do Aplikacji przez Użytkownika Aplikacji,
              </li>
              <li>
                <strong>2.7.&nbsp;</strong>
                dane Użytkownika – takie jak adres IP czy inne identyfikatory oraz informacje gromadzone za
                pośrednictwem Plików cookie lub innych podobnych technologii, pozyskiwane w związku z odwiedzinami
                Strony Internetowej, które w określonych okolicznościach mogą także stanowić Dane osobowe.
              </li>
              <li>
                Dane Użytkowania pobierane są automatycznie w trakcie korzystania z Usług i są to głównie dane w postaci
                statystycznej (anonimowe) dotyczące aktywności Użytkownika na Stronie Internetowej Serwisu.
              </li>
              <li>
                użytkowania mogą zawierać informacje dotyczące adresu IP Urządzenia, systemu operacyjnego lub inne dane
                identyfikujące to Urządzenie, jak również rodzaj i wersję przeglądarki, której używasz, rodzaju
                odwiedzanych stron, czasu i daty wizyty na konkretnej stronie internetowej.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <strong>3.&nbsp;W jakim celu i na jakiej podstawie prawnej przetwarzamy twoje dane?</strong>
        <ol>
          <li>
            <strong>3.1.&nbsp;</strong>Dane osobowe przetwarzamy:
            <ol>
              <li>
                <strong>3.1.1.&nbsp;</strong>
                w celu udostępniana treści prezentowanych na Stronie Internetowej i Newsletterze – wówczas podstawą
                prawną przetwarzania jest nasz uzasadniony interes polegający na rozpowszechnianiu naszych treści (art.
                6 ust. 1 lit. f) RODO); w celach analitycznych i statystycznych (m.in. poprzez Pliki cookie)– wówczas
                podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO),
              </li>
              <li>
                <strong>3.1.2.&nbsp;</strong>
                polegający na prowadzeniu analiz aktywności Użytkowników, a także ich preferencji, w celu poprawy
                stosowanych funkcjonalności i świadczonych Usług; Zgodnie z obowiązującymi przepisami Pliki cookie
                przetwarzamy na podstawie udzielonej przez Ciebie zgody zgodnie z wybranymi przez Ciebie opcjami
                zarządzania cookies;
              </li>
              <li>
                <strong>3.1.3.&nbsp;</strong>
                w przypadku, gdy przekazujesz nam swoje dane dla celów kontaktu lub udzielenia dalszych interesujących
                Cię informacji, w tym informacji promocyjnych lub ofert świadczenia Usług – wówczas podstawą prawną
                przetwarzania jest udzielona przez Ciebie zgoda (art. 6 ust. 1 lit. a) RODO), którą możesz w każdym
                czasie cofnąć; wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na
                podstawie zgody przed jej wycofaniem;
              </li>
              <li>
                <strong>3.1.4.&nbsp;</strong>
                w celu wykonywania czynności w ramach świadczenia Usług i w wykonaniu umowy o świadczenie usług drogą
                elektroniczną, gdy zdecydowałeś korzystać z naszej Aplikacji – wówczas podstawą prawną przetwarzania
                jest niezbędność dla wykonywania umowy pomiędzy Klientem a Administratorem lub do podjęcia działań na
                żądanie Klienta przed zawarciem umowy z Administratorem (art. 6 ust. 1 lit. b) RODO);
              </li>
              <li>
                <strong>3.1.5.&nbsp;</strong>
                w celu prowadzenia rozliczeń z Klientami lub udostępniania Danych osobowych na żądanie upoważnionych
                organów lub osób, w granicach i na mocy obowiązujących przepisów prawnych – wówczas podstawą prawną
                przetwarzania jest niezbędność wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust.2
                lit. c RODO);
              </li>
              <li>
                <strong>3.1.6.&nbsp;</strong>
                w przypadku, gdy jesteś Użytkownikiem Aplikacji, a dane zostały udostępnione przez Klienta – w celach
                poznania Twoich preferencji lub opinii na temat korzystania z Aplikacji lub rozwoju naszych Usług –
                wówczas podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO),
                polegający na prowadzeniu analiz aktywności Użytkowników Aplikacji, odwiedzających nasz fanpage
                Facebook, Instagram i TikTOK, a także ich preferencji,
              </li>
              <li>
                <strong>3.1.7.&nbsp;</strong>
                w celu poprawy stosowanych funkcjonalności i świadczonych Usług; w celu ewentualnego ustalenia,
                dochodzenia lub obrony przed roszczeniami – podstawą prawną przetwarzania jest nasz uzasadniony interes
                (art. 6 ust. 1 lit. f) RODO) polegający na ochronie naszych praw, a przetwarzanie następuje maksymalnie
                przez okres przedawnienia roszczeń jakie mogą nam przysługiwać.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <ol>
          <li>
            <strong>3.2.&nbsp;</strong>
            Wobec Danych osobowych dotyczących Użytkowników Aplikacji, co do zasady jesteśmy podmiotem przetwarzającym
            na mocy Regulaminu, w którym określono zasady powierzenia nam przetwarzania danych osobowych przez Klienta.
            Działamy wówczas wyłącznie na polecenie Klienta jako administratora danych osobowych, za wyjątkiem sytuacji,
            gdy Dane osobowe Użytkowników Aplikacji pozyskujemy i przetwarzamy w ramach naszego uzasadnionego interesu
            (jak wskazano powyżej), pozostają wówczas wobec takich danych jesteśmy Administratorem.
          </li>
          <li>
            <strong>3.3.&nbsp;</strong>
            W każdym przypadku, a zwłaszcza działając jako podmiot przetwarzający i przetwarzając Dane osobowe w imieniu
            i na rzecz Klienta w związku z dostępem do Aplikacji, zapewniamy wystarczające gwarancje wdrożenia
            odpowiednich środków technicznych i organizacyjnych, by przetwarzanie spełniało wymogi RODO i chroniło prawa
            osób, których dane dotyczą.
          </li>
        </ol>
      </li>
      <li>
        <strong>4.&nbsp;Odbiorcy danych – dalsze powierzanie przetwarzania</strong>
        <ol>
          <li>
            W sytuacjach uzasadnionych koniecznością przekazujemy Dane osobowe podmiotom trzecim. Takimi odbiorcami mogą
            być:
            <ol>
              <li>
                <strong>4.1.&nbsp;</strong>Podmioty i organy publiczne, w tym Policja, Krajowa Administracja Skarbowa,
                inne organy ścigania, wyłącznie na żądanie mające podstawę we właściwych przepisów prawa.
              </li>
              <li>
                <strong>4.2.&nbsp;</strong>Podwykonawcy – podmioty, którym zlecamy wykonywanie usług, z którymi wiąże
                się przetwarzanie Danych osobowych, a są to:
                <ul>
                  <li>podmioty prowadzące obsługę naszej działalności – księgowość,</li>
                  <li>
                    kancelarie prawne itp. dostawcy usług, z których korzystamy świadcząc Usługi w zakresie utrzymania
                    Aplikacji;
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <strong>5.&nbsp;Pliki cookies i technologie śledzenia</strong>
        <ol>
          <li>
            Używamy Plików Cookie i podobnych technologii śledzenia, aby śledzić aktywność w naszej Usłudze i
            przechowywać określone informacje.
          </li>
          <li>
            <strong>5.1.&nbsp;</strong>
            Jakie rodzaje Plików cookie są wykorzystywane w Serwisie?
            <ol>
              <li>
                Cookies stosowane w Serwisie dzielą się na następujące kategorie:
                <ol>
                  <li>
                    <strong>5.1.1.&nbsp;</strong>Niezbędne Pliki cookie - Te pliki cookies są instalowane, aby zapewnić
                    Użytkownikowi dostęp do Serwisu i jego podstawowych funkcji, nie wymagają zatem zgody Użytkownika.
                    Bez niezbędnych Plików cookie Administrator nie byłby w stanie świadczyć Użytkownikom usług w ramach
                    Serwisu;
                  </li>
                  <li>
                    <strong>5.1.2.&nbsp;</strong>Opcjonalne Pliki cookie - Z tych cookies Administrator korzysta
                    wyłącznie wtedy, gdy Użytkownik wyrazi na to zgodę. Są to cookies:
                    <ul>
                      <li>
                        funkcjonalne – pozwalają na zapamiętanie preferencji lub wyborów Użytkowników (takich jak nazwa
                        użytkownika, język, rozmiar tekstu lub inne elementy, które można dostosować) i dostarczanie
                        użytkownikom spersonalizowanych treści w ramach Serwisu;
                      </li>
                      <li>
                        analityczne – umożliwiają sprawdzenie liczby wizyt i źródeł ruchu na Stronie Internetowej.
                        Pomagają rozpoznać, które strony są mniej lub bardziej popularne, i zrozumieć, jak użytkownicy
                        poruszają się po stronie. Dzięki temu Administrator może poprawiać wydajność Serwisu;
                      </li>
                      <li>
                        reklamowe – wykorzystywane są w celu dostarczenia reklam zgodnych z zainteresowaniami i
                        preferencjami Użytkowników. Na podstawie informacji z tych plików i aktywności w innych
                        serwisach jest budowany profil zainteresowań Użytkowników.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>5.2.&nbsp;</strong>
            Do czego są wykorzystywane Pliki cookie na Stronie Internetowej?
            <ol>
              <li>
                <strong>5.2.1&nbsp;</strong>
                Administrator wykorzystuje Pliki cookie przede wszystkim, aby umożliwić Użytkownikowi dostęp do Serwisu
                i ułatwić Użytkownikowi korzystanie z niego.
              </li>
              <li>
                <strong>5.2.2.&nbsp;</strong>
                Administrator wykorzystuje Pliki cookie również w celach analitycznych oraz marketingowych – jednak
                wyłącznie wtedy, gdy Użytkownik wyrazi na to zgodę przy pierwszym wejściu do Serwisu.
              </li>
            </ol>
          </li>
          <li>
            <strong>5.3.&nbsp;</strong>Czy na Stronie Internetowej używane są Pliki cookie podmiotów trzecich?
            <ol>
              <li>
                Korzystając z Serwisu, Użytkownik może otrzymać Pliki cookie pochodzące od współpracujących z
                Administratorem podmiotów trzecich. Bardziej szczegółowe informacje znajdują się poniżej:
                <table>
                  <thead>
                    <tr>
                      <th>Podmiot trzeci</th>
                      <th>Opis</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Dostawcy usług analitycznych</td>
                      <td>
                        W celu lepszego zrozumienia, jak działa Serwis, Administrator współpracuje z dostawcami usług
                        analitycznych takimi jak Google Analytics, Meta (facebook), Tawk.to. Więcej informacji na temat
                        tego, jak wykorzystują oni dane Użytkowników, znajdziesz tutaj:
                        <ul>
                          <li>
                            <a href="https://policies.google.com/technologies/cookies?hl=pl" target="_blank"
                              >https://policies.google.com/technologies/cookies?hl=pl</a
                            >
                          </li>
                          <li>
                            <a href="https://pl-pl.facebook.com/help/336858938174917" target="_blank"
                              >https://pl-pl.facebook.com/help/336858938174917</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://help.tawk.to/article/what-are-tawkto-cookies-and-what-do-they-do"
                              target="_blank"
                              >https://help.tawk.to/article/what-are-tawkto-cookies-and-what-do-they-do</a
                            >
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </li>
          <li>
            <strong>5.4.&nbsp;</strong>Jakie Pliki cookie przetwarzamy?
            <ol>
              <li>
                Na Stronie Internetowej stosowane są stałe i sesyjne pliki cookie. Poniżej znajdziesz więcej informacji
                o tym, jak długo są one przechowywane:
                <ol>
                  <li>
                    <strong>5.4.1.&nbsp;</strong>Sesyjne Pliki cookie - niektóre cookies są plikami tymczasowymi,
                    przechowywanymi do czasu wylogowania, opuszczenia strony lub wyłączenia przeglądarki internetowej.
                    Tego rodzaju cookies pomagają analizować ruch sieciowy, umożliwiają identyfikację i rozwiązywanie
                    problemów technicznych oraz łatwiejsze poruszanie się po Serwisie;
                  </li>
                  <li>
                    <strong>5.4.2.&nbsp;</strong>„Stałe” Pliki cookie - „Stałe” Pliki cookie przechowywane są przez czas
                    określony w ich parametrach lub do czasu usunięcia ich przez Użytkownika. Pomagają Administratorowi
                    zapamiętywanie ustawień i preferencji Użytkownika, by uczynić jego kolejną wizytę wygodniejszą lub
                    dostarczyć mu dopasowaną treść. Poniżej znajduje się lista plików cookie wraz z okresem ich
                    żywotności:
                  </li>
                </ol>
              </li>
              <li>Nasz Serwis korzysta z następujących plików cookies:</li>
            </ol>
            <table>
              <tbody>
                <tr>
                  <td>Nazwa</td>
                  <td>Domena</td>
                  <td>Typ</td>
                  <td>Przeznaczenie</td>
                  <td>Wygaśnięcie</td>
                </tr>
                <tr>
                  <td>fizjoPro:tenantId</td>
                  <td>[domena-klienta].fizjo.pro</td>
                  <td>Techniczne/ niezbędne</td>
                  <td>Używane do identyfikacji. Identyfikator bazodanowy konta użytkownika Aplikacji fizjo.pro</td>
                  <td>12 miesięcy od ostatniego pomyślnego zalogowania się</td>
                </tr>
                <tr>
                  <td>fizjoPro:token</td>
                  <td>[domena-klienta].fizjo.pro</td>
                  <td>Techniczne/ Autoryzacyjne/ niezbędne</td>
                  <td>Służy do przechowywania tokena uwierzytelniającego, do logowania Użytkownika Aplikacji</td>
                  <td>4h od ostatniego pomyślnego zalogowania się</td>
                </tr>
                <tr>
                  <td>fizjopro:acceptCookie</td>
                  <td>[domena-klient].fizjo.pro</td>
                  <td>Funkcjonalne/ niezbędne</td>
                  <td>
                    Informacja o akceptacji ciasteczek. Zapisuje akceptację polityki cookies i Regulaminu Aplikacji
                  </td>
                  <td>12 miesięcy</td>
                </tr>
                <tr>
                  <td>fizjopro:calendarView</td>
                  <td>[domen-klienta].fizjo.pro</td>
                  <td>Funkcjonalne/ niezbędne</td>
                  <td>
                    Tryb pracy kalendarz w aplikacji fizjo.pro.<br />
                    Przechowuje preferencje widoku kalendarza użytkownika w aplikacji fizjo.pro.
                  </td>
                  <td>12 miesięcy</td>
                </tr>
                <tr>
                  <td></td>
                  <td>fizjo.pro</td>
                  <td>marketingowe</td>
                  <td>ciasteczka identyfikujące do Google Analytics</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>fizjo.pro</td>
                  <td>marketingowe</td>
                  <td>ciasteczka identyfikujące do Facebook</td>
                  <td></td>
                </tr>
                <tr>
                  <td>fizjoPro:customerToken</td>
                  <td>mypet.fizjo.pro</td>
                  <td>Techniczne/ Autoryzacyjne niezbędne</td>
                  <td>
                    Przechowuje token identyfikujący Użytkownika Aplikacji, używany do zarządzania sesją Użytkownika
                    Aplikacji.
                  </td>
                  <td>24 h</td>
                </tr>
                <tr>
                  <td>tawk</td>
                  <td>fizjo.pro</td>
                  <td>Funkcjonalne/ niezbędne</td>
                  <td>Token identyfikujący aplikację fizjo.pro u dostawcy usługi komunikatora Tawk</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <strong>5.5.&nbsp;</strong>Jak można zmienić ustawienia w zakresie cookies?
            <ol>
              <li>
                <strong>5.5.1.&nbsp;</strong>Na Stronie Internetowej
                <a href="https://fizjo.pro" target="_blank">https://fizjo.pro</a> stosowana jest platforma zarządzania
                zgodą, aby ułatwić Użytkownikowi zarządzanie swoimi preferencjami w zakresie cookies.
              </li>
              <li>
                <strong>5.5.2.&nbsp;</strong>Użytkownik może zmieniać swoje ustawienia dotyczące Plików cookie.
                Platforma pozwala:
                <ol>
                  <li>
                    <strong>5.5.2.1.&nbsp;</strong>
                    pozyskać szczegółowe informacje na temat Plików cookie wykorzystywanych na Stronie Internetowej oraz
                    zaufanych partnerów Administratora;
                  </li>
                  <li>
                    <strong>5.5.2.2.&nbsp;</strong>
                    wyrazić i wycofać zgodę na wykorzystanie przez Administratora i zaufanych partnerów opcjonalnych
                    Plików cookie;
                  </li>
                  <li><strong>5.5.2.3.&nbsp;</strong>zmieniać wybrane wcześniej ustawienia.</li>
                </ol>
              </li>
              <li>
                <strong>5.5.3.&nbsp;</strong>Czy możliwa jest zmiana ustawień w zakresie Plików cookie z poziomu
                przeglądarki internetowej?
              </li>
              <li>
                <strong>5.5.4.&nbsp;</strong>Przeglądarka internetowa Użytkownika powinna zapewniać możliwość zmiany
                ustawień tak, aby odrzucić, usunąć lub zablokować określone Pliki cookie. Pod tymi linkami znajdują się
                odpowiednie informacje dla najbardziej popularnych przeglądarek:
                <ul>
                  <li>
                    Google Chrome:
                    <a
                      href="https://support.google.com/chrome/answer/95647?hl=pl&co=GENIE.Platform%3DDesktop"
                      target="_blank"
                      >Zarządzanie plikami cookies w Chrome</a
                    >
                  </li>
                  <li>
                    Mozilla Firefox:
                    <a
                      href="https://support.mozilla.org/pl/kb/usuwanie-ciasteczek-i-danych-stron-firefox"
                      target="_blank"
                      >Zarządzanie plikami cookies w Firefox</a
                    >
                  </li>
                  <li>
                    Microsoft Edge:
                    <a
                      href="https://support.microsoft.com/pl-pl/windows/zarz%C4%85dzanie-plikami-cookie-w-przegl%C4%85darce-microsoft-edge-wy%C5%9Bwietlanie-zezwalanie-blokowanie-usuwanie-i-u%C5%BCywanie-168dab11-0753-043d-7c16-ede5947fc64d"
                      target="_blank"
                      >Zarządzanie plikami cookies w Edge</a
                    >
                  </li>
                  <li>
                    Safari(macOS):
                    <a href="https://support.apple.com/pl-pl/guide/deployment/depf7d5714d4/web" target="_blank"
                      >Zarządzanie plikami cookies w Safari</a
                    >
                  </li>
                  <li>
                    Opera:
                    <a href="https://help.opera.com/pl/latest/security-and-privacy/" target="_blank"
                      >Zarządzanie plikami cookies w Opera</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <strong>5.5.5.&nbsp;</strong>Ponieważ niektóre Pliki cookie są niezbędne do funkcjonowania Serwisu,
                zmiana ustawień przeglądarki może sprawić, że niektóre usługi nie będą działać prawidłowo, a nawet
                całkowicie uniemożliwić korzystanie z Serwisu.
              </li>
            </ol>
          </li>
          <li>
            <strong>5.6.&nbsp;</strong>Jak przetwarzamy Twoje dane w mediach społecznościowych?
            <ol>
              <li>
                <strong>5.6.1.&nbsp;</strong>
                Twoje dane osobowe na fanpage:
                <ul>
                  <li>
                    <a href="https://www.facebook.com/zoofizjopro" target="_blank"
                      >https://www.facebook.com/zoofizjopro</a
                    >,
                  </li>
                  <li>
                    <a href="https://www.instagram.com/prezescharlie?igsh=cmlsY3pqbGI1MDN2" target="_blank"
                      >https://www.instagram.com/prezescharlie?igsh=cmlsY3pqbGI1MDN2</a
                    >
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@fizjo.pro?is_from_webapp=1&sender_device=pc" target="_blank"
                      >www.tiktok.com/&commat;fizjo.pro</a
                    >
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/fizjopro/" target="_blank"
                      >https://www.linkedin.com/company/fizjopro/</a
                    >
                  </li>
                </ul>
                będą przetwarzane w celu komunikacji, poznania Twojej opinii na temat przedstawianych problemów i
                informacji, budowania i utrzymania społeczności z nami związanej, w tym odpowiedzi na Twoje pytania za
                pośrednictwem dostępnych funkcjonalności serwisu Facebook.
              </li>
            </ol>
          </li>
          <li>
            <strong>5.7.&nbsp;</strong>Działanie fanpage’a jest zgodne z wymogami, które są zawarte w Regulaminie
            Facebooka. Informacje zawarte na Twoim profilu oraz aktywności wynikające z jego użytkowania są bezpośrednio
            administrowane przez firmę Meta, która jest współadministratorem tych danych. Twoja aktywność związana z
            korzystania z naszego fanpage’a nie jest archiwizowana przez nas poza serwisem Facebook.
          </li>
          <li>
            <strong>5.8.&nbsp;</strong>Przetwarzamy również anonimowe dane statystyczne dotyczące osób odwiedzających
            fanpage’a dostępne za pomocą funkcji udostępnionej przez Facebooka stosownie do warunków korzystania z
            serwisu Facebook, gromadzone dzięki plikom szpiegującym (zwanym „plikami cookies”). Więcej informacji na
            temat wykorzystania cookies w ramach Facebooka znajdziesz na stronie:
            <a href="https://www.facebook.com/policies/cookies/" target="_blank"
              >https://www.facebook.com/policies/cookies/</a
            >.
          </li>
          <li>
            <strong>5.9.&nbsp;</strong>Nie mamy kontroli nad tym, jakie dane gromadzi dostawca wtyczek lub stron
            serwisów społecznościowych i jak je przetwarza. Aby uzyskać informacje na temat celu i zakresu gromadzenia
            danych, ich dalszego przetwarzania i wykorzystywania przez zewnętrznych dostawców oraz przysługujących praw
            oraz opcji ustawień ochrony prywatności tych dostawców, możesz zapoznać się z informacją o ochronie danych
            odpowiedniego dostawcy:
            <a href="https://pl-pl.facebook.com/privacy/explanation">https://pl-pl.facebook.com/privacy/explanation</a>.
          </li>
          <li><strong>5.10.&nbsp;</strong>Co do zasady nie udostępniamy Twoich danych innym podmiotom.</li>
          <li>
            <strong>5.11.&nbsp;</strong>Musisz mieć jednak na uwadze, że Twoje dane osobowe są jednocześnie przetwarzane
            przez administratora portalu społecznościowego Facebook na zasadach dotyczących danych określonych przez
            Meta.
          </li>
          <li>
            <strong>5.12.&nbsp;</strong>W przypadku informacji, które posiadamy w ramach udostępnionych przez Ciebie
            komentarzy, będą one dostępne na naszym serwisie co najmniej do czasu usunięcia ich przez autora.
          </li>
          <li>
            <strong>5.13.&nbsp;</strong>Twoje dane osobowe gromadzone przez Meta tj. historia wpisów, historia
            aktywności w aplikacji Messenger, historia aktywności poprzez aplikację Instagram podlega retencji na
            zasadach określonych w informacji o ochronie danych osobowych:
            <a href="https://www.facebook.com/about/privacy" target="_blank">https://www.facebook.com/about/privacy</a>.
          </li>
          <li><strong>5.14.&nbsp;</strong>Jak długo przechowujemy Twoje dane?</li>
          <li>
            <strong>5.15.&nbsp;</strong>Dane przetwarzane w Aplikacji będą przechowywane przez do czasu usunięcia Konta
            Klienta lub Konta użytkownika. Pozostałe dane zgodnie z wskazanymi wyżej zasadami.
          </li>
        </ol>
      </li>
      <li>
        <strong>6.&nbsp;Twoje prawa</strong>
        <ol>
          <li>
            RODO, w zależności od sytuacji, przyznaje Ci szereg praw związanych z przetwarzaniem Twoich Danych
            Osobowych, w szczególności:
          </li>
          <li><strong>6.1.&nbsp;</strong>prawo dostępu do Twoich Danych osobowych, w tym do uzyskania ich kopii;</li>
          <li><strong>6.2.&nbsp;</strong>prawo żądania sprostowania Twoich Danych osobowych;</li>
          <li><strong>6.3.&nbsp;</strong>prawo do usunięcia Twoich Danych osobowych;</li>
          <li><strong>6.4.&nbsp;</strong>prawo ograniczenia przetwarzania Twoich Danych osobowych;</li>
          <li>
            <strong>6.5.&nbsp;</strong>
            prawo do wycofania zgody – w zakresie w jakim Twoje Dane osobowe są przetwarzane na podstawie udzielonej
            przez Ciebie zgody. Cofnięcia zgody możesz dokonać korzystając z linku zamieszczonego w przekazywanej do
            Ciebie korespondencji e-mail, a także poprzez kontakt e- mailowych pod adres:
            <a href="mailto:kontakt@fizjo.pro" target="_blank">kontakt&commat;fizjo.pro</a>. Wycofanie zgody nie ma
            wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem;
          </li>
          <li><strong>6.6.&nbsp;</strong>prawo do przenoszenia Twoich Danych osobowych;</li>
          <li><strong>6.7.&nbsp;</strong>prawo zgłoszenia sprzeciwu wobec przetwarzania Twoich Danych osobowych;</li>
        </ol>
      </li>
    </ol>
    <p>
      Jeżeli uważasz, że przetwarzanie przez nas Twoich DO narusza przepisy prawa, możesz wnieść skargę do Prezesa
      Urzędu Ochrony Danych Osobowych. Więcej informacji na ten temat uzyskasz pod tym linkiem:
      <a href="https://uodo.gov.pl/pl" target="_blank">https://uodo.gov.pl/pl</a>
    </p>
  </p-card>
</div>
