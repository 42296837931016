import { clearState } from '@fizjo-pro/data-auth';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as PatientActions from './patient.actions';
import { PatientState } from './patient.models';
import { PatientDto } from '../api/models';

export const patientAdapter: EntityAdapter<PatientDto> = createEntityAdapter<PatientDto>({
  selectId: (patient: PatientDto) => patient._id,
});

export const initialPatientState: PatientState = {
  ...patientAdapter.getInitialState(),
  ready: false,
};

const reducer = createReducer(
  initialPatientState,
  on(PatientActions.loadPatientsSuccess, (state: PatientState, action) => ({
    ...patientAdapter.upsertMany(action.patients, state),
    ready: true,
  })),
  on(PatientActions.savePatientSuccess, (state: PatientState, action) => ({
    ...patientAdapter.upsertOne(action.patient, state),
  })),
  on(PatientActions.savePatientSuccessAndCloseDialog, (state: PatientState, action) => ({
    ...patientAdapter.upsertOne(action.patient, state),
  })),
  on(PatientActions.patchPatientOwnerSuccess, (state: PatientState, action) => ({
    ...patientAdapter.upsertOne(action.patient, state),
  })),
  on(clearState, () => initialPatientState)
);

export function patientReducer(state: PatientState | undefined, action: Action): PatientState {
  return reducer(state, action);
}
