import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserDto } from '@fizjo-pro/data-auth';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { Observable } from 'rxjs';

import { SettingsItemsService } from './settings-items.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MenuModule, TieredMenuModule],
  providers: [SettingsItemsService],
  selector: 'fizjo-pro-settings',
  standalone: true,
  styleUrls: ['./settings.component.scss'],
  templateUrl: './settings.component.html',
})
export class SettingsComponent {
  public readonly items$: Observable<MenuItem[] | undefined>;
  public me$!: Observable<UserDto | null>;

  constructor(itemsService: SettingsItemsService) {
    this.items$ = itemsService.items$;
  }
}
