import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MessageChannel, OwnerDto, OwnerPayloadDto } from './api/models';
import * as actions from './state/owner.actions';
import { ownerActions, updateOwner } from './state/owner.actions';
import { selectAllOwners, selectOwner, selectReady } from './state/owner.selectors';

@Injectable({
  providedIn: 'root',
})
export class OwnerFacade {
  public readonly owners$: Observable<OwnerDto[]>;

  public lastNames$!: Observable<string[]>;

  public readonly ready$: Observable<boolean>;

  constructor(private store: Store) {
    this.owners$ = this.store.select(selectAllOwners);
    this.ready$ = this.store.select(selectReady);
    this.lastNames$ = this.owners$.pipe(
      map((owners: OwnerDto[]) => owners.map<string>((owner: OwnerDto) => `${owner.firstname}&nbsp;${owner.lastname}`))
    );
  }

  public createOwner(payload: OwnerPayloadDto): void {
    this.store.dispatch(actions.createOwner({ payload }));
  }

  public createOwnerWithAnimal(payload: OwnerPayloadDto): void {
    this.store.dispatch(actions.createOwnerWithAnimal({ payload }));
  }

  public getOwner$(ownerId: string | null): Observable<OwnerDto | undefined> {
    return this.store.select(selectOwner(ownerId));
  }

  public updateOwner(ownerId: string, payload: OwnerPayloadDto): void {
    this.store.dispatch(updateOwner({ ownerId, payload }));
  }

  public createInvitation(ownerId: string, channel: MessageChannel): void {
    this.store.dispatch(ownerActions.createInvitation({ channel, ownerId }));
  }

  public sendGdprRequest(ownerId: string): void {
    this.store.dispatch(ownerActions.sendGdprRequest({ ownerId }));
  }

  public sendMessage(ownerId: string, content: string, channel: MessageChannel): void {
    this.store.dispatch(ownerActions.sendMessage({ channel, content, ownerId }));
  }
}
