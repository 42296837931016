import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserDto } from '@fizjo-pro/data-auth';
import { AuthService, MeService } from '@fizjo-pro/util-auth';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { Observable } from 'rxjs';

@Component({
  imports: [CommonModule, AvatarModule, ButtonModule, RouterModule],
  selector: 'fizjo-pro-user-view',
  standalone: true,
  styleUrls: ['./user-view.component.scss'],
  templateUrl: './user-view.component.html',
})
export class UserViewComponent {
  @Output() public closeOverlay: EventEmitter<void> = new EventEmitter<void>();
  public readonly me$: Observable<UserDto | null>;

  constructor(
    private meService: MeService,
    private authService: AuthService
  ) {
    this.me$ = meService.me$;
  }

  public logout(uid: string): void {
    this.authService.logout(uid).then(() => {
      this.closeOverlay.emit();
    });
  }
}
