/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OWNER_FEATURE_KEY, OwnerState } from './owner.models';
import { ownerAdapter } from './owner.reducer';
import { OwnerDto } from '../api/models/owner-dto';

export const getOwnerState = createFeatureSelector<OwnerState>(OWNER_FEATURE_KEY);

const { selectAll } = ownerAdapter.getSelectors();

export const selectAllOwners = createSelector(getOwnerState, (state: OwnerState) => selectAll(state));

export const selectOwner = (ownerId: string | null) =>
  createSelector(getOwnerState, (state: OwnerState) => (ownerId !== null ? state.entities[ownerId] : undefined));

export const selectOwnerDataReady = (ownerId: string | null) =>
  createSelector(
    selectOwner(ownerId),
    (owner: OwnerDto | undefined) => owner !== undefined && owner?.address !== undefined
  );

export const selectReady = createSelector(getOwnerState, (state: OwnerState) => state.ready);
