import { createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as EquipmentAction from './equipment.actions';
import { equipmentActions } from './equipment.actions';
import { EquipmentFilter, EquipmentState } from './equipment.model';
import { EquipmentDto } from '../api/models';

export const equipmentAdapter = createEntityAdapter<EquipmentDto>({
  selectId: (equipment: EquipmentDto) => equipment._id,
});

export const equipmentInitialState: EquipmentState = {
  ...equipmentAdapter.getInitialState(),
  filter: EquipmentFilter.ALL,
  ready: false,
};

const reducer = createReducer(
  equipmentInitialState,
  on(EquipmentAction.createEquipmentSuccess, (state: EquipmentState, action) => ({
    ...equipmentAdapter.upsertOne(action.equipment, state),
    ready: true,
  })),
  on(EquipmentAction.fetchEquipmentListSuccess, (state: EquipmentState, action) => ({
    ...equipmentAdapter.addMany(action.equipments, state),
    ready: true,
  })),
  on(EquipmentAction.updateEquipmentSuccess, (state: EquipmentState, action) => {
    const update: Update<EquipmentDto> = {
      changes: action.equipment,
      id: action.equipment._id,
    };

    return equipmentAdapter.updateOne<EquipmentState>(update, state);
  }),

  on(EquipmentAction.setFilter, (state: EquipmentState, action) => ({
    ...state,
    filter: action.filter,
  })),

  on(equipmentActions.success, (state: EquipmentState, action) => {
    const update: Update<EquipmentDto> = {
      changes: action.equipment,
      id: action.equipment._id,
    };

    return equipmentAdapter.updateOne<EquipmentState>(update, state);
  })
);

export function equipmentReducer(state: EquipmentState | undefined, action: Action): EquipmentState {
  return reducer(state, action);
}

export const { selectAll } = equipmentAdapter.getSelectors();
