import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'normalizePolish', standalone: true })
export class NormalizePolishPipe implements PipeTransform {
  private polishCharsMap: { [key: string]: string } = {
    ą: 'a',
    ć: 'c',
    ę: 'e',
    ł: 'l',
    ń: 'n',
    ó: 'o',
    ś: 's',
    ź: 'z',
    ż: 'z',
    Ą: 'A',
    Ć: 'C',
    Ę: 'E',
    Ł: 'L',
    Ń: 'N',
    Ó: 'O',
    Ś: 'S',
    Ź: 'Z',
    Ż: 'Z',
  };

  transform(value: string): string {
    return value.replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, char => this.polishCharsMap[char] || char);
  }
}
