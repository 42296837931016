import { HttpContext, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountActions } from '@fizjo-pro/data-account';
import {
  ApiAuthService,
  authActions,
  AuthFacade,
  ScreenLockService,
  SigninDto,
  TokenDto,
  UserDto,
} from '@fizjo-pro/data-auth';
import { BlockUiFacade } from '@fizjo-pro/shared/ui';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { HandleErrorPassThrough } from '@fizjo-pro/shared/util-intrceptors';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, from, NEVER, Observable, switchMap, take, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { MeService } from './me.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #blockUiFacade: BlockUiFacade = inject(BlockUiFacade);
  #screenLockService: ScreenLockService = inject(ScreenLockService);
  #translateService: TranslateService = inject(TranslateService);
  #meService: MeService = inject(MeService);
  #store: Store = inject(Store);

  public authenticated$: Observable<boolean>;

  constructor(
    private router: Router,
    private authService: ApiAuthService,
    private messageService: MessageService,
    private authFacade: AuthFacade,
    private appDomainService: AppDomainService
  ) {
    this.authenticated$ = this.authFacade.authenticated$;
  }

  public logout(uid: string): Promise<boolean> {
    this.authFacade.setUnauthenticated('auth service', uid);
    this.#screenLockService.unsubscribeCheckToken();

    return this.router.navigate(['/auth']);
  }

  public authenticateAsync$(body: SigninDto): Observable<void> {
    return this.authService
      .authControllerAuthenticate({
        body,
        [X_TENANT_ID]: this.appDomainService.tenantIdSignal() || '',
      })
      .pipe(
        tap((tokenDto: TokenDto) => this.handleAuthenticated(tokenDto)),
        map(() => void 0),
        catchError((error: HttpErrorResponse) => {
          this.#blockUiFacade.block(false);
          switch (error.status) {
            case 401:
              this.unauthorizedErrorHandler();
              break;
            case 403:
              this.unauthorizedErrorHandler();
              break;
            case 405:
              this.notActiveHandler();
              break;
            default:
              this.unknownErrorHandler();
          }

          return NEVER;
        })
      );
  }

  public authenticate(body: SigninDto): void {
    this.authService
      .authControllerAuthenticate({
        body,
        [X_TENANT_ID]: this.appDomainService.tenantIdSignal() || '',
        context: new HttpContext().set(HandleErrorPassThrough, [401, 403, 405]),
      })
      .pipe(
        tap((tokenDto: TokenDto) => this.handleAuthenticated(tokenDto)),
        switchMap(() => from(this.router.navigate(['/']))),
        catchError((error: HttpErrorResponse) => {
          this.#blockUiFacade.block(false);
          switch (error.status) {
            case 401:
              this.unauthorizedErrorHandler();
              break;
            case 403:
              this.unauthorizedErrorHandler();
              break;
            case 405:
              this.notActiveHandler();
              break;
            default:
              this.unknownErrorHandler();
          }

          return NEVER;
        })
      )
      .subscribe(() => {
        this.#blockUiFacade.block(false);

        return void 0;
      });
  }

  public refreshCode$(pinCode: string): Observable<boolean> {
    return this.#meService.me$.pipe(
      map((me: UserDto | null) => {
        let refreshToken: string | null = null;

        if (me) {
          refreshToken = localStorage.getItem(me._id);
        } else {
          throw new Error('User not found');
        }
        if (refreshToken === null) {
          throw new Error('Refresh token not found');
        }

        return refreshToken;
      }),
      switchMap((refreshToken: string) =>
        this.authService.authControllerRefreshAuthToken({
          [X_TENANT_ID]: this.appDomainService.tenantId,
          body: { pinCode, refreshToken },
        })
      ),
      tap((tokenDto: TokenDto) => this.handleAuthenticated(tokenDto)),
      map(() => true),
      take(1)
    );
  }

  private handleAuthenticated(tokenDto: TokenDto): void {
    this.#store.dispatch(authActions.setAuthenticated({ tokenDto }));
    this.#store.dispatch(AccountActions.loadWhenAuthenticated());
    this.#screenLockService.checkToken();
    this.#screenLockService.unlock();
  }

  private notActiveHandler(): void {
    this.#translateService
      .get(['auth.accountInactiveMessage', 'auth.authErrorHeader'])
      .pipe(take(1))
      .subscribe((trans: Record<string, string>) => {
        this.messageService.add({
          detail: trans['auth.accountInactiveMessage'],
          severity: 'info',
          summary: trans['auth.authErrorHeader'],
        });
      });
  }

  private unknownErrorHandler(): void {
    // unknown error
  }

  private unauthorizedErrorHandler(): void {
    this.#translateService
      .get(['auth.unauthorizedErrorMessage', 'auth.authErrorHeader'])
      .pipe(take(1))
      .subscribe((trans: Record<string, string>) => {
        this.messageService.add({
          detail: trans['auth.unauthorizedErrorMessage'],
          severity: 'error',
          summary: trans['auth.authErrorHeader'],
        });
        this.authFacade.setUnauthenticated('auth error handler');
      });
  }
}
