import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationDateTransform',
  standalone: true,
})
export class NotificationDateTransformPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: string, date: Date): string {
    if (!value) return value;
    const dateString = formatDate(date, 'dd/MM/yyyy HH:mm', this.locale);

    return value.replace(/{{DATA}}/g, dateString ?? '');
  }
}
