import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { StatsItemDto } from '../api/models/stats-item-dto';
import { StatsService } from '../api/services/stats.service';
import { statsActions } from '../state/actions';

export const statsResolver: ResolveFn<StatsItemDto[]> = (
  route,
  state,
  store: Store = inject(Store),
  service: StatsService = inject(StatsService)
) => service.statsControllerReadStats().pipe(tap(stats => store.dispatch(statsActions.readSuccess({ stats }))));
