import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { blockUiAction, selectBlockUi } from './block-ui.feature';

@Injectable()
export class BlockUiFacade {
  public readonly blockUi$: Observable<boolean>;

  constructor(private store: Store) {
    this.blockUi$ = this.store.select(selectBlockUi);
  }

  public block(blockUi: boolean): void {
    if (blockUi) {
      this.store.dispatch(blockUiAction.blockUi());
    } else {
      this.store.dispatch(blockUiAction.removeBlock());
    }
  }
}
