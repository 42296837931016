import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ResourceType } from '@fizjo-pro/admin/data-accounts';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ChartModule, DialogModule, ButtonModule, RouterLink, TranslateModule],
  selector: 'fizjo-pro-out-of-resources-dialog',
  standalone: true,
  styleUrls: ['./out-of-resources-dialog.component.scss'],
  templateUrl: './out-of-resources-dialog.component.html',
})
export class OutOfResourcesDialogComponent implements OnInit {
  #translateService: TranslateService = inject(TranslateService);
  public config: DynamicDialogConfig = inject(DynamicDialogConfig);
  public ref: DynamicDialogRef = inject(DynamicDialogRef);

  protected resource?: ResourceType;
  protected message = '';

  ngOnInit() {
    this.resource = this.config.data.resource;
    this.displayResourceMessage();
  }
  private displayResourceMessage(): void {
    switch (this.resource) {
      case ResourceType.Sms:
        this.message = this.#translateService.instant('outOfResourcesDialog.messageSms');
        break;
      case ResourceType.Data:
        this.message = this.#translateService.instant('outOfResourcesDialog.messageData');
        break;
      case ResourceType.Users:
        this.message = this.#translateService.instant('outOfResourcesDialog.messageUsers');
        break;
      case ResourceType.MonthlyAppointments:
        this.message = this.#translateService.instant('outOfResourcesDialog.messageMonthlyAppointments');
        break;
      case ResourceType.Patients:
        this.message = this.#translateService.instant('outOfResourcesDialog.messagePatients');
        break;
      case ResourceType.Owners:
        this.message = this.#translateService.instant('outOfResourcesDialog.messageOwners');
        break;
      default:
        this.message = this.#translateService.instant('outOfResourcesDialog.messageDefault');
    }
  }

  protected close(): void {
    this.ref.close();
  }
}
