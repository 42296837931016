import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { gdprActions } from './state/gdpr.actions';
import { selectReady } from './state/gdpr.selectors';

@Injectable()
export class GdprResolver extends ResolverHelper {
  constructor(store: Store) {
    super(store);
  }

  override resolve(): Observable<any> {
    return this.store.select(selectReady).pipe(this.helpResolve(gdprActions.read()));
  }
}
