import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { StatsItemDto } from '../api/models/stats-item-dto';

export const statsActions = createActionGroup({
  events: {
    readAll: emptyProps(),
    readSuccess: props<{ stats: StatsItemDto[] }>(),
  },
  source: 'stats',
});
