import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { createFeature, provideState } from '@ngrx/store';

import { AllPatientsResolver } from './all-patients.resolver';
import { ApiModule } from './api/api.module';
import { PatientFacade } from './patient.facade';
import { PatientEffects } from './state/patient.effects';
import { PATIENT_FEATURE_KEY } from './state/patient.models';
import { patientReducer } from './state/patient.reducer';

export const patientProviders: (Provider | EnvironmentProviders)[] = [
  provideState(
    createFeature({
      name: PATIENT_FEATURE_KEY,
      reducer: patientReducer,
    })
  ),
  provideEffects(PatientEffects),
  PatientFacade,
  AllPatientsResolver,
  importProvidersFrom(ApiModule),
];
