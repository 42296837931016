/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangePasswordPayloadDto } from '../models/change-password-payload-dto';
import { ChangePasswordWithCodeDto } from '../models/change-password-with-code-dto';
import { TokenDto } from '../models/token-dto';

@Injectable({
  providedIn: 'root',
})
export class PasswordService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation passwordControllerRequestMfaCode
   */
  static readonly PasswordControllerRequestMfaCodePath = '/api/password/mfa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordControllerRequestMfaCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  passwordControllerRequestMfaCode$Response(params: {
    /**
     * User email address
     */
    email: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, PasswordService.PasswordControllerRequestMfaCodePath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `passwordControllerRequestMfaCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passwordControllerRequestMfaCode(params: {
    /**
     * User email address
     */
    email: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<{}> {
    return this.passwordControllerRequestMfaCode$Response(params).pipe(
      map((r: StrictHttpResponse<{}>) => r.body as {})
    );
  }

  /**
   * Path part for operation passwordControllerChangePasswordWithEmail
   */
  static readonly PasswordControllerChangePasswordWithEmailPath = '/api/password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordControllerChangePasswordWithEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordControllerChangePasswordWithEmail$Response(params: {
    context?: HttpContext;
    body: ChangePasswordPayloadDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PasswordService.PasswordControllerChangePasswordWithEmailPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `passwordControllerChangePasswordWithEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordControllerChangePasswordWithEmail(params: {
    context?: HttpContext;
    body: ChangePasswordPayloadDto;
  }): Observable<void> {
    return this.passwordControllerChangePasswordWithEmail$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation passwordControllerForcePasswordChange
   */
  static readonly PasswordControllerForcePasswordChangePath = '/api/password/forced';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordControllerForcePasswordChange()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordControllerForcePasswordChange$Response(params: {
    context?: HttpContext;
    body: ChangePasswordWithCodeDto;
  }): Observable<StrictHttpResponse<TokenDto>> {
    const rb = new RequestBuilder(this.rootUrl, PasswordService.PasswordControllerForcePasswordChangePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TokenDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `passwordControllerForcePasswordChange$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordControllerForcePasswordChange(params: {
    context?: HttpContext;
    body: ChangePasswordWithCodeDto;
  }): Observable<TokenDto> {
    return this.passwordControllerForcePasswordChange$Response(params).pipe(
      map((r: StrictHttpResponse<TokenDto>) => r.body as TokenDto)
    );
  }
}
