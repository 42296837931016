import { createFeature, createReducer, on } from '@ngrx/store';

import { AccountActions } from './account.actions';
import { AccountDto } from '../api/models/account-dto';

export interface AccountState {
  account: AccountDto | null;
  ready: boolean;
}
export const initialState: AccountState = {
  account: null,
  ready: false,
};
export const accountFeature = createFeature({
  name: 'account',
  reducer: createReducer(
    initialState,
    on(AccountActions.loadSuccess, (state, action) => ({
      ...state,
      account: action.account,
      ready: true,
    }))
  ),
});
