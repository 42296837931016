/* eslint-disable  @typescript-eslint/explicit-function-return-type */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EQUIPMENT_FEATURE_KEY, EquipmentFilter, EquipmentState } from './equipment.model';
import { selectAll } from './equipment.reducer';
import { EquipmentDto } from '../api/models';

export const selectEquipmentState = createFeatureSelector<EquipmentState>(EQUIPMENT_FEATURE_KEY);

export const selectReady = createSelector(selectEquipmentState, (state: EquipmentState) => state.ready);

export const selectEquipments = createSelector(selectEquipmentState, (state: EquipmentState) =>
  selectAll(state).filter((eq: EquipmentDto) => {
    switch (state.filter) {
      case EquipmentFilter.ACTIVE:
        return eq.active;
      case EquipmentFilter.INACTIVE:
        return !eq.active;
      default:
        return true;
    }
  })
);

export const selectActiveOnly = createSelector(selectEquipmentState, (state: EquipmentState) =>
  selectAll(state).filter((eq: EquipmentDto) => eq.active)
);

export const selectEquipmentById = (equipmentId: string) =>
  createSelector(selectEquipmentState, (state: EquipmentState) => state.entities[equipmentId]);

export const selectFilterValue = createSelector(selectEquipmentState, (state: EquipmentState) => state.filter);
