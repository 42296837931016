import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ADMIN_ACCOUNTS_STORE_KEY } from './model';
import {
  AccountRequestPayloadDto,
  AccountResourcePayloadDto,
  ProductCode,
  UpdateLicenseValidUntilDto,
} from '../api/models';
import { AccountDto } from '../api/models/account-dto';

export const adminAccountsActions = createActionGroup({
  events: {
    read: emptyProps(),
    readSuccess: props<{ accounts: AccountDto[] }>(),
    selectAccount: props<{ accountId: string }>(),
    selectMulti: props<{ accounts: string[] }>(),
    createAccount: props<{ requestId: string; payload: AccountRequestPayloadDto }>(),
    createAccountSuccess: props<{ account: AccountDto }>(),
  },
  source: ADMIN_ACCOUNTS_STORE_KEY,
});

export const resourceActions = createActionGroup({
  events: {
    update: props<{ tenantId: string; payload: AccountResourcePayloadDto }>(),
    updateSuccess: props<{ account: AccountDto }>(),
  },
  source: 'account-resource',
});

export const licenseActions = createActionGroup({
  events: {
    update: props<{ tenantId: string; licenseId: string; payload: UpdateLicenseValidUntilDto }>(),
    updateSuccess: props<{ account: AccountDto }>(),
    replace: props<{ tenantId: string; licenseId: string; productCode: ProductCode }>(),
    replaceSuccess: props<{ account: AccountDto }>(),
  },
  source: 'account-license',
});
