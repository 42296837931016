import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class AppMessagesService {
  constructor(private messageService: MessageService) {}

  public addMessage(message: Message): void {
    this.messageService.add(message);
  }
}
