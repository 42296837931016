import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { AuthFacade } from './auth.facade';
import { MeResolver } from './resolvers/me.resolver';
import { UserDataResolver } from './resolvers/user-data.resolver';
import { AuthEffects } from './state/auth.effects';
import { AUTH_FEATURE_KEY } from './state/auth.model';
import { authReducer } from './state/auth.reducer';
import { UserFacade } from './user.facade';

export const authProviders: (Provider | EnvironmentProviders)[] = [
  provideState({
    name: AUTH_FEATURE_KEY,
    reducer: authReducer,
  }),
  AuthFacade,
  UserFacade,
  UserDataResolver,
  MeResolver,
  provideEffects([AuthEffects]),
  importProvidersFrom([ApiModule]),
];
