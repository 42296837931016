import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AccountDto } from '../api/models/account-dto';

export const AccountActions = createActionGroup({
  source: 'account',
  events: {
    'load from guard': emptyProps(),
    'load when authenticated': emptyProps(),
    'load success': props<{ account: AccountDto }>(),
  },
});
