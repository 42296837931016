import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SpeedDialModule } from 'primeng/speeddial';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SpeedDialModule, NgIf],
  selector: 'fizjo-pro-list-item',
  standalone: true,
  styleUrls: ['list-item.component.scss'],
  templateUrl: 'list-item.component.html',
})
export class ListItemComponent {
  @Input() public speedDialItems!: MenuItem[];

  @Output() public selectItem: EventEmitter<void> = new EventEmitter<void>();
}
