import { Routes } from '@angular/router';
import { FormTemplateResolver } from '@fizjo-pro/settings/data-form-template';

export const formTemplateRoutes: Routes = [
  {
    loadComponent: () =>
      import('./form-template-generator/form-template-generator.component').then(m => m.FormTemplateGeneratorComponent),
    path: 'create',
    resolve: {
      templates: FormTemplateResolver,
    },
  },
  {
    loadComponent: () =>
      import('./form-template-generator/form-template-generator.component').then(m => m.FormTemplateGeneratorComponent),
    path: ':templateId/edit',
    resolve: {
      templates: FormTemplateResolver,
    },
  },
  {
    loadComponent: () =>
      import('./form-templates-list/form-templates-list.component').then(m => m.FormTemplatesListComponent),
    path: '',
    resolve: {
      templates: FormTemplateResolver,
    },
  },
];
