import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';

import { PatientDto, PatientPayloadDto } from './api/models';
import * as PatientActions from './state/patient.actions';
import {
  selectAllPatients,
  selectBindPatients,
  selectByOwner,
  selectPatient,
  selectUnBindPatients,
} from './state/patient.selectors';

@Injectable({ providedIn: 'root' })
export class PatientFacade {
  private readonly store = inject(Store);
  public patient$: Observable<PatientDto[]> = this.store.select(selectAllPatients);
  public unBindPatients$: Observable<PatientDto[]> = this.store.select(selectUnBindPatients);
  public bindPatients$: Observable<PatientDto[]> = this.store.select(selectBindPatients);

  public init(): void {
    this.store.dispatch(PatientActions.loadPatients());
  }

  public savePatient(payload: PatientPayloadDto, ref?: DynamicDialogRef): void {
    if (ref) {
      this.store.dispatch(PatientActions.savePatientFromDialog({ payload, ref }));
    }
    this.store.dispatch(PatientActions.savePatient({ payload }));
  }

  public selectPatient$(patientId: string | null): Observable<PatientDto | undefined> {
    return patientId ? this.store.select(selectPatient(patientId)) : of(undefined);
  }

  public selectByOwner$(ownerId: string | null): Observable<PatientDto[]> {
    return ownerId !== null ? this.store.select(selectByOwner(ownerId)) : of([]);
  }

  public updatePatient(patientId: string, payload: PatientPayloadDto): void {
    this.store.dispatch(PatientActions.updatePatient({ patientId, payload }));
  }

  public attachToOwner(patientId: string, owner: string): void {
    this.store.dispatch(PatientActions.patchPatientOwner({ owner, patientId }));
  }

  public deletePatientOwner(patientId: string): void {
    this.store.dispatch(PatientActions.deletePatientOwner({ patientId }));
  }
}
