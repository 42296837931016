import { inject, Injectable } from '@angular/core';
import { AppDomainService } from '@fizjo-pro/shared/util-app-domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { appConfigActions as actions, contactPointActions } from './app-config.actions';
import { AddressDto, AppConfigDto, CompanyNamePayloadDto, ContactPointDto } from '../api/models';
import { ConfigService } from '../api/services/config.service';

@Injectable()
export class AppConfigEffects {
  #translateService: TranslateService = inject(TranslateService);
  #messageService: MessageService = inject(MessageService);
  #action$: Actions = inject(Actions);
  #apiService: ConfigService = inject(ConfigService);
  #appDomainService: AppDomainService = inject(AppDomainService);

  public fetchAppConfig$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(actions.fetchAppConfig),
      switchMap(() => this.#appDomainService.tenantId$()),
      switchMap((tenantId: string) => this.#apiService.appConfigUnsecureControllerReadConfig({ tenantId })),
      map((appConfig: AppConfigDto) => actions.fetchAppConfigSuccess({ appConfig }))
    );
  });
  public saveCompanyName$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(actions.saveCompanyName),
      switchMap(action =>
        this.#apiService.appConfigControllerPatchCompanyName({
          body: { companyName: action.companyName },
        })
      ),
      map((payloadDto: CompanyNamePayloadDto) =>
        actions.saveCompanyNameSuccess({ companyName: payloadDto.companyName })
      )
    );
  });
  public saveAddress$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(actions.saveAddress),
      switchMap(action => this.#apiService.addressControllerCreate({ body: action.address })),
      map((addresses: AddressDto[]) => actions.saveAddressSuccess({ addresses }))
    );
  });
  public createContactPoint$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(contactPointActions.createContactPoint),
      withLatestFrom(this.#appDomainService.tenantId$()),
      switchMap(([action, id]) => {
        return this.#apiService.contactPointControllerCreate({
          //TODO: check if x-tenant-id is needed
          body: action.contactPoint,
          'x-tenant-id': id,
        });
      }),
      map((contactPoint: ContactPointDto) => contactPointActions.createContactPointSuccess({ contactPoint }))
    );
  });

  public updateContactPoint$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(contactPointActions.updateContactPoint),
      switchMap(action => {
        return this.#apiService.contactPointControllerUpdate({
          contactPointId: action.id,
          body: action.contactPoint,
        });
      }),
      map((contactPoints: ContactPointDto[]) => contactPointActions.updateContactPointSuccess({ contactPoints }))
    );
  });
  public saveContactPointSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(contactPointActions.createContactPointSuccess, contactPointActions.updateContactPointSuccess),
        switchMap(() =>
          this.#translateService.get(['settings.saveContactPoint.summary', 'settings.saveContactPoint.detail'])
        ),
        map(translations =>
          this.#messageService.add({
            summary: translations['settings.saveContactPoint.summary'],
            detail: translations['settings.saveContactPoint.detail'],
            severity: 'success',
          })
        )
      );
    },
    { dispatch: false }
  );
  public deleteContactPoint$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(contactPointActions.deleteContactPoint),
      switchMap(action => {
        return this.#apiService.contactPointControllerDelete({
          contactPointId: action.contactPointId,
        });
      }),
      map((contactPoints: ContactPointDto[]) => contactPointActions.deleteContactPointSuccess({ contactPoints }))
    );
  });

  public deleteContactPointSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(contactPointActions.deleteContactPointSuccess),
        switchMap(() =>
          this.#translateService.get(['settings.deleteContactPoint.summary', 'settings.deleteContactPoint.detail'])
        ),
        map(translations =>
          this.#messageService.add({
            summary: translations['settings.deleteContactPoint.summary'],
            detail: translations['settings.deleteContactPoint.detail'],
            severity: 'success',
          })
        )
      );
    },
    { dispatch: false }
  );

  public fetchPermissionMatrix$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(actions.fetchPermissionMatrix),
      switchMap(() => this.#apiService.appConfigControllerGetPermissions()),
      map((permissionMatrix: Record<string, string[]>) => actions.fetchPermissionMatrixSuccess({ permissionMatrix }))
    );
  });
}
