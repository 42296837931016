import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { appointmentActions } from '../state/appointment.actions';
import { selectReady } from '../state/appointment.selectors';

@Injectable()
export class AppointmentResolver extends ResolverHelper {
  constructor(store: Store) {
    super(store);
  }

  override resolve(): Observable<any> {
    return this.store.select(selectReady).pipe(this.helpResolve(appointmentActions.fetch()));
  }
}
