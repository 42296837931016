import { inject, Injectable } from '@angular/core';
import { BlockUiFacade } from '@fizjo-pro/shared/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { billingDataActions } from './billing-data.actions';
import { StripeCustomerDto } from '../api/models/stripe-customer-dto';
import { BillingDataService } from '../api/services/billing-data.service';

@Injectable()
export class BillingDataEffects {
  #blockUi: BlockUiFacade = inject(BlockUiFacade);
  #translate: TranslateService = inject(TranslateService);
  #action$: Actions = inject(Actions);
  #billingDataService: BillingDataService = inject(BillingDataService);
  #messageService: MessageService = inject(MessageService);

  public readBillingData$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(billingDataActions.read),
      switchMap(() => this.#billingDataService.billingDataControllerRead()),
      map((stripeCustomer: StripeCustomerDto) => billingDataActions.readSuccess({ stripeCustomer }))
    );
  });
  public updateBillingData$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(billingDataActions.update),
      switchMap(action =>
        this.#billingDataService.billingDataControllerUpdate({
          body: action.payload,
        })
      ),
      map((stripeCustomer: StripeCustomerDto) => billingDataActions.updateSuccess({ stripeCustomer }))
    );
  });

  public updateSuccessBillingData$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(billingDataActions.updateSuccess),
        switchMap(() =>
          this.#translate.get(['billingData.saveBillingData.summary', 'billingData.saveBillingData.details'])
        ),
        tap((translate: Record<string, string>) => {
          this.#blockUi.block(false);
          this.#messageService.add({
            severity: 'success',
            summary: translate['billingData.saveBillingData.summary'],
            detail: translate['billingData.saveBillingData.details'],
          });
        })
      );
    },
    { dispatch: false }
  );
}
