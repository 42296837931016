<form [formGroup]="formGroup">
  <div class="flex flex-column gap-5">
    <p-dropdown
      [formControl]="formGroup.controls.type"
      [options]="types"
      optionValue="value"
      optionLabel="label"></p-dropdown>
    <div class="p-float-label">
      <input pInputText [formControl]="formGroup.controls.street" />
      <label>Adres</label>
    </div>
    <div class="p-float-label">
      <input pInputText [formControl]="formGroup.controls.streetSndLine" />
      <label>Adres - c. d.</label>
    </div>
    <div class="flex flex-row gap-2">
      <div class="p-float-label w-3">
        <input pInputText [formControl]="formGroup.controls.postalCode" />
        <label>Kod pocztowy</label>
      </div>
      <div class="p-float-label flex-grow-1">
        <input pInputText [formControl]="formGroup.controls.city" />
        <label>Miasto</label>
      </div>
    </div>
  </div>
</form>
