import { Injectable, signal, WritableSignal } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  public isProgressVisible: WritableSignal<boolean> = signal(false);

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isProgressVisible.set(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel
      ) {
        this.isProgressVisible.set(false);
      }
    });
  }

  public show(): void {
    this.isProgressVisible.set(true);
  }

  public hide(): void {
    this.isProgressVisible.set(false);
  }
}
