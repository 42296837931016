import { createFeatureSelector, createSelector } from '@ngrx/store';

import { GDPR_DATA_STORE_KEY, GdprSettingsState } from './gdpr.model';

export const gdprSettingsSelector = createFeatureSelector<GdprSettingsState>(GDPR_DATA_STORE_KEY);

export const selectGdprSettings = createSelector(
  gdprSettingsSelector,
  (state: GdprSettingsState) => state.gdprSettings
);

export const selectReady = createSelector(gdprSettingsSelector, (state: GdprSettingsState) => state.ready);
