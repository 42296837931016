import { createAction, props } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { PATIENT_FEATURE_KEY } from './patient.models';
import { PatientDto } from '../api/models/patient-dto';
import { PatientPayloadDto } from '../api/models/patient-payload-dto';

export const loadPatients = createAction(`[${PATIENT_FEATURE_KEY}] fetch patients data`);

export const loadPatientsSuccess = createAction(
  `[${PATIENT_FEATURE_KEY}] fetch patients data success`,
  props<{ patients: PatientDto[] }>()
);

export const savePatient = createAction(
  `[${PATIENT_FEATURE_KEY}] save patient`,
  props<{ payload: PatientPayloadDto }>()
);

export const savePatientFromDialog = createAction(
  `[${PATIENT_FEATURE_KEY}] save patient from dialog`,
  props<{ payload: PatientPayloadDto; ref?: DynamicDialogRef }>()
);

export const updatePatient = createAction(
  `[${PATIENT_FEATURE_KEY}] update patient data`,
  props<{ patientId: string; payload: PatientPayloadDto }>()
);

export const savePatientSuccessAndCloseDialog = createAction(
  `[${PATIENT_FEATURE_KEY}] save patient success and close dialog`,
  props<{ patient: PatientDto; ref?: DynamicDialogRef }>()
);
export const savePatientSuccess = createAction(
  `[${PATIENT_FEATURE_KEY}] save patient success`,
  props<{ patient: PatientDto }>()
);

export const patchPatientOwner = createAction(
  `[${PATIENT_FEATURE_KEY}] patch patient owner`,
  props<{ owner: string; patientId: string }>()
);

export const patchPatientOwnerSuccess = createAction(
  `[${PATIENT_FEATURE_KEY}] patch patient owner success`,
  props<{ patient: PatientDto }>()
);

export const deletePatientOwner = createAction(
  `[${PATIENT_FEATURE_KEY}] delete patient owner`,
  props<{ patientId: string }>()
);

export const deletePatientOwnerSuccess = createAction(
  `[${PATIENT_FEATURE_KEY}] delete patient owner success`,
  props<{ patient: PatientDto }>()
);

export const message = createAction(
  `[${PATIENT_FEATURE_KEY}] present message`,
  props<{ message: string; severity: string }>()
);
