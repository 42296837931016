/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NotificationDto } from '../models/notification-dto';

@Injectable()
export class NotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation notificationsControllerRead
   */
  static readonly NotificationsControllerReadPath = '/api/notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerRead$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<NotificationDto>>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationsControllerReadPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotificationDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationsControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerRead(params?: { context?: HttpContext }): Observable<Array<NotificationDto>> {
    return this.notificationsControllerRead$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotificationDto>>) => r.body as Array<NotificationDto>)
    );
  }

  /**
   * Path part for operation notificationsControllerReadForCalendarEvent
   */
  static readonly NotificationsControllerReadForCalendarEventPath = '/api/notifications/event/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsControllerReadForCalendarEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerReadForCalendarEvent$Response(params: {
    /**
     * The calendar event id
     */
    eventId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<NotificationDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.NotificationsControllerReadForCalendarEventPath,
      'get'
    );
    if (params) {
      rb.path('eventId', params.eventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotificationDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationsControllerReadForCalendarEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsControllerReadForCalendarEvent(params: {
    /**
     * The calendar event id
     */
    eventId: string;
    context?: HttpContext;
  }): Observable<Array<NotificationDto>> {
    return this.notificationsControllerReadForCalendarEvent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotificationDto>>) => r.body as Array<NotificationDto>)
    );
  }
}
