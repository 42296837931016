import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, inject, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { MarketplaceStore, Order } from '@fizjo-pro/data-marketplace';
import { StripePriceDto } from '@fizjo-pro/data-products';
import {
  selectHasBillingAddress,
  selectStripeCustomer,
  StripeCustomerDto,
  StripeTaxIdDto,
} from '@fizjo-pro/settings/data-billing-data';
import { ApiConfigurationToken } from '@kate-fizjo/practice-shared/environment';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import { StripePricesSet } from './stripe-price-set';

@Component({
  selector: 'fizjo-pro-checkout-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    ScrollPanelModule,
    SharedModule,
    TabViewModule,
    TableModule,
    TranslateModule,
    TooltipModule,
    RouterLink,
    DialogModule,
    ReactiveFormsModule,
    SplitButtonModule,
    MessageModule,
  ],
  templateUrl: './checkout-dialog.component.html',
})
export class CheckoutDialogComponent implements OnInit {
  #orderDynamicDialogConfig: DynamicDialogConfig<Order> = inject<DynamicDialogConfig<Order>>(DynamicDialogConfig);
  #dynamicDialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  #destroyRef: DestroyRef = inject(DestroyRef);
  #store: Store = inject(Store);
  #router: Router = inject(Router);
  #appLayoutStore = inject(MarketplaceStore);
  rootUrl = inject(ApiConfigurationToken);

  protected hasOrder: Signal<boolean> = this.#appLayoutStore.hasOrder;
  protected hasBillingAddress: Signal<boolean> = this.#store.selectSignal(selectHasBillingAddress);
  protected disableCheckoutButton: WritableSignal<boolean> = signal(true);
  protected order: Order | undefined = this.#orderDynamicDialogConfig.data;
  protected isYearly =
    !!this.#orderDynamicDialogConfig.data?.price.recurring &&
    this.#orderDynamicDialogConfig.data?.price.recurring?.interval === 'year';
  protected acceptRulesCtrl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  protected readonly billingData: Signal<StripeCustomerDto | null> = this.#store.selectSignal(selectStripeCustomer);
  protected vat: Signal<string | undefined> = computed(() => {
    const tax: StripeTaxIdDto[] | undefined = this.billingData()?.tax_ids;

    return tax && tax.length > 0 ? tax[0].value : undefined;
  });

  public ngOnInit(): void {
    this.acceptRulesCtrl.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(accepted => this.disableCheckoutButton.set(!accepted));
  }

  protected price(price: StripePriceDto): number {
    return StripePricesSet.toAmount(price);
  }

  protected close(): void {
    this.#dynamicDialogRef.close();
  }

  protected checkout(): void {
    this.#dynamicDialogRef.close(this.order);
  }

  protected clearAndClose(): void {
    this.#appLayoutStore.clearState();
    this.close();
  }

  editBillingData(): void {
    if (this.order) {
      this.#appLayoutStore.saveOrder(this.order);
    }
    this.#router.navigate(['/settings/app-settings'], { queryParams: { tab: 'billingData' } });
    this.#dynamicDialogRef.close();
  }
}
