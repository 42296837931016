import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { appConfigActions } from './app-config.actions';
import { ConfigService } from '../api/services/config.service';

@Injectable()
export class NotificationsSettingsEffects {
  #api: ConfigService = inject(ConfigService);
  #messageService: MessageService = inject(MessageService);
  #translateService: TranslateService = inject(TranslateService);

  #action$: Actions = inject(Actions);

  putNotificationsSettings$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appConfigActions.putNotificationsSettings),
      switchMap(action =>
        this.#api.appConfigControllerSetNotificationsSettings({ body: action.notificationsSettings })
      ),
      map(appConfig => appConfigActions.putNotificationsSettingsSuccess({ appConfig }))
    );
  });

  putNotificationsSettingsSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appConfigActions.putNotificationsSettingsSuccess),
        tap(() =>
          this.#messageService.add({
            severity: 'success',
            detail: this.#translateService.instant('notifications.settings.updateSuccess'),
          })
        )
      );
    },
    { dispatch: false }
  );

  turnOffNotifications$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appConfigActions.turnOffNotifications),
      switchMap(() => this.#api.appConfigControllerSetNotificationsSettings({ body: { timings: [], message: '' } })),
      map(appConfig => appConfigActions.putNotificationsSettingsSuccess({ appConfig }))
    );
  });
}
