import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ApiConfigurationToken } from '@kate-fizjo/practice-shared/environment';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie';
import { iif, Observable, of, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export const X_TENANT_ID = 'x-tenant-id';

@Injectable({
  providedIn: 'root',
})
export class AppDomainService {
  #rootUrl: string = inject(ApiConfigurationToken).rootUrl;
  #cookies: CookieService = inject(CookieService);
  #http: HttpClient = inject(HttpClient);
  #document: any = inject(DOCUMENT);
  public tenantIdSignal: Signal<string | undefined> = toSignal(this.tenantId$());
  public get appDomain(): string {
    const hostname: string = this.#document.location.hostname;

    return /\.fizjo\.pro$/.test(hostname) ? hostname.split('.')[0] : 'fizjopro-dev';
  }

  public get tenantId(): string {
    const tenantId: string | null = this.#cookies.get(X_TENANT_ID) || null;

    if (tenantId === null) {
      this.tenantId$().pipe(take(1)).subscribe();
    }

    return tenantId || '';
  }

  public tenantId$(): Observable<string> {
    const tenantId: string | null = this.#cookies.get(X_TENANT_ID) || null;

    return iif(() => tenantId === null, this.#readTenantId$(this.appDomain), of(tenantId as string));
  }

  #readTenantId$(appName: string): Observable<string> {
    return this.#http.get<{ tenantId: string }>(`${this.#rootUrl}/api/account/${appName}`).pipe(
      map((response: { tenantId: string }) => response.tenantId),
      tap((tenantId: string) => {
        this.#cookies.put(X_TENANT_ID, tenantId || '', { expires: DateTime.local().plus({ year: 1 }).toJSDate() });
      })
    );
  }
}
