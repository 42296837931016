import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { DateTime } from 'luxon';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';
import { from } from 'rxjs';

import { AppMessageDto } from './model';

@Injectable()
export class MessagesService {
  private collection!: AngularFirestoreCollection;
  private accountIdSubj$: BehaviorSubject<string> = new BehaviorSubject<string>('system');

  public messages$: Observable<AppMessageDto[]>;
  public messagesCount$!: Observable<number>;

  constructor(private afs: AngularFirestore) {
    this.messages$ = this.getMessage$();
    this.messagesCount$ = this.messages$.pipe(
      map((messages: AppMessageDto[]) =>
        messages.filter(message => message.recipient === this.accountIdSubj$.value && !message.read)
      ),
      map((messages: AppMessageDto[]) => messages.length)
    );
  }

  public setAccountId(accountId: string): void {
    this.accountIdSubj$.next(accountId);
  }

  public getMessage$(): Observable<AppMessageDto[]> {
    return this.accountIdSubj$.pipe(
      map((accountId: string) => `messages/${accountId}/user-messages`),
      tap((path: string) => {
        this.collection = this.afs.collection<AppMessageDto>(path, ref => ref.orderBy('createdAt'));
      }),
      switchMap(() => this.collection.snapshotChanges()),
      map(snapshotChanges =>
        snapshotChanges.map(snapshot => {
          const appMessageDto: AppMessageDto = snapshot.payload.doc.data() as AppMessageDto;
          const id: string = snapshot.payload.doc.id;

          return { ...appMessageDto, id };
        })
      )
    );
  }

  public getTenantMessage$(tenantId: string): Observable<AppMessageDto[]> {
    this.accountIdSubj$.next(tenantId);
    this.collection = this.afs.collection<AppMessageDto>(`messages/${tenantId}/user-messages`, ref =>
      ref.orderBy('createdAt')
    );

    return this.collection.snapshotChanges().pipe(
      map(snapshotChanges =>
        snapshotChanges.map(snapshot => {
          const appMessageDto: AppMessageDto = snapshot.payload.doc.data() as AppMessageDto;
          const id: string = snapshot.payload.doc.id;

          return { ...appMessageDto, id };
        })
      )
    );
  }

  public async sendMessage(content: string, recipient: string, sender: string, senderName: string): Promise<any> {
    return this.collection.add({
      content,
      createdAt: DateTime.local().toJSDate(),
      read: false,
      recipient,
      sender,
      senderName,
    });
  }

  public deleteMessage(messageId: string): Promise<void> {
    return this.collection.doc(messageId).delete();
  }

  public setAsRead(messageId: string): Promise<void> {
    return this.collection.doc(messageId).update({ read: true });
  }

  public sendMutipleMessage$(content: string, accounts: string[]): Observable<any[]> {
    const promises: Promise<any>[] = accounts.map((tenantId: string) => {
      return this.afs
        .collection<AppMessageDto>(`messages/${tenantId}/user-messages`, ref => ref.orderBy('createdAt'))
        .add({
          content,
          createdAt: DateTime.local().toJSDate(),
          read: false,
          recipient: tenantId,
          sender: 'system',
          senderName: 'Administrator aplikacji',
        });
    });

    return from(Promise.all(promises));
  }
}
