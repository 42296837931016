<div class="premium-dialog relative py-5 px-7 flex flex-column justify-content-between">
  <div>
    @for (idx of [0, 1, 2, 3]; track idx) {
      <p [innerHTML]="'products.premium.' + idx | translate" class="text-white text-lg"></p>
    }
  </div>
  <p-button
    [outlined]="true"
    (click)="navigate()"
    styleClass="w-full"
    [label]="'products.arrangeAppointment' | translate"
    icon="pi pi-calendar"></p-button>
  <p-button
    (click)="close()"
    class="close-button"
    icon="pi pi-times"
    severity="success"
    [rounded]="true"
    [text]="true"></p-button>
</div>
