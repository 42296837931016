import { DateTime } from 'luxon';

export function sortByCreatedAt<T extends { createdAt: string }>(unsorted: T[], asc: 'ASC' | 'DESC' = 'ASC'): T[] {
  return unsorted.sort((a, b) => {
    const dtA: number = DateTime.fromISO(a.createdAt).toMillis();
    const dtB: number = DateTime.fromISO(b.createdAt).toMillis();

    return asc === 'ASC' ? dtA - dtB : dtB - dtA;
  });
}
