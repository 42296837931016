import { EntityState } from '@ngrx/entity';

import { ProcedureTemplateDto } from '../api/models';

export const PROCEDURE_TEMPLATE_FEATURE_KEY = 'procedures';

export enum ProcedureTemplateFilter {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ALL = 'ALL',
}

export interface ProcedureTemplateState extends EntityState<ProcedureTemplateDto> {
  ready: boolean;
  filter: ProcedureTemplateFilter;
}
