/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ADMIN_ACCOUNTS_STORE_KEY, AdminAccountsState } from './model';
import { selectAll } from './reducers';

export const accountsSelector = createFeatureSelector<AdminAccountsState>(ADMIN_ACCOUNTS_STORE_KEY);

export const selectAccounts = createSelector(accountsSelector, (state: AdminAccountsState) =>
  selectAll(state.accounts)
);

export const selectAccountById = (accountId: string) =>
  createSelector(accountsSelector, (state: AdminAccountsState) => state.accounts.entities[accountId] || null);

export const selectReady = createSelector(accountsSelector, (state: AdminAccountsState) => state.ready);

export const selectSelected = createSelector(accountsSelector, (state: AdminAccountsState) => {
  const selectedId: string | null = state.selectedAccount;

  if (selectedId) {
    return state.accounts.entities[selectedId] || null;
  }

  return null;
});

export const selectMultiAccounts = createSelector(accountsSelector, (state: AdminAccountsState) => state.multiSelect);
export const selectAccountsCount = createSelector(
  accountsSelector,
  (state: AdminAccountsState) => state.accounts.ids.length
);

export const selectSingleAccount = (accountId: string | null) =>
  createSelector(accountsSelector, (state: AdminAccountsState) =>
    accountId ? state.accounts.entities[accountId] : undefined
  );
