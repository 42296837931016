import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { EquipmentDto, EquipmentPayloadDto } from './api/models';
import { createEquipment, equipmentActions, setFilter, updateEquipment } from './state/equipment.actions';
import { EquipmentFilter } from './state/equipment.model';
import {
  selectActiveOnly,
  selectEquipmentById,
  selectEquipments,
  selectFilterValue,
} from './state/equipment.selectors';

@Injectable()
export class EquipmentFacade {
  public equipment$!: Observable<EquipmentDto[]>;
  public filter$!: Observable<EquipmentFilter>;
  public activeOnly$!: Observable<EquipmentDto[]>;

  constructor(private store: Store) {
    this.equipment$ = this.store.select(selectEquipments);
    this.activeOnly$ = this.store.select(selectActiveOnly);
    this.filter$ = this.store.select(selectFilterValue);
  }

  public createEquipment(payload: EquipmentPayloadDto): void {
    this.store.dispatch(createEquipment({ payload }));
  }

  public getEquipmentById$(equipmentId: string): Observable<EquipmentDto | undefined> {
    return this.store.select(selectEquipmentById(equipmentId));
  }

  public updateEquipment(equipmentId: string, payload: EquipmentPayloadDto): void {
    return this.store.dispatch(updateEquipment({ equipmentId, payload }));
  }

  public deleteEquipment(equipmentId: string): void {
    this.store.dispatch(equipmentActions.delete({ equipmentId }));
  }

  public restoreEquipment(equipmentId: string): void {
    this.store.dispatch(equipmentActions.restore({ equipmentId }));
  }

  public setActiveOnly(activeOnly: EquipmentFilter): void {
    this.store.dispatch(setFilter({ filter: activeOnly }));
  }
}
