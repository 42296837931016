import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { createFeature, provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { ProcedureTemplateResolver } from './procedure-template.resolver';
import { ProcedureTemplateEffects } from './state/procedure-template.effects';
import { ProcedureTemplateFacade } from './state/procedure-template.facade';
import { PROCEDURE_TEMPLATE_FEATURE_KEY } from './state/procedure-template.model';
import { procedureTemplateReducer } from './state/procedure-template.reducer';

export const templatesProviders: (Provider | EnvironmentProviders)[] = [
  ProcedureTemplateFacade,
  provideState(
    createFeature({
      name: PROCEDURE_TEMPLATE_FEATURE_KEY,
      reducer: procedureTemplateReducer,
    })
  ),
  provideEffects(ProcedureTemplateEffects),
  importProvidersFrom(ApiModule),
  ProcedureTemplateResolver,
];
