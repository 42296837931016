/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppointmentDto } from '../models/appointment-dto';
import { AppointmentGeneralDto } from '../models/appointment-general-dto';
import { AppointmentGeneralFilesDto } from '../models/appointment-general-files-dto';
import { AppointmentGeneralPayloadDto } from '../models/appointment-general-payload-dto';
import { AppointmentLogDto } from '../models/appointment-log-dto';
import { AppointmentLogsCountDto } from '../models/appointment-logs-count-dto';
import { AppointmentNotePayloadDto } from '../models/appointment-note-payload-dto';
import { AppointmentVerificationStatus } from '../models/appointment-verification-status';
import { AppointmentsListItemDto } from '../models/appointments-list-item-dto';
import { AttachmentIdDto } from '../models/attachment-id-dto';
import { MovePayloadDto } from '../models/move-payload-dto';
import { ProcedureDto } from '../models/procedure-dto';
import { ProcedurePayloadDto } from '../models/procedure-payload-dto';
import { ShareDataDto } from '../models/share-data-dto';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation appointmentsControllerReadAll
   */
  static readonly AppointmentsControllerReadAllPath = '/api/appointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerReadAll$Response(params: {
    /**
     * Find appointments from date
     */
    from: string;

    /**
     * Find appointments to date
     */
    to: string;

    /**
     * The type of appointment
     */
    verificationStatus?: AppointmentVerificationStatus;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<AppointmentsListItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsControllerReadAllPath, 'get');
    if (params) {
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('verificationStatus', params.verificationStatus, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AppointmentsListItemDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerReadAll(params: {
    /**
     * Find appointments from date
     */
    from: string;

    /**
     * Find appointments to date
     */
    to: string;

    /**
     * The type of appointment
     */
    verificationStatus?: AppointmentVerificationStatus;
    context?: HttpContext;
  }): Observable<Array<AppointmentsListItemDto>> {
    return this.appointmentsControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentsListItemDto>>) => r.body as Array<AppointmentsListItemDto>)
    );
  }

  /**
   * Path part for operation appointmentsControllerReadAllForPatient
   */
  static readonly AppointmentsControllerReadAllForPatientPath = '/api/appointment/patient/{patientId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerReadAllForPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerReadAllForPatient$Response(params: {
    /**
     * The MongoDB identifier of patient
     */
    patientId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<AppointmentsListItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsControllerReadAllForPatientPath, 'get');
    if (params) {
      rb.path('patientId', params.patientId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AppointmentsListItemDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerReadAllForPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerReadAllForPatient(params: {
    /**
     * The MongoDB identifier of patient
     */
    patientId: string;
    context?: HttpContext;
  }): Observable<Array<AppointmentsListItemDto>> {
    return this.appointmentsControllerReadAllForPatient$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentsListItemDto>>) => r.body as Array<AppointmentsListItemDto>)
    );
  }

  /**
   * Path part for operation appointmentsControllerReadOne
   */
  static readonly AppointmentsControllerReadOnePath = '/api/appointment/{appointmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerReadOne$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsControllerReadOnePath, 'get');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerReadOne(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation singleAppointmentControllerDeleteAppointment
   */
  static readonly SingleAppointmentControllerDeleteAppointmentPath = '/api/appointment/{appointmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `singleAppointmentControllerDeleteAppointment()` instead.
   *
   * This method doesn't expect any request body.
   */
  singleAppointmentControllerDeleteAppointment$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AppointmentService.SingleAppointmentControllerDeleteAppointmentPath,
      'delete'
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `singleAppointmentControllerDeleteAppointment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  singleAppointmentControllerDeleteAppointment(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.singleAppointmentControllerDeleteAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation appointmentsControllerPushNote
   */
  static readonly AppointmentsControllerPushNotePath = '/api/appointment/{appointmentId}/notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerPushNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentsControllerPushNote$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: AppointmentNotePayloadDto;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsControllerPushNotePath, 'patch');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerPushNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentsControllerPushNote(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: AppointmentNotePayloadDto;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerPushNote$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation appointmentsControllerDeleteNote
   */
  static readonly AppointmentsControllerDeleteNotePath = '/api/appointment/{appointmentId}/notes/{noteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerDeleteNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerDeleteNote$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;

    /**
     * The MongoDB identifier of note in appointment
     */
    noteId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsControllerDeleteNotePath, 'delete');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.path('noteId', params.noteId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerDeleteNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerDeleteNote(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;

    /**
     * The MongoDB identifier of note in appointment
     */
    noteId: string;
    context?: HttpContext;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerDeleteNote$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation appointmentsControllerUpdateNote
   */
  static readonly AppointmentsControllerUpdateNotePath = '/api/appointment/{appointmentId}/notes/{noteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerUpdateNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentsControllerUpdateNote$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;

    /**
     * The MongoDB identifier of note in appointment
     */
    noteId: string;
    context?: HttpContext;
    body: AppointmentNotePayloadDto;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsControllerUpdateNotePath, 'patch');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.path('noteId', params.noteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerUpdateNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentsControllerUpdateNote(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;

    /**
     * The MongoDB identifier of note in appointment
     */
    noteId: string;
    context?: HttpContext;
    body: AppointmentNotePayloadDto;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerUpdateNote$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation appointmentsControllerApproveOwnAppointment
   */
  static readonly AppointmentsControllerApproveOwnAppointmentPath = '/api/appointment/{appointmentId}/approve-own';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerApproveOwnAppointment()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerApproveOwnAppointment$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AppointmentService.AppointmentsControllerApproveOwnAppointmentPath,
      'patch'
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerApproveOwnAppointment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerApproveOwnAppointment(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerApproveOwnAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation appointmentsControllerApproveInternsAppointment
   */
  static readonly AppointmentsControllerApproveInternsAppointmentPath =
    '/api/appointment/{appointmentId}/approve-intern';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerApproveInternsAppointment()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerApproveInternsAppointment$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AppointmentService.AppointmentsControllerApproveInternsAppointmentPath,
      'patch'
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerApproveInternsAppointment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerApproveInternsAppointment(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerApproveInternsAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation appointmentsControllerSendApprovalAppointment
   */
  static readonly AppointmentsControllerSendApprovalAppointmentPath = '/api/appointment/{appointmentId}/send-approval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerSendApprovalAppointment()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerSendApprovalAppointment$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AppointmentService.AppointmentsControllerSendApprovalAppointmentPath,
      'patch'
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerSendApprovalAppointment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerSendApprovalAppointment(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerSendApprovalAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation appointmentsControllerRequireChangesAppointment
   */
  static readonly AppointmentsControllerRequireChangesAppointmentPath =
    '/api/appointment/{appointmentId}/require-changes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerRequireChangesAppointment()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerRequireChangesAppointment$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AppointmentService.AppointmentsControllerRequireChangesAppointmentPath,
      'patch'
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerRequireChangesAppointment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsControllerRequireChangesAppointment(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerRequireChangesAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation appointmentsControllerMove
   */
  static readonly AppointmentsControllerMovePath = '/api/appointment/{appointmentId}/move';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsControllerMove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentsControllerMove$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: MovePayloadDto;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsControllerMovePath, 'patch');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsControllerMove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentsControllerMove(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: MovePayloadDto;
  }): Observable<AppointmentDto> {
    return this.appointmentsControllerMove$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation singleAppointmentControllerGetPdfReport
   */
  static readonly SingleAppointmentControllerGetPdfReportPath = '/api/appointment/{appointmentId}/report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `singleAppointmentControllerGetPdfReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  singleAppointmentControllerGetPdfReport$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.SingleAppointmentControllerGetPdfReportPath, 'get');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `singleAppointmentControllerGetPdfReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  singleAppointmentControllerGetPdfReport(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<void> {
    return this.singleAppointmentControllerGetPdfReport$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation singleAppointmentControllerShareAppointmentReport
   */
  static readonly SingleAppointmentControllerShareAppointmentReportPath = '/api/appointment/{appointmentId}/share';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `singleAppointmentControllerShareAppointmentReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  singleAppointmentControllerShareAppointmentReport$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    'x-tenant-id': string;
    context?: HttpContext;
    body: ShareDataDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AppointmentService.SingleAppointmentControllerShareAppointmentReportPath,
      'post'
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `singleAppointmentControllerShareAppointmentReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  singleAppointmentControllerShareAppointmentReport(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    'x-tenant-id': string;
    context?: HttpContext;
    body: ShareDataDto;
  }): Observable<void> {
    return this.singleAppointmentControllerShareAppointmentReport$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation attachmentsControllerReadOne
   */
  static readonly AttachmentsControllerReadOnePath = '/api/appointment/{appointmentId}/attachments/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachmentsControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  attachmentsControllerReadOne$Response(params: {
    'x-tenant-id': string;

    /**
     * The MongoDB appointment identifier
     */
    appointmentId: string;

    /**
     * The MongoDB attachment identifier
     */
    attachmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AttachmentsControllerReadOnePath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.path('appointmentId', params.appointmentId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `attachmentsControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attachmentsControllerReadOne(params: {
    'x-tenant-id': string;

    /**
     * The MongoDB appointment identifier
     */
    appointmentId: string;

    /**
     * The MongoDB attachment identifier
     */
    attachmentId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.attachmentsControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation attachmentsControllerDeleteAttachments
   */
  static readonly AttachmentsControllerDeleteAttachmentsPath = '/api/appointment/{appointmentId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachmentsControllerDeleteAttachments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attachmentsControllerDeleteAttachments$Response(params: {
    'x-tenant-id': string;

    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: AttachmentIdDto;
  }): Observable<StrictHttpResponse<AppointmentDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AttachmentsControllerDeleteAttachmentsPath, 'patch');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `attachmentsControllerDeleteAttachments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attachmentsControllerDeleteAttachments(params: {
    'x-tenant-id': string;

    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: AttachmentIdDto;
  }): Observable<AppointmentDto> {
    return this.attachmentsControllerDeleteAttachments$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentDto>) => r.body as AppointmentDto)
    );
  }

  /**
   * Path part for operation appointmentsLogControllerRead
   */
  static readonly AppointmentsLogControllerReadPath = '/api/appointment/{appointmentId}/logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsLogControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsLogControllerRead$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<AppointmentLogDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsLogControllerReadPath, 'get');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AppointmentLogDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsLogControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsLogControllerRead(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<Array<AppointmentLogDto>> {
    return this.appointmentsLogControllerRead$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentLogDto>>) => r.body as Array<AppointmentLogDto>)
    );
  }

  /**
   * Path part for operation appointmentsLogControllerCount
   */
  static readonly AppointmentsLogControllerCountPath = '/api/appointment/{appointmentId}/logs/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentsLogControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsLogControllerCount$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AppointmentLogsCountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentsLogControllerCountPath, 'get');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentLogsCountDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentsLogControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentsLogControllerCount(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<AppointmentLogsCountDto> {
    return this.appointmentsLogControllerCount$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentLogsCountDto>) => r.body as AppointmentLogsCountDto)
    );
  }

  /**
   * Path part for operation appointmentGeneralControllerCreate
   */
  static readonly AppointmentGeneralControllerCreatePath = '/api/appointment/general';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentGeneralControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentGeneralControllerCreate$Response(params: {
    /**
     * CalendarEvent which is connected with created appointment
     */
    eventId?: string;
    context?: HttpContext;
    body: AppointmentGeneralPayloadDto;
  }): Observable<StrictHttpResponse<AppointmentGeneralDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentGeneralControllerCreatePath, 'post');
    if (params) {
      rb.query('eventId', params.eventId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentGeneralDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentGeneralControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentGeneralControllerCreate(params: {
    /**
     * CalendarEvent which is connected with created appointment
     */
    eventId?: string;
    context?: HttpContext;
    body: AppointmentGeneralPayloadDto;
  }): Observable<AppointmentGeneralDto> {
    return this.appointmentGeneralControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentGeneralDto>) => r.body as AppointmentGeneralDto)
    );
  }

  /**
   * Path part for operation appointmentGeneralControllerUpdate
   */
  static readonly AppointmentGeneralControllerUpdatePath = '/api/appointment/general/{appointmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentGeneralControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentGeneralControllerUpdate$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: AppointmentGeneralPayloadDto;
  }): Observable<StrictHttpResponse<AppointmentGeneralDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentGeneralControllerUpdatePath, 'put');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentGeneralDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentGeneralControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointmentGeneralControllerUpdate(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: AppointmentGeneralPayloadDto;
  }): Observable<AppointmentGeneralDto> {
    return this.appointmentGeneralControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentGeneralDto>) => r.body as AppointmentGeneralDto)
    );
  }

  /**
   * Path part for operation appointmentGeneralControllerDelete
   */
  static readonly AppointmentGeneralControllerDeletePath = '/api/appointment/general/{appointmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentGeneralControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentGeneralControllerDelete$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.AppointmentGeneralControllerDeletePath, 'delete');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentGeneralControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appointmentGeneralControllerDelete(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.appointmentGeneralControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation appointmentGeneralFilesControllerUpdateFiles
   */
  static readonly AppointmentGeneralFilesControllerUpdateFilesPath = '/api/appointment/general/{appointmentId}/files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentGeneralFilesControllerUpdateFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  appointmentGeneralFilesControllerUpdateFiles$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: AppointmentGeneralFilesDto;
  }): Observable<StrictHttpResponse<AppointmentGeneralDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AppointmentService.AppointmentGeneralFilesControllerUpdateFilesPath,
      'put'
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentGeneralDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentGeneralFilesControllerUpdateFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  appointmentGeneralFilesControllerUpdateFiles(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: AppointmentGeneralFilesDto;
  }): Observable<AppointmentGeneralDto> {
    return this.appointmentGeneralFilesControllerUpdateFiles$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentGeneralDto>) => r.body as AppointmentGeneralDto)
    );
  }

  /**
   * Path part for operation appointmentGeneralFilesControllerAppendFiles
   */
  static readonly AppointmentGeneralFilesControllerAppendFilesPath = '/api/appointment/general/{appointmentId}/files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointmentGeneralFilesControllerAppendFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  appointmentGeneralFilesControllerAppendFiles$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: {
      attachments?: Array<Blob>;
    };
  }): Observable<StrictHttpResponse<AppointmentGeneralDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AppointmentService.AppointmentGeneralFilesControllerAppendFilesPath,
      'post'
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentGeneralDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appointmentGeneralFilesControllerAppendFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  appointmentGeneralFilesControllerAppendFiles(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: {
      attachments?: Array<Blob>;
    };
  }): Observable<AppointmentGeneralDto> {
    return this.appointmentGeneralFilesControllerAppendFiles$Response(params).pipe(
      map((r: StrictHttpResponse<AppointmentGeneralDto>) => r.body as AppointmentGeneralDto)
    );
  }

  /**
   * Path part for operation procedureControllerCreate
   */
  static readonly ProcedureControllerCreatePath = '/api/appointment/procedure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureControllerCreate$Response(params: {
    /**
     * CalendarEvent which is connected with created appointment
     */
    eventId?: string;
    context?: HttpContext;
    body: ProcedurePayloadDto;
  }): Observable<StrictHttpResponse<ProcedureDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ProcedureControllerCreatePath, 'post');
    if (params) {
      rb.query('eventId', params.eventId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProcedureDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureControllerCreate(params: {
    /**
     * CalendarEvent which is connected with created appointment
     */
    eventId?: string;
    context?: HttpContext;
    body: ProcedurePayloadDto;
  }): Observable<ProcedureDto> {
    return this.procedureControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<ProcedureDto>) => r.body as ProcedureDto)
    );
  }

  /**
   * Path part for operation procedureControllerUpdate
   */
  static readonly ProcedureControllerUpdatePath = '/api/appointment/procedure/{appointmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureControllerUpdate$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: ProcedurePayloadDto;
  }): Observable<StrictHttpResponse<ProcedureDto>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ProcedureControllerUpdatePath, 'put');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProcedureDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  procedureControllerUpdate(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
    body: ProcedurePayloadDto;
  }): Observable<ProcedureDto> {
    return this.procedureControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<ProcedureDto>) => r.body as ProcedureDto)
    );
  }

  /**
   * Path part for operation procedureControllerDelete
   */
  static readonly ProcedureControllerDeletePath = '/api/appointment/procedure/{appointmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedureControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedureControllerDelete$Response(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ProcedureControllerDeletePath, 'delete');
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedureControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedureControllerDelete(params: {
    /**
     * The MongoDB identifier of appointment
     */
    appointmentId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.procedureControllerDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }
}
