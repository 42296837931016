/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddressDto } from '../models/address-dto';
import { AddressPayloadDto } from '../models/address-payload-dto';
import { AppConfigDto } from '../models/app-config-dto';
import { CompanyNamePayloadDto } from '../models/company-name-payload-dto';
import { ContactPointDto } from '../models/contact-point-dto';
import { ContactPointPayloadDto } from '../models/contact-point-payload-dto';
import { FeatEnum } from '../models/feat-enum';
import { NotificationsSettingsDto } from '../models/notifications-settings-dto';
import { PublicLogoUrlDto } from '../models/public-logo-url-dto';

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation featureControllerHasFeat
   */
  static readonly FeatureControllerHasFeatPath = '/api/config/feat/{featName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `featureControllerHasFeat()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureControllerHasFeat$Response(params: {
    /**
     * The application feature
     */
    featName: FeatEnum;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.FeatureControllerHasFeatPath, 'get');
    if (params) {
      rb.path('featName', params.featName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `featureControllerHasFeat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureControllerHasFeat(params: {
    /**
     * The application feature
     */
    featName: FeatEnum;
    context?: HttpContext;
  }): Observable<void> {
    return this.featureControllerHasFeat$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation appConfigControllerPatchCompanyName
   */
  static readonly AppConfigControllerPatchCompanyNamePath = '/api/config/company-name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appConfigControllerPatchCompanyName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appConfigControllerPatchCompanyName$Response(params: {
    context?: HttpContext;
    body: CompanyNamePayloadDto;
  }): Observable<StrictHttpResponse<CompanyNamePayloadDto>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AppConfigControllerPatchCompanyNamePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CompanyNamePayloadDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appConfigControllerPatchCompanyName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appConfigControllerPatchCompanyName(params: {
    context?: HttpContext;
    body: CompanyNamePayloadDto;
  }): Observable<CompanyNamePayloadDto> {
    return this.appConfigControllerPatchCompanyName$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyNamePayloadDto>) => r.body as CompanyNamePayloadDto)
    );
  }

  /**
   * Path part for operation appConfigControllerCreate
   */
  static readonly AppConfigControllerCreatePath = '/api/config/logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appConfigControllerCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  appConfigControllerCreate$Response(params: {
    context?: HttpContext;
    body: {
      file?: Blob;
    };
  }): Observable<StrictHttpResponse<AppConfigDto>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AppConfigControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppConfigDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appConfigControllerCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  appConfigControllerCreate(params: {
    context?: HttpContext;
    body: {
      file?: Blob;
    };
  }): Observable<AppConfigDto> {
    return this.appConfigControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<AppConfigDto>) => r.body as AppConfigDto)
    );
  }

  /**
   * Path part for operation appConfigControllerGetPermissions
   */
  static readonly AppConfigControllerGetPermissionsPath = '/api/config/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appConfigControllerGetPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigControllerGetPermissions$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AppConfigControllerGetPermissionsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appConfigControllerGetPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigControllerGetPermissions(params?: { context?: HttpContext }): Observable<{}> {
    return this.appConfigControllerGetPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<{}>) => r.body as {})
    );
  }

  /**
   * Path part for operation appConfigControllerSetNotificationsSettings
   */
  static readonly AppConfigControllerSetNotificationsSettingsPath = '/api/config/notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appConfigControllerSetNotificationsSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appConfigControllerSetNotificationsSettings$Response(params: {
    context?: HttpContext;
    body: NotificationsSettingsDto;
  }): Observable<StrictHttpResponse<AppConfigDto>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AppConfigControllerSetNotificationsSettingsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppConfigDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appConfigControllerSetNotificationsSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appConfigControllerSetNotificationsSettings(params: {
    context?: HttpContext;
    body: NotificationsSettingsDto;
  }): Observable<AppConfigDto> {
    return this.appConfigControllerSetNotificationsSettings$Response(params).pipe(
      map((r: StrictHttpResponse<AppConfigDto>) => r.body as AppConfigDto)
    );
  }

  /**
   * Path part for operation addressControllerCreate
   */
  static readonly AddressControllerCreatePath = '/api/config/address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addressControllerCreate$Response(params: {
    context?: HttpContext;
    body: AddressPayloadDto;
  }): Observable<StrictHttpResponse<Array<AddressDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AddressControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AddressDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addressControllerCreate(params: { context?: HttpContext; body: AddressPayloadDto }): Observable<Array<AddressDto>> {
    return this.addressControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AddressDto>>) => r.body as Array<AddressDto>)
    );
  }

  /**
   * Path part for operation addressControllerDeleteOne
   */
  static readonly AddressControllerDeleteOnePath = '/api/config/address/{addressId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerDeleteOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerDeleteOne$Response(params: {
    /**
     * The address Mongo DB identifier
     */
    addressId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<AddressDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AddressControllerDeleteOnePath, 'delete');
    if (params) {
      rb.path('addressId', params.addressId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AddressDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressControllerDeleteOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerDeleteOne(params: {
    /**
     * The address Mongo DB identifier
     */
    addressId: string;
    context?: HttpContext;
  }): Observable<Array<AddressDto>> {
    return this.addressControllerDeleteOne$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AddressDto>>) => r.body as Array<AddressDto>)
    );
  }

  /**
   * Path part for operation addressControllerUpdate
   */
  static readonly AddressControllerUpdatePath = '/api/config/address/{addressId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addressControllerUpdate$Response(params: {
    /**
     * The address Mongo DB identifier
     */
    addressId: string;
    context?: HttpContext;
    body: AddressPayloadDto;
  }): Observable<StrictHttpResponse<Array<AddressDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AddressControllerUpdatePath, 'patch');
    if (params) {
      rb.path('addressId', params.addressId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AddressDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addressControllerUpdate(params: {
    /**
     * The address Mongo DB identifier
     */
    addressId: string;
    context?: HttpContext;
    body: AddressPayloadDto;
  }): Observable<Array<AddressDto>> {
    return this.addressControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AddressDto>>) => r.body as Array<AddressDto>)
    );
  }

  /**
   * Path part for operation appConfigUnsecureControllerReadConfig
   */
  static readonly AppConfigUnsecureControllerReadConfigPath = '/api/config/{tenantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appConfigUnsecureControllerReadConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigUnsecureControllerReadConfig$Response(params: {
    /**
     * Tenant Id
     */
    tenantId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AppConfigDto>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AppConfigUnsecureControllerReadConfigPath, 'get');
    if (params) {
      rb.path('tenantId', params.tenantId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppConfigDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appConfigUnsecureControllerReadConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigUnsecureControllerReadConfig(params: {
    /**
     * Tenant Id
     */
    tenantId: string;
    context?: HttpContext;
  }): Observable<AppConfigDto> {
    return this.appConfigUnsecureControllerReadConfig$Response(params).pipe(
      map((r: StrictHttpResponse<AppConfigDto>) => r.body as AppConfigDto)
    );
  }

  /**
   * Path part for operation appConfigUnsecureControllerReadCompanyName
   */
  static readonly AppConfigUnsecureControllerReadCompanyNamePath = '/api/config/{tenantId}/company-name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appConfigUnsecureControllerReadCompanyName()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigUnsecureControllerReadCompanyName$Response(params: {
    /**
     * Tenant Id
     */
    tenantId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AppConfigUnsecureControllerReadCompanyNamePath, 'get');
    if (params) {
      rb.path('tenantId', params.tenantId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appConfigUnsecureControllerReadCompanyName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigUnsecureControllerReadCompanyName(params: {
    /**
     * Tenant Id
     */
    tenantId: string;
    context?: HttpContext;
  }): Observable<string> {
    return this.appConfigUnsecureControllerReadCompanyName$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation appConfigUnsecureControllerReadLogoPublicUrl
   */
  static readonly AppConfigUnsecureControllerReadLogoPublicUrlPath = '/api/config/{tenantId}/logo-public-url';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appConfigUnsecureControllerReadLogoPublicUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigUnsecureControllerReadLogoPublicUrl$Response(params: {
    /**
     * Tenant Id
     */
    tenantId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<PublicLogoUrlDto>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AppConfigUnsecureControllerReadLogoPublicUrlPath, 'get');
    if (params) {
      rb.path('tenantId', params.tenantId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PublicLogoUrlDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appConfigUnsecureControllerReadLogoPublicUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigUnsecureControllerReadLogoPublicUrl(params: {
    /**
     * Tenant Id
     */
    tenantId: string;
    context?: HttpContext;
  }): Observable<PublicLogoUrlDto> {
    return this.appConfigUnsecureControllerReadLogoPublicUrl$Response(params).pipe(
      map((r: StrictHttpResponse<PublicLogoUrlDto>) => r.body as PublicLogoUrlDto)
    );
  }

  /**
   * Path part for operation appConfigUnsecureControllerReadLogo
   */
  static readonly AppConfigUnsecureControllerReadLogoPath = '/api/config/{tenantId}/logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appConfigUnsecureControllerReadLogo()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigUnsecureControllerReadLogo$Response(params: {
    /**
     * Tenant Id
     */
    tenantId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.AppConfigUnsecureControllerReadLogoPath, 'get');
    if (params) {
      rb.path('tenantId', params.tenantId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appConfigUnsecureControllerReadLogo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appConfigUnsecureControllerReadLogo(params: {
    /**
     * Tenant Id
     */
    tenantId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.appConfigUnsecureControllerReadLogo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation contactPointControllerRead
   */
  static readonly ContactPointControllerReadPath = '/api/config/contact-point';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactPointControllerRead$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<ContactPointDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.ContactPointControllerReadPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ContactPointDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactPointControllerRead(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<Array<ContactPointDto>> {
    return this.contactPointControllerRead$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContactPointDto>>) => r.body as Array<ContactPointDto>)
    );
  }

  /**
   * Path part for operation contactPointControllerCreate
   */
  static readonly ContactPointControllerCreatePath = '/api/config/contact-point';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactPointControllerCreate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: ContactPointPayloadDto;
  }): Observable<StrictHttpResponse<ContactPointDto>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.ContactPointControllerCreatePath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ContactPointDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactPointControllerCreate(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: ContactPointPayloadDto;
  }): Observable<ContactPointDto> {
    return this.contactPointControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<ContactPointDto>) => r.body as ContactPointDto)
    );
  }

  /**
   * Path part for operation contactPointControllerUpdate
   */
  static readonly ContactPointControllerUpdatePath = '/api/config/contact-point/{contactPointId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactPointControllerUpdate$Response(params: {
    /**
     * The address Mongo DB identifier
     */
    contactPointId: string;
    context?: HttpContext;
    body: ContactPointPayloadDto;
  }): Observable<StrictHttpResponse<Array<ContactPointDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.ContactPointControllerUpdatePath, 'put');
    if (params) {
      rb.path('contactPointId', params.contactPointId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ContactPointDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactPointControllerUpdate(params: {
    /**
     * The address Mongo DB identifier
     */
    contactPointId: string;
    context?: HttpContext;
    body: ContactPointPayloadDto;
  }): Observable<Array<ContactPointDto>> {
    return this.contactPointControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContactPointDto>>) => r.body as Array<ContactPointDto>)
    );
  }

  /**
   * Path part for operation contactPointControllerDelete
   */
  static readonly ContactPointControllerDeletePath = '/api/config/contact-point/{contactPointId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactPointControllerDelete$Response(params: {
    /**
     * The address Mongo DB identifier
     */
    contactPointId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<ContactPointDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ConfigService.ContactPointControllerDeletePath, 'delete');
    if (params) {
      rb.path('contactPointId', params.contactPointId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ContactPointDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactPointControllerDelete(params: {
    /**
     * The address Mongo DB identifier
     */
    contactPointId: string;
    context?: HttpContext;
  }): Observable<Array<ContactPointDto>> {
    return this.contactPointControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContactPointDto>>) => r.body as Array<ContactPointDto>)
    );
  }
}
