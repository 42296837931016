import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserDto } from '@fizjo-pro/settings/data-user';
import { AvatarModule } from 'primeng/avatar';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AvatarModule],
  selector: 'fizjo-pro-app-user-avatar',
  standalone: true,
  styleUrls: ['./app-user-avatar.component.scss'],
  templateUrl: './app-user-avatar.component.html',
})
export class AppUserAvatarComponent {
  @Input() public userDto!: UserDto;
  @Input() public size: 'normal' | 'large' | 'xlarge' = 'normal';
  public get initials(): string {
    return `${this.userDto.firstName[0]}${this.userDto.lastName[0]}`;
  }
}
