import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { valueAccessorFactory } from '@kate-fizjo/practice-shared/tools';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { Observable, Subject, takeUntil } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PasswordForm, passwordFormFactory } from './password.form';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PasswordModule, ReactiveFormsModule, InputTextModule],
  providers: [valueAccessorFactory(() => CreatePasswordComponent)],
  selector: 'fizjo-pro-create-password',
  standalone: true,
  styleUrls: ['./create-password.component.scss'],
  templateUrl: './create-password.component.html',
})
export class CreatePasswordComponent implements ControlValueAccessor, OnInit, OnDestroy {
  #destroy$ = new Subject<void>();
  private password$!: Observable<string>;

  public passwordForm: FormGroup<PasswordForm> = passwordFormFactory();

  public ngOnInit(): void {
    this.password$ = this.passwordForm.valueChanges.pipe(
      filter(() => this.passwordForm.valid),
      map(passwordFormData => passwordFormData.password),
      filter(Boolean),
      takeUntil(this.#destroy$)
    );
  }

  public ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
  public registerOnChange(callback: (password: string) => void): void {
    this.password$.pipe(takeUntil(this.#destroy$)).subscribe(callback);
  }

  public registerOnTouched(callback: () => void): void {
    this.password$.pipe(takeUntil(this.#destroy$)).subscribe(callback);
  }

  public writeValue(password: string): void {
    if (password) {
      this.passwordForm.controls.password.setValue(password);
      this.passwordForm.controls.passwordRepeat.setValue(password);
    }
  }
}
