import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, finalize, first, tap } from 'rxjs/operators';

export abstract class ResolverHelper implements Resolve<any> {
  #isLoading = false;

  protected constructor(protected store: Store) {}

  protected helpResolve(action: Action): OperatorFunction<boolean, any> {
    return (ready$: Observable<boolean>) =>
      ready$.pipe(
        tap((ready: boolean) => {
          if (!this.#isLoading && !ready) {
            this.#isLoading = true;
            this.store.dispatch(action);
          }
        }),
        filter((ready: boolean) => ready),
        first(),
        finalize(() => {
          this.#isLoading = false;
        })
      );
  }

  abstract resolve(route?: ActivatedRouteSnapshot): Observable<any>;
}

export const helpResolve = (store: Store, action: Action): OperatorFunction<boolean, any> => {
  let isLoading = false;

  return (ready$: Observable<boolean>) =>
    ready$.pipe(
      tap((ready: boolean) => {
        if (!isLoading && !ready) {
          isLoading = true;
          store.dispatch(action);
        }
      }),
      filter((ready: boolean) => ready),
      first(),
      finalize(() => {
        isLoading = false;
      })
    );
};
