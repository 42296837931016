import { Routes } from '@angular/router';

export const authRoutes: Routes = [
  {
    loadComponent: () => import('./login/login.component').then(m => m.LoginComponent),
    path: '',
  },
  {
    loadComponent: () => import('./password-remind/password-remind.component').then(m => m.PasswordRemindComponent),
    path: 'password',
  },
  {
    loadComponent: () => import('./init/init.component').then(m => m.InitComponent),
    path: 'init',
  },
  {
    path: '**',
    redirectTo: '',
  },
];
