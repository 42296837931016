import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserDto } from '@fizjo-pro/data-auth';
import { PinCodeComponent } from '@fizjo-pro/shared/ui';
import { AuthService, MeService } from '@fizjo-pro/util-auth';
import { TranslateModule } from '@ngx-translate/core';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginForm, loginFormFactory } from '../login.form';

@Component({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    TranslateModule,
    PinCodeComponent,
    RxLet,
    RxIf,
  ],
  selector: 'fizjo-pro-login-modal',
  standalone: true,
  styleUrls: ['./login-modal.component.scss'],
  templateUrl: './login-modal.component.html',
})
export class LoginModalComponent implements OnInit {
  public loginForm: FormGroup<LoginForm> = loginFormFactory();
  private authService: AuthService = inject(AuthService);
  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  private meService: MeService = inject(MeService);
  #destroyRef: DestroyRef = inject(DestroyRef);

  public hasRefreshToken$!: Observable<boolean>;

  public pinCode: FormControl<string | null> = new FormControl<string | null>(null, {
    validators: Validators.required,
  });

  public ngOnInit(): void {
    this.hasRefreshToken$ = this.meService.me$.pipe(
      map((user: UserDto | null) => (user?._id ? !!localStorage.getItem(user?._id) && !!user.pinCode : false))
    );
  }

  public authenticate(): void {
    this.authService
      .authenticateAsync$(this.loginForm.getRawValue())
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  public refreshToken(pinCodeSrc?: string): void {
    const pinCode: string | null = pinCodeSrc || this.pinCode.getRawValue();

    if (pinCode) {
      this.authService
        .refreshCode$(pinCode)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(() => {
          this.dialogRef.close();
        });
    }
  }
}
