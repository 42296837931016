import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie';
import { switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { authActions } from './auth.actions';
import { UserDto } from '../api/models/user-dto';
import { UserService } from '../api/services/user.service';

export interface RefreshToken {
  tenantId: string;
  _id: string;
}

@Injectable()
export class AuthEffects {
  #cookies: CookieService = inject(CookieService);
  #action$: Actions = inject(Actions);
  #userService: UserService = inject(UserService);

  public fetchOwnUser$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(authActions.fetchOwnUser),
      switchMap(() => this.#userService.userControllerReadMe()),
      map((user: UserDto) => authActions.fetchOwnUserSuccess({ user }))
    );
  });

  public updateUserData$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(authActions.updateUserData),
      switchMap(action =>
        this.#userService.userControllerUpdate({
          body: action.payload,
          uid: action.uid,
        })
      ),
      map((user: UserDto) => authActions.fetchOwnUserSuccess({ user }))
    );
  });

  public setAuthenticated$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(authActions.setAuthenticated),
      tap(action => {
        if (action.tokenDto?.token) {
          this.setJwtCookie(action.tokenDto.token);
        }
        if (action.tokenDto?.refresh_token) {
          this.saveRefreshToken(action.tokenDto.refresh_token);
        }
      }),
      map(() => authActions.fetchOwnUser())
    );
  });

  public setUnauthenticated$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(authActions.setUnauthenticated),
      tap(action => {
        this.#cookies.remove('jwt');
        if (action.uid) {
          localStorage.removeItem(action.uid);
        }
      }),
      map(() => authActions.clearState())
    );
  });

  private setJwtCookie(token: string): void {
    const payload: { exp: number } = jwtDecode(token);

    this.#cookies.put('jwt', token, {
      expires: DateTime.fromSeconds(payload.exp).toJSDate(),
    });
  }

  private saveRefreshToken(refreshToken: string): void {
    const tokenPayload: RefreshToken = jwtDecode<RefreshToken>(refreshToken);

    localStorage.setItem(tokenPayload._id, refreshToken);
  }
}
