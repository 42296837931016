<input
  type="file"
  [accept]="accept.join(',')"
  (change)="fileInputChangeHandler($event)"
  #fileInput
  class="hidden"
  [multiple]="isMultiple" />
<div class="flex flex-row align-items-center w-full gap-1">
  <div
    class="border-1 border-500 hover:border-900 border-round-sm flex flex-row gap-2 align-items-center p-2 flex-grow-1 file-chips-container flex-wrap"
    *rxLet="file$; let files">
    <label
      class="file-select-label bg-white text-gray-600 ml-2"
      *rxLet="count$; let count"
      [ngClass]="count > 0 ? 'active text-xs' : ''"
      >{{ count > 0 ? label : placeholder }}</label
    >
    <span *ngFor="let file of files; let index = index">
      <p-chip
        [icon]="mimeTypeToIcon(file.type)"
        [removable]="true"
        [label]="file.name"
        styleClass="bg-primary"
        (onRemove)="dropFile(index)"></p-chip>
    </span>
    <span *rxFor="let attachment of attachments$; let index = index">
      <p-chip
        [icon]="mimeTypeToIcon(attachment.mimetype)"
        [removable]="true"
        [label]="attachment.originalname"
        (onRemove)="dropAttachment(index)"></p-chip>
    </span>
  </div>
  <button
    pButton
    class="p-button p-button-icon-only trigger-button"
    icon="pi pi-file-import"
    [disabled]="isDisabled"
    (click)="fileInput.click()"></button>
</div>
