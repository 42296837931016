import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  AddressDto,
  AddressPayloadDto,
  AppConfigDto,
  ContactPointDto,
  ContactPointPayloadDto,
  NotificationsSettingsDto,
} from '../api/models';

export const appConfigActions = createActionGroup({
  events: {
    fetchAppConfig: emptyProps(),
    fetchAppConfigSuccess: props<{ appConfig: AppConfigDto }>(),
    saveAddress: props<{ address: AddressPayloadDto }>(),
    saveAddressSuccess: props<{ addresses: AddressDto[] }>(),
    saveCompanyName: props<{ companyName: string }>(),
    saveCompanyNameSuccess: props<{ companyName: string }>(),
    setAddresses: props<{ addresses: AddressDto[] }>(),
    fetchPermissionMatrix: emptyProps(),
    fetchPermissionMatrixSuccess: props<{ permissionMatrix: Record<string, string[]> }>(),
    'put notifications settings': props<{ notificationsSettings: NotificationsSettingsDto }>(),
    'turn off notifications': emptyProps(),
    'put notifications settings success': props<{ appConfig: AppConfigDto }>(),
  },
  source: 'appConfig',
});

export const contactPointActions = createActionGroup({
  events: {
    deleteContactPoint: props<{ contactPointId: string }>(),
    deleteContactPointSuccess: props<{ contactPoints: ContactPointDto[] }>(),
    'create contact point': props<{ contactPoint: ContactPointPayloadDto }>(),
    'create contact point success': props<{ contactPoint: ContactPointDto }>(),
    'update contact point': props<{ contactPoint: ContactPointPayloadDto; id: string }>(),
    'update contact point success': props<{ contactPoints: ContactPointDto[] }>(),
  },
  source: 'appConfig',
});
