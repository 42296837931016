import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { valueAccessorFactory } from '@kate-fizjo/practice-shared/tools';
import { mimeTypeToIcon } from '@kate-fizjo/shared/tools';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { BehaviorSubject, combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';

import { AcceptMime, Attachment } from './file-picker.model';

@Component({
  imports: [CommonModule, ChipModule, RxLet, RxFor, ButtonModule],
  providers: [valueAccessorFactory(() => FilePickerComponent)],
  selector: 'fizjo-pro-file-picker',
  standalone: true,
  styleUrls: ['./file-picker.component.scss'],
  templateUrl: './file-picker.component.html',
})
export class FilePickerComponent implements OnInit, ControlValueAccessor, OnDestroy {
  #attachment$: BehaviorSubject<Attachment[]> = new BehaviorSubject<Attachment[]>([]);
  #file$: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);
  #destroy$ = new Subject<void>();

  @Input() placeholder = 'Wybierz pliki';
  @Input() label = 'Wybrane pliki';
  @Input() isMultiple = false;
  @Input() accept: AcceptMime[] = [AcceptMime.ALL];

  @Output() delete: EventEmitter<Attachment> = new EventEmitter<Attachment>();

  public isDisabled = false;
  public file$!: Observable<File[]>;
  public attachments$!: Observable<Attachment[]>;
  public count$!: Observable<number>;

  public mimeTypeToIcon: (mimeType: string) => string = mimeTypeToIcon;

  @Input() set attachments(value: Attachment[]) {
    this.#attachment$.next(value);
  }

  public ngOnInit(): void {
    this.file$ = this.#file$.asObservable();
    this.attachments$ = this.#attachment$.asObservable();
    this.count$ = combineLatest([this.file$, this.attachments$]).pipe(
      map(([files, attachments]: [File[], Attachment[]]) => files.length + attachments.length)
    );
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
  public writeValue(): void {
    // Does not need to be implemented
  }

  registerOnChange(callback: (files: File[]) => void): void {
    this.file$.pipe(takeUntil(this.#destroy$)).subscribe(callback);
  }

  public registerOnTouched(callback: () => void): void {
    this.file$.pipe(takeUntil(this.#destroy$)).subscribe(callback);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public fileInputChangeHandler(event: Event): void {
    const fileInput: HTMLInputElement | null = event.target as HTMLInputElement;

    if (fileInput && fileInput?.files?.length) {
      this.#file$.next(Array.from(fileInput.files));
    }
  }

  public dropFile(index: number): void {
    const files = [...this.#file$.value];

    files.splice(index, 1);
    this.#file$.next(files);
  }

  public dropAttachment(index: number): void {
    const attachments = [...this.#attachment$.value];

    this.delete.emit(attachments[index]);

    attachments.splice(index, 1);
    this.#attachment$.next(attachments);
  }
}
