import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  #router: Router = inject(Router);

  public outletStyle$: Observable<Record<string, string>> = this.#router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(event => {
      return /auth/.test((event as RouterEvent).url)
        ? {
            height: `${window.innerHeight}px`,
          }
        : { height: `${window.innerHeight}px` };
    })
  );
}
