import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { createFeature, provideState } from '@ngrx/store';

import { AccountsFacade } from './accounts.facade';
import { AccountsResolver } from './accounts.resolver';
import { ApiModule } from './api/api.module';
import { AccountLicenseEffects } from './state/account-license.effects';
import { AccountResourcesEffects } from './state/account-resources.effects';
import { AccountEffects } from './state/account.effects';
import { ADMIN_ACCOUNTS_STORE_KEY } from './state/model';
import { accountsReducer } from './state/reducers';

export const accountsProviders: (EnvironmentProviders | Provider)[] = [
  importProvidersFrom(ApiModule),
  provideState(
    createFeature({
      name: ADMIN_ACCOUNTS_STORE_KEY,
      reducer: accountsReducer,
    })
  ),
  provideEffects([AccountEffects, AccountLicenseEffects, AccountResourcesEffects]),
  AccountsFacade,
  AccountsResolver,
];
