import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RxLet } from '@rx-angular/template/let';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Observable } from 'rxjs';

import { BlockUiFacade } from './block-ui.facade';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BlockUIModule, ProgressSpinnerModule, AsyncPipe, RxLet],
  selector: 'fizjo-pro-block-ui',
  standalone: true,
  templateUrl: 'block-ui.component.html',
})
export class BlockUiComponent {
  public readonly blockUi$: Observable<boolean>;

  constructor(private blockUiFacade: BlockUiFacade) {
    this.blockUi$ = this.blockUiFacade.blockUi$;
  }
}
