import { EntityState } from '@ngrx/entity';

import { AppointmentDto } from '../api/models/appointment-dto';

export const APPOINTMENT_FEATURE_KEY = 'appointments';
export const GENERAL_FEATURE_KEY = 'general-appointment';

export interface AppointmentFullDataDto extends AppointmentDto {
  ready?: boolean;
}

export interface AppointmentState extends EntityState<AppointmentFullDataDto> {
  ready: boolean;
}

export interface RangeDto {
  from?: string;
  to?: string;
}
