/* tslint:disable */
/* eslint-disable */
export enum ResourceType {
  Sms = 'SMS',
  Data = 'DATA',
  Users = 'USERS',
  MonthlyAppointments = 'MONTHLY_APPOINTMENTS',
  Patients = 'PATIENTS',
  Owners = 'OWNERS',
}
