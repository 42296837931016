import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';

import { DateTimeForm, dateTimeForm, mergeDates } from './date-time.form';

@Component({
  selector: 'fizjo-pro-date-time',
  standalone: true,
  imports: [CommonModule, ButtonModule, CalendarModule, FloatLabelModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './date-time.component.html',
  styleUrl: './date-time.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
  ],
})
export class DateTimeComponent implements ControlValueAccessor {
  @Input() minDate!: Date;
  @Input() maxDate!: Date;
  @Output() dateTimeChange = new EventEmitter<Date>();
  dateTimeForm: FormGroup<DateTimeForm> = dateTimeForm(this.maxDate);
  isDisabled: WritableSignal<boolean> = signal(false);

  callback!: (date: Date) => void;

  emitValue(): void {
    const date: Date = mergeDates(this.dateTimeForm.getRawValue());

    this.dateTimeChange.emit(date);
    this.callback(date);
  }
  registerOnChange(callback: (date: Date) => void): void {
    this.callback = callback;
  }

  registerOnTouched(callback: () => void): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.dateTimeForm.valueChanges.subscribe(() => callback());
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
    if (isDisabled) {
      this.dateTimeForm.controls.date.disable();
      this.dateTimeForm.controls.time.disable();
    } else {
      this.dateTimeForm.controls.date.enable();
      this.dateTimeForm.controls.time.enable();
    }
  }

  writeValue(date: Date | null): void {
    if (date) {
      this.dateTimeForm.setValue({
        date,
        time: date,
      });
    }
  }
}
