import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'fizjo-pro-internal-server-error-dialog',
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule],
  templateUrl: './internal-server-error-dialog.component.html',
  styleUrl: './internal-server-error-dialog.component.scss',
})
export class InternalServerErrorDialogComponent {
  public ref: DynamicDialogRef = inject(DynamicDialogRef);

  protected close(): void {
    this.ref.close();
  }
}
