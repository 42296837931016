import { Pipe, PipeTransform } from '@angular/core';
import { AppUsersFacade, UserDto } from '@fizjo-pro/settings/data-user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'userData',
  standalone: true,
})
export class AppUserPipe implements PipeTransform {
  constructor(private appUsersFacade: AppUsersFacade) {}

  // eslint-disable-next-line rxjs/finnish
  public transform(userId: string, field: keyof UserDto = 'lastName'): Observable<string> {
    return this.appUsersFacade
      .user$(userId)
      .pipe(map((user: UserDto | undefined) => (user ? (user[field] as string) : '')));
  }
}
