/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StatsItemDto } from '../models/stats-item-dto';

@Injectable({
  providedIn: 'root',
})
export class StatsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation statsControllerReadStats
   */
  static readonly StatsControllerReadStatsPath = '/api/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statsControllerReadStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsControllerReadStats$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<StatsItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, StatsService.StatsControllerReadStatsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StatsItemDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statsControllerReadStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsControllerReadStats(params?: { context?: HttpContext }): Observable<Array<StatsItemDto>> {
    return this.statsControllerReadStats$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatsItemDto>>) => r.body as Array<StatsItemDto>)
    );
  }
}
