import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppointmentLogDto, AppointmentLogsCountDto } from './api/models';
import { AppointmentService } from './api/services/appointment.service';

@Injectable()
export class LogsFacade {
  #api: AppointmentService = inject(AppointmentService);

  logs$(appointmentId: string): Observable<AppointmentLogDto[]> {
    return this.#api.appointmentsLogControllerRead({ appointmentId });
  }
  logsCount$(appointmentId: string): Observable<number> {
    return this.#api
      .appointmentsLogControllerCount({ appointmentId })
      .pipe(map((response: AppointmentLogsCountDto) => response.count));
  }
}
