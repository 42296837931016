import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { APP_USERS_KEY } from './app-users.model';
import { MyDataPayloadDto } from '../api/models/my-data-payload-dto';
import { PinCodePayloadDto } from '../api/models/pin-code-payload-dto';
import { UserDto } from '../api/models/user-dto';
import { UserPayloadDto } from '../api/models/user-payload-dto';

export const appUsersActions = createActionGroup({
  events: {
    create: props<{ user: UserPayloadDto }>(),
    createSuccess: props<{ user: UserDto }>(),
    fetch: emptyProps(),
    fetchSuccess: props<{ users: UserDto[] }>(),
    'patch pin code': props<{ payload: PinCodePayloadDto }>(),
    'patch pin code success': props<{ user: UserDto }>(),
    patchPassword: props<{ uid: string; password: string; email: string }>(),
    patchPasswordSuccess: emptyProps(),
    update: props<{ uid: string; payload: UserPayloadDto }>(),
    updateSuccess: props<{ user: UserDto }>(),
    saveMyData: props<{ payload: MyDataPayloadDto }>(),
    saveMyDataSuccess: props<{ user: UserDto }>(),
    requestMfaToChangeForcedPassword: emptyProps(),
    acceptTerms: emptyProps(),
    acceptTermsSuccess: props<{ user: UserDto }>(),
  },
  source: APP_USERS_KEY,
});
