import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { statsFeature } from './state/stats.feature';

export const statsProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(ApiModule),
  provideState(statsFeature),
];
