import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { appointmentActions } from '../state/appointment.actions';
import { selectAppointmentFullReady } from '../state/appointment.selectors';

@Injectable()
export class SingleAppointmentResolver extends ResolverHelper {
  constructor(store: Store<any>) {
    super(store);
  }
  public resolve(snapshot: ActivatedRouteSnapshot): Observable<unknown> {
    const appointmentId: string = snapshot.paramMap.get('appointmentId') || '';

    return this.store
      .select(selectAppointmentFullReady(appointmentId))
      .pipe(this.helpResolve(appointmentActions.fetchSingleAppointment({ appointmentId })));
  }
}
