import { Routes } from '@angular/router';
import { equipmentProviders, EquipmentResolver } from '@fizjo-pro/data-equipment';

export const equipmentRoutes: Routes = [
  {
    loadComponent: () => import('./equipment-list/equipment-list.component').then(m => m.EquipmentListComponent),
    path: '',
    providers: equipmentProviders,
    resolve: {
      equipment: EquipmentResolver,
    },
  },
  {
    loadComponent: () => import('./equipment/equipment.component').then(m => m.EquipmentComponent),
    path: 'create',
    providers: equipmentProviders,
  },
  {
    loadComponent: () => import('./equipment/equipment.component').then(m => m.EquipmentComponent),
    path: ':equipmentId/edit',
    providers: equipmentProviders,
    resolve: {
      equipment: EquipmentResolver,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];
