import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { AppUsersFacade } from './app-users.facade';
import { AppUsersResolver } from './app-users.resolver';
import { AppUsersEffects } from './state/app-users.effects';
import { APP_USERS_KEY } from './state/app-users.model';
import { appUsersReducers } from './state/app-users.reducers';

export const appUsersProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(ApiModule),
  provideState(APP_USERS_KEY, appUsersReducers),
  provideEffects([AppUsersEffects]),
  AppUsersResolver,
  AppUsersFacade,
];
