<label *ngIf="label" class="text-sm mb-1 inline-block text-gray-600">{{label}}</label>
<form [formGroup]="pinCodeGroup">
  <div formArrayName="pinCodeArray" class="flex flex-row gap-1">
    <div class="w-3rem" *ngFor="let item of pinCodeGroup.controls.pinCodeArray.controls; let index = index">
      <input
        pInputText
        [tabIndex]="index"
        #input
        (keyup)="keyUpHandler($event, index)"
        pAutoFocus
        [autofocus]="focusedIndex === index"
        class="text-center"
        [maxlength]="1"
        pKeyFilter="int"
        [formControl]="pinCodeGroup.controls.pinCodeArray.controls[index]" />
    </div>
    <button
      pButton
      icon="pi pi-times"
      [pTooltip]="'cleanPinCode' | translate"
      tooltipPosition="left"
      (click)="reset()"
      class="p-button-sm p-button-text p-button-danger"></button>
  </div>
</form>
