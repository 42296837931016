<div class="text-center">
  <lord-icon
    src="https://cdn.lordicon.com/jwdvjivv.json"
    trigger="loop"
    delay="2000"
    colors="primary:#5ff29b,secondary:#00b9b9"
    style="width: 140px; height: 140px">
  </lord-icon>
</div>
<h3>Ta strona wykorzystuje pliki cookie</h3>
<p class="text-sm text-justify">
  Klikając „Akceptuję cookies”, zgadzasz się na przechowywanie plików cookie na swoim urządzeniu w celu usprawnienia
  nawigacji w witrynie, analizy korzystania z witryny i pomocy w naszych działaniach marketingowych.
</p>
<button pButton label="Akceptuję cookies" class="w-full" (click)="doNotShow()"></button>
