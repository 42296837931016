import { AbstractControl } from '@angular/forms';
import { map, Observable } from 'rxjs';

import { cast } from '../cast';

export abstract class TypedFormFacade<
  FormModelType extends AbstractControl,
  FormReturnValueType extends { [key: string]: any },
> {
  protected initialData: FormReturnValueType | undefined;

  protected abstract formBuilder(value?: FormReturnValueType): FormModelType;

  public buildForm(initialData?: FormReturnValueType): FormModelType {
    this.initialData = initialData;

    return this.formBuilder(initialData);
  }

  public formValue$(control: FormModelType): Observable<FormReturnValueType> {
    const initialData: FormReturnValueType = this.initialData ? this.initialData : control.getRawValue();

    return control.valueChanges.pipe(
      map(formData =>
        cast<FormReturnValueType>({
          ...initialData,
          ...formData,
        })
      )
    );
  }
}
