import { inject, Injectable } from '@angular/core';
import { AccountService } from '@fizjo-pro/admin/data-accounts';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { licenseActions } from './actions';

@Injectable()
export class AccountLicenseEffects {
  #action$: Actions = inject(Actions);
  #accountService: AccountService = inject(AccountService);
  #messageService: MessageService = inject(MessageService);

  updateLicense$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(licenseActions.update),
      switchMap(({ tenantId, licenseId, payload }) =>
        this.#accountService.licenseControllerUpdateLicense({ tenantId, licenseId, body: payload })
      ),
      map(account => licenseActions.updateSuccess({ account }))
    );
  });

  updateLicenseSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(licenseActions.updateSuccess),
        map(() => {
          this.#messageService.add({ severity: 'success', summary: 'License updated' });
        })
      );
    },
    { dispatch: false }
  );

  replace$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(licenseActions.replace),
      switchMap(({ tenantId, licenseId, productCode }) =>
        this.#accountService.licenseControllerReplaceLicense({ tenantId, licenseId, body: { productCode } })
      ),
      map(account => licenseActions.replaceSuccess({ account }))
    );
  });

  replaceLicenseSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(licenseActions.replaceSuccess),
        map(() => {
          this.#messageService.add({ severity: 'success', summary: 'License replaced' });
        })
      );
    },
    { dispatch: false }
  );
}
