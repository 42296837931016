import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from './api/services/user.service';

@Injectable()
export class UserFacade {
  #destroyRef: DestroyRef = inject(DestroyRef);
  constructor(private userService: UserService) {}

  public checkEmail$(email: string): Observable<boolean> {
    return this.userService.userControllerCheckEmail({ email }).pipe(
      map(() => true),
      takeUntilDestroyed(this.#destroyRef)
    );
  }
}
