import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MessagesService } from '@fizjo-pro/data-messages';
import { RxIf } from '@rx-angular/template/if';
import { BadgeModule } from 'primeng/badge';
import { Observable } from 'rxjs';

@Component({
  imports: [CommonModule, BadgeModule, RouterLink, RxIf],
  selector: 'fizjo-pro-messages-notification',
  standalone: true,
  styleUrls: ['./messages-notification.component.scss'],
  templateUrl: './messages-notification.component.html',
})
export class MessagesNotificationComponent {
  public messagesCount$: Observable<number>;
  constructor(private messagesService: MessagesService) {
    this.messagesCount$ = this.messagesService.messagesCount$;
  }
}
