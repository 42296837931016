import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Permission } from '@fizjo-pro/data-auth';
import { MeService } from '@fizjo-pro/util-auth';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnInit {
  #templateRef: TemplateRef<any> = inject(TemplateRef);
  #viewContainer: ViewContainerRef = inject(ViewContainerRef);
  #cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  #meService: MeService = inject(MeService);
  #destroyRef = inject(DestroyRef);

  @Input() public hasPermissionElse?: TemplateRef<any>;

  @Input() public hasPermission!: Permission;

  ngOnInit() {
    this.#meService
      .hasPermission$(this.hasPermission)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(hasPermission => this.updateView(hasPermission));
  }

  private updateView(hasPermission: boolean): void {
    this.#viewContainer.clear();
    if (hasPermission) {
      this.#viewContainer.createEmbeddedView(this.#templateRef);
    } else if (this.hasPermissionElse) {
      this.#viewContainer.createEmbeddedView(this.hasPermissionElse);
    }
    this.#cdr.detectChanges();
  }
}
