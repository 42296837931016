import { Action, createReducer, on } from '@ngrx/store';

import { authActions } from './auth.actions';
import { AuthState, ScreenLockType } from './auth.model';

export const initialState: AuthState = {
  authenticated: false,
  screenLock: ScreenLockType.NONE,
  sessionTimeout: 0,
  user: null,
};

export const reducer = createReducer(
  initialState,
  on(authActions.setAuthenticated, (state: AuthState) => ({ ...state, authenticated: true })),
  on(authActions.setUnauthenticated, (state: AuthState) => ({ ...state, authenticated: false })),
  on(authActions.fetchOwnUserSuccess, (state: AuthState, action) => ({ ...state, user: action.user })),
  on(authActions.unlockScreen, (state: AuthState) => ({
    ...state,
    screenLock: ScreenLockType.NONE,
  })),
  on(authActions.lockScreen, (state: AuthState, action) => ({
    ...state,
    screenLock: action.lockType,
  })),
  on(authActions.setSessionTimeout, (state: AuthState, action) => ({
    ...state,
    sessionTimeout: action.sessionTimeout,
  })),
  on(authActions.clearState, () => initialState)
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
