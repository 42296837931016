<form class="flex flex-column gap-5" [formGroup]="passwordForm">
  <div class="p-float-label">
    <input
      type="password"
      pPassword
      class="w-full"
      promptLabel="Wprowadź hasło"
      weakLabel="Słabe"
      mediumLabel="Średnie"
      strongLabel="Mocne"
      [formControl]="passwordForm.controls.password" />
    <label>Hasło</label>
  </div>
  <div class="p-float-label">
    <input type="password" pInputText class="w-full" [formControl]="passwordForm.controls.passwordRepeat" />
    <label>Powtórz hasło</label>
  </div>
  <small
    class="p-error"
    *ngIf="passwordForm.controls.passwordRepeat.touched && passwordForm.hasError('password-mismatch')"
    >Hasło i powtórzenie hasła nie zgadzają się</small
  >
</form>
