import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AccountDto } from './api/models';
import { adminAccountsActions } from './state/actions';
import {
  selectAccounts,
  selectAccountsCount,
  selectMultiAccounts,
  selectSelected,
  selectSingleAccount,
} from './state/selectors';

@Injectable()
export class AccountsFacade {
  public accounts$!: Observable<AccountDto[]>;

  public accountsCount$!: Observable<number>;
  public selectedAccount$!: Observable<AccountDto | null>;
  public selectMulti$!: Observable<string[] | null>;
  constructor(private store: Store) {
    this.accounts$ = this.store.select(selectAccounts);
    this.selectedAccount$ = this.store.select(selectSelected);
    this.accountsCount$ = this.store.select(selectAccountsCount);
    this.selectMulti$ = this.store.select(selectMultiAccounts);
  }

  public fetchAccount(): void {
    this.store.dispatch(adminAccountsActions.read());
  }

  public selectAccount(accountId: string): void {
    this.store.dispatch(adminAccountsActions.selectAccount({ accountId }));
  }

  public selectMulti(accounts: string[]): void {
    this.store.dispatch(adminAccountsActions.selectMulti({ accounts }));
  }

  public account$(accountId: string | null): Observable<AccountDto | undefined> {
    return this.store.select(selectSingleAccount(accountId));
  }
}
