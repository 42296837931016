import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppLayoutComponent } from '@fizjo-pro/shared/ui';

@Component({
  selector: 'fizjo-pro-main',
  template: `<fizjo-pro-app-layout><router-outlet></router-outlet></fizjo-pro-app-layout>`,
  standalone: true,
  imports: [RouterOutlet, AppLayoutComponent],
})
export class MainComponent {}
