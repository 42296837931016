import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { appUsersActions } from './app-users.actions';
import { UserDto } from '../api/models/user-dto';
import { UserService } from '../api/services/user.service';

@Injectable()
export class AppUsersEffects {
  #translate: TranslateService = inject(TranslateService);
  #action$: Actions = inject(Actions);
  #userService: UserService = inject(UserService);
  #messageService: MessageService = inject(MessageService);
  #router: Router = inject(Router);
  #appDomainService: AppDomainService = inject(AppDomainService);

  public create$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appUsersActions.create),
      switchMap(action =>
        this.#userService.userControllerCreate({
          body: action.user,
          [X_TENANT_ID]: this.#appDomainService.tenantId,
        })
      ),
      map((user: UserDto) => appUsersActions.createSuccess({ user }))
    );
  });
  public read$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appUsersActions.fetch),
      switchMap(() => this.#userService.userControllerReadAll({ [X_TENANT_ID]: this.#appDomainService.tenantId })),
      map((users: UserDto[]) => appUsersActions.fetchSuccess({ users }))
    );
  });
  public update$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appUsersActions.update),
      switchMap(action =>
        this.#userService.userControllerUpdate({
          body: action.payload,
          uid: action.uid,
        })
      ),
      map((user: UserDto) => appUsersActions.updateSuccess({ user }))
    );
  });

  public updateMyData$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appUsersActions.saveMyData),
      switchMap(action => this.#userService.ownUserControllerUpdateMe({ body: action.payload })),
      map((user: UserDto) => appUsersActions.saveMyDataSuccess({ user }))
    );
  });

  public updateMyDataSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appUsersActions.saveMyDataSuccess),
        tap(() => {
          this.#messageService.add({
            detail: this.#translate.instant('settings.user.updateMyData.detail'),
            severity: 'success',
            summary: this.#translate.instant('settings.user.updateUserSuccess.summary'),
          });
          this.#router.navigate(['/settings/app-users']);
        })
      );
    },
    { dispatch: false }
  );

  public updateSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appUsersActions.updateSuccess),
        tap(action => {
          this.#messageService.add({
            detail: this.#translate.instant('settings.user.updateUserSuccess.detail', action.user),
            severity: 'success',
            summary: this.#translate.instant('settings.user.updateUserSuccess.summary'),
          });
          this.#router.navigate(['/settings/app-users']);
        })
      );
    },
    { dispatch: false }
  );
  public createSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appUsersActions.createSuccess),
        tap(action => {
          this.#messageService.add({
            detail: `Pomyślnie zapisano dane użytkownika ${action.user.firstName} ${action.user.lastName}`,
            severity: 'success',
            summary: 'Użytkownik aplikacji',
          });

          this.#router.navigate(['/settings/app-users']);
        })
      );
    },
    { dispatch: false }
  );
  public patchPassword$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appUsersActions.patchPassword),
      switchMap(action =>
        this.#userService.ownUserControllerChangePassword({
          body: { password: action.password },
        })
      ),
      map(() => appUsersActions.patchPasswordSuccess())
    );
  });
  public patchPasswordSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appUsersActions.patchPasswordSuccess),
        switchMap(() => this.#translate.get(['user.changePasswordSuccess', 'user.changePasswordSubject'])),
        tap((translate: Record<string, string>) => {
          this.#messageService.add({
            detail: translate['user.changePasswordSuccess'],
            severity: 'success',
            summary: translate['user.changePasswordSubject'],
          });
        })
      );
    },
    { dispatch: false }
  );
  public patchPinCode$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appUsersActions.patchPinCode),
      switchMap(action =>
        this.#userService.ownUserControllerUpdatePinCode({
          body: action.payload,
        })
      ),
      map((user: UserDto) => appUsersActions.patchPinCodeSuccess({ user }))
    );
  });
  public patchPinCodeSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appUsersActions.patchPinCodeSuccess),
        switchMap(() => this.#translate.get(['user.pinCode', 'user.pinCodePatchSuccess'])),
        tap((translate: Record<string, string>) => {
          this.#messageService.add({
            detail: translate['user.pinCodePatchSuccess'],
            severity: 'success',
            summary: translate['user.pinCode'],
          });
        })
      );
    },
    { dispatch: false }
  );

  public requestMfaToChangeForcedPassword$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appUsersActions.requestMfaToChangeForcedPassword),
        switchMap(() => this.#userService.ownUserControllerRequestMfa())
      );
    },
    { dispatch: false }
  );

  public acceptTerms$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appUsersActions.acceptTerms),
      switchMap(() => this.#userService.ownUserControllerAcceptTerms()),
      map((user: UserDto) => appUsersActions.acceptTermsSuccess({ user }))
    );
  });

  public redirectAfterAcceptTermsSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appUsersActions.acceptTermsSuccess),
        tap(() => void this.#router.navigate(['/']))
      );
    },
    { dispatch: false }
  );
}
