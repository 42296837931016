import { ActivatedRouteSnapshot } from '@angular/router';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { fetchOwners } from './state/owner.actions';
import { selectOwnerDataReady } from './state/owner.selectors';

export class SingleOwnerResolver extends ResolverHelper {
  constructor(store: Store) {
    super(store);
  }

  override resolve(snapshot: ActivatedRouteSnapshot): Observable<any> {
    return this.store
      .select(selectOwnerDataReady(snapshot.paramMap.get('ownerId')))
      .pipe(this.helpResolve(fetchOwners()));
  }
}
