import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { helpResolve } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';

import { CalendarActions } from './state/calendar.actions';
import { selectReady } from './state/calendar.selectors';

export const calendarResolver: ResolveFn<any> = (route, state, store: Store = inject(Store)) =>
  store.select(selectReady).pipe(
    helpResolve(
      store,
      CalendarActions.fetch({
        end: DateTime.now().endOf('month').toISODate() || '',
        start: DateTime.now().startOf('month').toISODate() || '',
      })
    )
  );
