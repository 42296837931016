import { effect, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { MessageService } from 'primeng/api';

@Injectable()
export class LocalCacheService<T> {
  #cache: WritableSignal<T | null> = signal(null);
  #cookies: CookieService = inject(CookieService);
  #messageService: MessageService = inject(MessageService);
  #translateService: TranslateService = inject(TranslateService);

  static KEY = 'fizjoPro:appointmentFormCache';

  cache = this.#cache.asReadonly();

  constructor() {
    const cacheContent: string | null = localStorage.getItem(LocalCacheService.KEY);

    if (cacheContent && JSON.parse(cacheContent)) {
      this.#cache.set(JSON.parse(cacheContent));
    }

    effect(() => {
      if (this.#cache() !== null) {
        localStorage.setItem(LocalCacheService.KEY, JSON.stringify(this.cache()));
      }
    });
  }

  setCache(data: T | undefined): void {
    if (data) {
      this.#cache.set(data);
    }
  }

  clearCache(): void {
    this.#cache.set(null);
    localStorage.removeItem(LocalCacheService.KEY);
  }

  hasContent(): boolean {
    return this.#cache() !== null;
  }

  toggleCacheFor(templateId: string): void {
    const cookie: string | undefined = this.#cookies.get(LocalCacheService.KEY);

    if (cookie) {
      const cache: string[] = JSON.parse(cookie);
      const index: number = cache.indexOf(templateId);

      if (index > -1) {
        cache.splice(index, 1);
      } else {
        cache.push(templateId);
      }

      this.#cookies.put(LocalCacheService.KEY, JSON.stringify(cache));
    } else {
      this.#cookies.put(LocalCacheService.KEY, JSON.stringify([templateId]));
    }

    this.#messageService.add({
      severity: 'success',
      detail: this.#translateService.instant(
        this.isEnabledFor(templateId) ? 'appointment.cache.enabledDetail' : 'appointment.cache.disabledDetail'
      ),
    });
  }

  isEnabledFor(templateId: string | undefined): boolean {
    const cookie: string | undefined = this.#cookies.get(LocalCacheService.KEY);

    return cookie ? JSON.parse(cookie).includes(templateId) : false;
  }
}
