import { clearState } from '@fizjo-pro/data-auth';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as actions from './owner.actions';
import { OwnerState } from './owner.models';
import { OwnerDto } from '../api/models';

export const ownerAdapter: EntityAdapter<OwnerDto> = createEntityAdapter<OwnerDto>({
  selectId: (owner: OwnerDto) => owner._id,
});

export const initialOwnerState: OwnerState = {
  ...ownerAdapter.getInitialState(),
  ready: false,
};

const reducer = createReducer(
  initialOwnerState,
  on(actions.createOwnerSuccess, (state: OwnerState, action) => ({
    ...ownerAdapter.upsertOne(action.owner, state),
    ready: true,
  })),
  on(actions.updateOwnerSuccess, (state: OwnerState, action) => ({
    ...ownerAdapter.upsertOne(action.owner, state),
    ready: true,
  })),
  on(actions.fetchOwnersSuccess, (state: OwnerState, action) => ({
    ...ownerAdapter.upsertMany(action.owners, state),
    ready: true,
  })),
  on(actions.fetchOwnerDataSuccess, (state: OwnerState, action) => ({
    ...ownerAdapter.upsertOne(action.owner, state),
    ready: true,
  })),
  on(clearState, () => initialOwnerState)
);

export function ownerReducer(state: OwnerState | undefined, action: Action): OwnerState {
  return reducer(state, action);
}
