import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

import { CalendarEventDto } from './api/models/calendar-event-dto';
import { CalendarEventPayloadDto } from './api/models/calendar-event-payload-dto';
import { CalendarActions } from './state/calendar.actions';
import { CalendarEventsRange } from './state/calendar.model';
import { selectCalendarEvents, selectEvent } from './state/calendar.selectors';

@Injectable({ providedIn: 'root' })
export class CalendarFacade {
  public calendarEvents$!: Observable<CalendarEventDto[]>;

  constructor(private store: Store) {
    this.calendarEvents$ = this.store.select(selectCalendarEvents);
  }

  public createEvent(payload: CalendarEventPayloadDto): void {
    this.store.dispatch(CalendarActions.create({ payload }));
  }

  public fetchEvents(range?: CalendarEventsRange): void {
    const dt: DateTime = DateTime.now();
    const _range: CalendarEventsRange = range || {
      end: dt.endOf('month').toISO() || '',
      start: dt.startOf('month').toISO() || '',
    };

    this.store.dispatch(CalendarActions.fetch(_range));
  }

  public updateEvent(eventId: string, payload: CalendarEventPayloadDto): void {
    this.store.dispatch(CalendarActions.update({ eventId, payload }));
  }

  public deleteEvent(eventId: string): void {
    this.store.dispatch(CalendarActions.delete({ eventId }));
  }

  public selectEvent$(eventId: string): Observable<CalendarEventDto | undefined> {
    return this.store.select(selectEvent(eventId));
  }
}
