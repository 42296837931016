import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FormTemplateDto } from './api/models/form-template-dto';
import { FormTemplatePayloadDto } from './api/models/form-template-payload-dto';
import { FormTemplateStatsDto } from './api/models/form-template-stats-dto';
import { FormTemplateService } from './api/services/form-template.service';
import * as Actions from './state/form-template.actions';
import { formTemplateActions } from './state/form-template.actions';
import { FormTemplateFilter } from './state/form-template.model';
import * as Selectors from './state/form-template.selectors';
import { selectTemplateFor } from './state/form-template.selectors';

@Injectable()
export class FormTemplateFacade {
  public templates$!: Observable<FormTemplateDto[]>;
  public filter$!: Observable<FormTemplateFilter>;
  public activeOnly$!: Observable<FormTemplateDto[]>;
  public ready$!: Observable<boolean>;

  constructor(
    private store: Store,
    private formTemplateService: FormTemplateService
  ) {
    this.templates$ = this.store.select(Selectors.selectTemplates);
    this.activeOnly$ = this.store.select(Selectors.selectActiveOnly);
    this.filter$ = this.store.select(Selectors.selectFilterValue);
    this.ready$ = this.store.select(Selectors.selectReady);
  }

  public create(payload: FormTemplatePayloadDto): void {
    this.store.dispatch(formTemplateActions.create({ payload }));
  }

  public getById$(templateId: string): Observable<FormTemplateDto | undefined> {
    return this.store.select(Selectors.selectById(templateId));
  }

  public update(templateId: string, payload: FormTemplatePayloadDto): void {
    return this.store.dispatch(Actions.update({ payload, templateId }));
  }

  public hideTemplate(templateId: string): void {
    this.store.dispatch(Actions.hideTemplate({ templateId }));
  }

  public restoreTemplate(templateId: string): void {
    this.store.dispatch(Actions.restoreTemplate({ templateId }));
  }

  public deleteTemplate(templateId: string): void {
    this.store.dispatch(formTemplateActions.delete({ templateId }));
  }

  public setSelectionFor(templateId: string, selectedFor: 'GENERAL' | 'CONSULTATION' | null): void {
    this.store.dispatch(Actions.setSelection({ selectedFor, templateId }));
  }

  public selectTemplateFor$(selectFor: 'GENERAL' | 'CONSULTATION'): Observable<FormTemplateDto | undefined> {
    return this.store.select(selectTemplateFor(selectFor));
  }

  public count$(): Observable<FormTemplateStatsDto[]> {
    return this.formTemplateService.formTemplateControllerStats();
  }
}
