/* tslint:disable */
/* eslint-disable */
export enum ProductCode {
  Trial = 'TRIAL',
  Base = 'BASE',
  OneUser = 'ONE_USER',
  Team = 'TEAM',
  Premium = 'PREMIUM',
  Finances = 'FINANCES',
  CustomerApp = 'CUSTOMER_APP',
  Attachments = 'ATTACHMENTS',
  Sms = 'SMS',
}
