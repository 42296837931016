/* tslint:disable */
/* eslint-disable */
export enum Permission {
  Mentor = 'MENTOR',
  ProcedureTemplatesEditor = 'PROCEDURE_TEMPLATES_EDITOR',
  EquipmentEditor = 'EQUIPMENT_EDITOR',
  FormTemplateEditor = 'FORM_TEMPLATE_EDITOR',
  CompanyDataEditor = 'COMPANY_DATA_EDITOR',
  UserEditor = 'USER_EDITOR',
  AppointmentEditor = 'APPOINTMENT_EDITOR',
  AppointmentAdmin = 'APPOINTMENT_ADMIN',
}
