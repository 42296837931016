import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Permission, Role } from '@fizjo-pro/data-auth';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MeService } from './me.service';

@Injectable()
export class RoleGuard {
  constructor(private meService: MeService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permission: Permission = route.routeConfig?.data?.['permission'];

    return combineLatest([this.meService.role$, this.meService.permissions$]).pipe(
      map(([role, permissions]) => role === Role.Owner || permissions.includes(permission))
    );
  }
}
