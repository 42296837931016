import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { createFeature, provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { GdprFacade } from './gdpr.facade';
import { GdprResolver } from './gdpr.resolver';
import { GdprEffects } from './state/gdpr.effects';
import { GDPR_DATA_STORE_KEY } from './state/gdpr.model';
import { gdprReducers } from './state/gdpr.reducers';

export const gdprProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(ApiModule),
  provideState(
    createFeature({
      name: GDPR_DATA_STORE_KEY,
      reducer: gdprReducers,
    })
  ),
  provideEffects([GdprEffects]),
  GdprResolver,
  GdprFacade,
];
