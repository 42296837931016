import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { EquipmentFacade } from './equipment.facade';
import { EquipmentResolver } from './equipment.resolver';
import { EquipmentEffects } from './state/equipment.effects';
import { EQUIPMENT_FEATURE_KEY } from './state/equipment.model';
import { equipmentReducer } from './state/equipment.reducer';

export const equipmentProviders: (Provider | EnvironmentProviders)[] = [
  provideState(EQUIPMENT_FEATURE_KEY, equipmentReducer),
  provideEffects([EquipmentEffects]),
  EquipmentFacade,
  EquipmentResolver,
  importProvidersFrom(ApiModule),
];
