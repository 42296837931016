import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { createFeature, provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { AppointmentFacade } from './appointment.facade';
import { LogsFacade } from './logs.facade';
import { AppointmentResolver, PatientAppointmentsResolver, SingleAppointmentResolver } from './resolvers';
import { APPOINTMENT_FEATURE_KEY } from './state/appointment.models';
import { appointmentReducer } from './state/appointment.reducer';
import { AppointmentEffects } from './state/effects/appointment.effects';
import { GeneralEffects } from './state/effects/general.effects';
import { ProcedureEffects } from './state/effects/procedure.effects';

export const appointmentProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(ApiModule),
  provideState(
    createFeature({
      name: APPOINTMENT_FEATURE_KEY,
      reducer: appointmentReducer,
    })
  ),
  provideEffects([AppointmentEffects, ProcedureEffects, GeneralEffects]),
  AppointmentFacade,
  LogsFacade,
  AppointmentResolver,
  SingleAppointmentResolver,
  PatientAppointmentsResolver,
];
