/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CeigdCompanyDto } from '../models/ceigd-company-dto';
import { StripeBillingDataDto } from '../models/stripe-billing-data-dto';
import { StripeCustomerDto } from '../models/stripe-customer-dto';

@Injectable({
  providedIn: 'root',
})
export class BillingDataService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation billingDataControllerReadFromCeigd
   */
  static readonly BillingDataControllerReadFromCeigdPath = '/api/billing-data/vat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDataControllerReadFromCeigd()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDataControllerReadFromCeigd$Response(params: {
    /**
     * NIP
     */
    nip: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<CeigdCompanyDto>> {
    const rb = new RequestBuilder(this.rootUrl, BillingDataService.BillingDataControllerReadFromCeigdPath, 'get');
    if (params) {
      rb.query('nip', params.nip, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CeigdCompanyDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDataControllerReadFromCeigd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDataControllerReadFromCeigd(params: {
    /**
     * NIP
     */
    nip: string;
    context?: HttpContext;
  }): Observable<CeigdCompanyDto> {
    return this.billingDataControllerReadFromCeigd$Response(params).pipe(
      map((r: StrictHttpResponse<CeigdCompanyDto>) => r.body as CeigdCompanyDto)
    );
  }

  /**
   * Path part for operation billingDataControllerRead
   */
  static readonly BillingDataControllerReadPath = '/api/billing-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDataControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDataControllerRead$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<StripeCustomerDto>> {
    const rb = new RequestBuilder(this.rootUrl, BillingDataService.BillingDataControllerReadPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StripeCustomerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDataControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDataControllerRead(params?: { context?: HttpContext }): Observable<StripeCustomerDto> {
    return this.billingDataControllerRead$Response(params).pipe(
      map((r: StrictHttpResponse<StripeCustomerDto>) => r.body as StripeCustomerDto)
    );
  }

  /**
   * Path part for operation billingDataControllerUpdate
   */
  static readonly BillingDataControllerUpdatePath = '/api/billing-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDataControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  billingDataControllerUpdate$Response(params: {
    context?: HttpContext;
    body: StripeBillingDataDto;
  }): Observable<StrictHttpResponse<StripeCustomerDto>> {
    const rb = new RequestBuilder(this.rootUrl, BillingDataService.BillingDataControllerUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StripeCustomerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDataControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  billingDataControllerUpdate(params: {
    context?: HttpContext;
    body: StripeBillingDataDto;
  }): Observable<StripeCustomerDto> {
    return this.billingDataControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<StripeCustomerDto>) => r.body as StripeCustomerDto)
    );
  }
}
