<ng-container *ngIf="me$ | async as userDto">
  <ng-container *ngIf="menuItems$ | async as menuItems">
    <fizjo-pro-app-user-avatar size="large" [userDto]="userDto" (click)="op.toggle($event)">
    </fizjo-pro-app-user-avatar>
    <p-overlayPanel #op styleClass="switch-app-user">
      <ng-template pTemplate="content">
        <p-menu [model]="menuItems"></p-menu>
      </ng-template>
    </p-overlayPanel>
  </ng-container>
</ng-container>
