import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BILLING_DATA_STORE_KEY } from './billing-data.model';
import { StripeBillingDataDto } from '../api/models/stripe-billing-data-dto';
import { StripeCustomerDto } from '../api/models/stripe-customer-dto';

export const billingDataActions = createActionGroup({
  events: {
    read: emptyProps(),
    'read success': props<{ stripeCustomer: StripeCustomerDto }>(),
    update: props<{ payload: StripeBillingDataDto }>(),
    'update success': props<{ stripeCustomer: StripeCustomerDto }>(),
  },
  source: BILLING_DATA_STORE_KEY,
});
