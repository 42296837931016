import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface LoginForm {
  password: FormControl<string>;
  email: FormControl<string>;
}

export function loginFormFactory(): FormGroup<LoginForm> {
  return new FormGroup<LoginForm>({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(8), Validators.maxLength(26)],
    }),
  });
}
