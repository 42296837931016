import { HttpContextToken, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';
import { X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

export const CONTEXT_KEY = new InjectionToken<string>('CONTEXT_KEY ', {
  providedIn: 'root',
  factory: (): string => 'jwt',
});

export const SHOULD_CHECK_TENANT_ID = new InjectionToken<boolean>('SHOULD_CHECK_TENANT_ID ', {
  providedIn: 'root',
  factory: (): boolean => true,
});

function httpHeaders<T>(request: HttpRequest<T>, token: string, tenantId?: string): HttpRequest<T> {
  const headers: Record<string, string> = {};

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  if (tenantId) {
    headers[X_TENANT_ID] = tenantId;
  }

  return request.clone({ setHeaders: headers });
}

export const bearerTokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  cookies: CookieService = inject(CookieService),
  contextKey: string = inject(CONTEXT_KEY)
): Observable<HttpEvent<any>> => {
  const tokenKey: string = request.context.get(new HttpContextToken<string>(() => contextKey));
  const token: string | undefined = cookies.get(tokenKey);
  const tenantId: string | undefined = cookies.get(X_TENANT_ID);
  const clonedRequest: HttpRequest<any> = httpHeaders(request, token || '', tenantId);

  return next(clonedRequest);
};
