import { EntityState } from '@ngrx/entity';

import { EquipmentDto } from '../api/models';

export const EQUIPMENT_FEATURE_KEY = 'equipment';

export enum EquipmentFilter {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ALL = 'ALL',
}

export interface EquipmentState extends EntityState<EquipmentDto> {
  ready: boolean;
  filter: EquipmentFilter;
}
