import { createActionGroup, createFeature, createReducer, emptyProps, on } from '@ngrx/store';

export interface BlockUiState {
  blockUi: boolean;
}

export const initialState: BlockUiState = {
  blockUi: false,
};
export const blockUiAction = createActionGroup({
  source: 'block-ui',
  events: {
    'block ui': emptyProps(),
    'remove block': emptyProps(),
  },
});
export const BlockUiFeature = createFeature({
  name: 'block-ui',
  reducer: createReducer(
    initialState,
    on(blockUiAction.blockUi, (state: BlockUiState) => ({ ...state, blockUi: true })),
    on(blockUiAction.removeBlock, (state: BlockUiState) => ({ ...state, blockUi: false }))
  ),
});
export const { name, selectBlockUi } = BlockUiFeature;
