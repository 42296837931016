import { EntityState } from '@ngrx/entity';

import { AccountDto } from '../api/models';

export const ADMIN_ACCOUNTS_STORE_KEY = 'admin-accounts';

export interface AdminAccountsState {
  accounts: EntityState<AccountDto>;
  ready: boolean;
  selectedAccount: string | null;
  multiSelect: string[] | null;
}
