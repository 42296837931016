import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { billingDataActions } from './billing-data.actions';
import { BillingDataState } from './billing-data.model';
import { StripeCustomerDto } from '../api/models/stripe-customer-dto';

export const initialState: BillingDataState = {
  stripeCustomer: null,
  ready: false,
};
export const billingDataFeature = createFeature({
  name: 'billing-data',
  extraSelectors: ({ selectStripeCustomer }) => ({
    selectHasVat: createSelector(
      selectStripeCustomer,
      (stripeCustomer: StripeCustomerDto | null) => !!stripeCustomer?.tax_ids && stripeCustomer?.tax_ids?.length > 0
    ),
    selectHasBillingAddress: createSelector(
      selectStripeCustomer,
      (stripeCustomer: StripeCustomerDto | null) =>
        !!stripeCustomer && !!stripeCustomer.address && !!stripeCustomer.address.line1
    ),
  }),
  reducer: createReducer(
    initialState,
    on(billingDataActions.readSuccess, billingDataActions.updateSuccess, (state: BillingDataState, action) => ({
      stripeCustomer: action.stripeCustomer,
      ready: true,
    }))
  ),
});

export const { selectReady, selectStripeCustomer, selectHasVat, selectHasBillingAddress } = billingDataFeature;
