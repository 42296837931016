import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { DateTime } from 'luxon';

export interface TimeRangeForm {
  date: FormControl<Date>;
  from: FormControl<Date>;
  to: FormControl<Date>;
}
export interface DateRange {
  from: Date;
  to: Date;
}
export function rangeValidator(control: AbstractControl): ValidationErrors | null {
  const { from, to }: DateRange = control.getRawValue();

  if (DateTime.fromJSDate(from) >= DateTime.fromJSDate(to)) {
    return {
      incorrectDateRange: true,
    };
  }

  return null;
}

export function timeRangeForm(): FormGroup<TimeRangeForm> {
  let dt: DateTime = DateTime.now();

  dt = dt.set({ minute: Math.ceil((dt.minute + 1) / 10) * 10 });

  return new FormGroup<TimeRangeForm>(
    {
      date: new FormControl<Date>(dt.startOf('day').toJSDate(), { nonNullable: true }),
      from: new FormControl<Date>(dt.startOf('minute').toJSDate(), { nonNullable: true }),
      to: new FormControl<Date>(dt.startOf('minute').plus({ hour: 1, minute: 30 }).toJSDate(), { nonNullable: true }),
    },
    { validators: [rangeValidator] }
  );
}
