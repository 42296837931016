import { CommonModule } from '@angular/common';
import { Component, forwardRef, inject, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { filter, map, Observable } from 'rxjs';

@Component({
  selector: 'fizjo-pro-phone-number',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FloatLabelModule,
    DropdownModule,
    TranslateModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
  ],
  templateUrl: './phone-number.component.html',
  styleUrl: './phone-number.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
  ],
})
export class PhoneNumberComponent implements OnInit, ControlValueAccessor {
  #translateService: TranslateService = inject(TranslateService);
  #phoneNumber$!: Observable<string>;

  @Input() public label!: string;

  public phoneNumberForm: FormGroup = new FormGroup({
    countryCode: new FormControl('pl', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
  });
  public countryCodes: { label: string; value: string }[] = [
    { label: this.#translateService.instant('countries.PL'), value: '48' },
  ];

  ngOnInit() {
    this.#phoneNumber$ = this.phoneNumberForm.valueChanges.pipe(
      filter(() => this.phoneNumberForm.valid),
      map(({ countryCode, phoneNumber }) => `+${countryCode} ${phoneNumber}`)
    );
  }

  registerOnChange(callback: (phoneNumber: string) => void): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.#phoneNumber$.subscribe(callback);
  }

  registerOnTouched(callback: () => void): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.#phoneNumber$.subscribe(callback);
  }

  setDisabledState(isDisabled: boolean): void {
    const countryCode = this.phoneNumberForm.get('countryCode');
    const phoneNumber = this.phoneNumberForm.get('phoneNumber');

    if (isDisabled) {
      countryCode?.disable();
      phoneNumber?.disable();
    } else {
      countryCode?.enable();
      phoneNumber?.enable();
    }
  }

  writeValue(phoneNumberValue: string): void {
    if (phoneNumberValue && phoneNumberValue.split(' ').length === 2) {
      const [countryCode, phoneNumber] = phoneNumberValue.split(' ');

      this.phoneNumberForm.setValue({ countryCode, phoneNumber }, { emitEvent: false });
    }
    const phoneNumberValueDigitsOnly = phoneNumberValue.replace(/\+\d{2}\s{1}\d{9}/, '');

    if (phoneNumberValueDigitsOnly.length >= 11) {
      const countryCode = phoneNumberValueDigitsOnly.substring(0, 2);
      const phoneNumber = phoneNumberValueDigitsOnly.substring(2, phoneNumberValueDigitsOnly.length);

      this.phoneNumberForm.setValue({ countryCode, phoneNumber }, { emitEvent: false });
    } else {
      this.phoneNumberForm.setValue({ countryCode: '48', phoneNumber: null }, { emitEvent: false });
    }
  }
}
