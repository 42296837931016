import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'lib-premium-dialog',
  standalone: true,
  imports: [TranslateModule, CommonModule, ButtonModule, DynamicDialogModule],
  templateUrl: './premium-dialog.component.html',
  styleUrl: './premium-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumDialogComponent {
  #dynamicDialogRef: DynamicDialogRef = inject(DynamicDialogRef);

  close(): void {
    this.#dynamicDialogRef.close();
  }

  navigate(): void {
    window.location.href = 'https://calendar.app.google/1AyUXVW4PAMro1Am8';
  }
}
