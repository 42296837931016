import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { appConfigActions } from './state/app-config.actions';
import { selectReady } from './state/app-config.selectors';

@Injectable()
export class AppConfigResolver extends ResolverHelper {
  constructor(store: Store) {
    super(store);
  }

  override resolve(): Observable<any> {
    return this.store.select(selectReady).pipe(this.helpResolve(appConfigActions.fetchAppConfig()));
  }
}
