<button
  pButton
  (click)="isVisible = !isVisible"
  icon="pi pi-plus"
  class="p-button-text p-button-primary"
  [label]="'procedureTemplate.addNew' | translate"></button>

<p-dialog
  [(visible)]="isVisible"
  [style]="{ minWidth: '480px' }"
  [draggable]="true"
  [header]="'procedureTemplate.selectTemplate' | translate">
  <ng-container *ngIf="template$ | async as templates">
    <p-table
      [value]="templates"
      selectionMode="single"
      (selectionChange)="selectHandler($event)"
      sortField="type"
      sortMode="single"
      dataKey="type"
      rowGroupMode="subheader"
      groupRowsBy="type">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name" colspan="2" translate>
            procedureTemplate.name
            <p-sortIcon field="name"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupheader" let-template let-rowIndex="rowIndex" let-expanded="expanded">
        <tr>
          <td class="flex flex-row align-items-center" colspan="2">
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="template"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            <span class="font-bold ml-4">{{ 'procedureTemplate.' + template.type | translate }}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-rowData let-template>
        <tr [pSelectableRow]="rowData">
          <td>{{ template.name }}</td>
          <td>{{ template.bookTime }} min</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-template pTemplate="footer">
    <div class="flex flex-row justify-content-center">
      <button pButton [label]="'close' | translate" class="p-button-text" (click)="isVisible = false"></button>
    </div>
  </ng-template>
</p-dialog>
