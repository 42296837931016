import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import { OWNER_FEATURE_KEY } from './owner.models';
import { MessageChannel, OwnerDto, OwnerPayloadDto, OwnerUpdateDto } from '../api/models';

export const createOwner = createAction(`[${OWNER_FEATURE_KEY}] create owner`, props<{ payload: OwnerPayloadDto }>());

export const createOwnerWithAnimal = createAction(
  `[${OWNER_FEATURE_KEY}] create owner with animal`,
  props<{ payload: OwnerPayloadDto }>()
);

export const createOwnerWithAnimalSuccess = createAction(
  `[${OWNER_FEATURE_KEY}] create owner with animal success`,
  props<{ owner: OwnerDto }>()
);

export const fetchOwnerData = createAction(`[${OWNER_FEATURE_KEY}] fetch owner's data`, props<{ ownerId: string }>());

export const fetchOwnerDataSuccess = createAction(
  `[${OWNER_FEATURE_KEY}] fetch owner's data success`,
  props<{ owner: OwnerDto }>()
);

export const createOwnerSuccess = createAction(
  `[${OWNER_FEATURE_KEY}] create owner success`,
  props<{ owner: OwnerDto; path?: string[] }>()
);

export const fetchOwners = createAction(`[${OWNER_FEATURE_KEY}] fetch owners list`);

export const fetchOwnersSuccess = createAction(
  `[${OWNER_FEATURE_KEY}] fetch owners list success`,
  props<{ owners: OwnerDto[] }>()
);

export const updateOwner = createAction(
  `[${OWNER_FEATURE_KEY}] update owner data`,
  props<{ ownerId: string; payload: OwnerUpdateDto }>()
);
export const updateOwnerSuccess = createAction(
  `[${OWNER_FEATURE_KEY}] update owner success`,
  props<{ owner: OwnerDto; path?: string[] }>()
);

export const ownerActions = createActionGroup({
  events: {
    createInvitation: props<{ ownerId: string; channel: MessageChannel }>(),
    createInvitationSuccess: emptyProps(),
    sendGdprRequest: props<{ ownerId: string }>(),
    sendGdprRequestSuccess: emptyProps(),
    sendMessage: props<{ ownerId: string; content: string; channel: MessageChannel }>(),
    sendMessageSuccess: emptyProps(),
  },
  source: OWNER_FEATURE_KEY,
});
