import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AddressDto } from '../../api/models';

export interface AddressForm {
  street: FormControl<string>;
  streetSndLine: FormControl<string>;
  city: FormControl<string>;
  postalCode: FormControl<string>;
  type: FormControl<string>;
  _id: FormControl<string | null>;
}

export function addressFormFactory(payload?: AddressDto): FormGroup<AddressForm> {
  return new FormGroup<AddressForm>({
    _id: new FormControl<string | null>(null),
    city: new FormControl<string>(payload?.city || '', {
      nonNullable: true,
      validators: Validators.required,
    }),
    postalCode: new FormControl<string>(payload?.postalCode || '', {
      nonNullable: true,
      validators: Validators.required,
    }),
    street: new FormControl<string>(payload?.street || '', {
      nonNullable: true,
      validators: Validators.required,
    }),
    streetSndLine: new FormControl<string>(payload?.streetSndLine || '', {
      nonNullable: true,
    }),
    type: new FormControl<'home' | 'office' | 'other'>(payload?.type || 'other', { nonNullable: true }),
  });
}
