import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum DataViewLayout {
  LIST = 'list',
  GRID = 'grid',
}

@Injectable({ providedIn: 'root' })
export class ListGridService {
  #cookies: CookieService = inject(CookieService);
  #breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  public layout$(key: string): Observable<DataViewLayout> {
    return this.#breakpointObserver
      .observe('(max-width: 767px)')
      .pipe(
        map((breakpointState: BreakpointState) =>
          breakpointState.matches
            ? DataViewLayout.GRID
            : ((this.#cookies.get(key) || DataViewLayout.LIST) as DataViewLayout)
        )
      );
  }

  public changeLayoutHandler(layout: DataViewLayout, key: string): void {
    this.#cookies.put(key, layout);
  }
}
