import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { defineElement } from 'lord-icon-element';
import lottie from 'lottie-web';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

defineElement(lottie.loadAnimation);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'kf-cookies-info',
  standalone: true,
  templateUrl: 'cookies-info.component.html',
  imports: [ButtonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CookiesInfoComponent {
  #cookies: CookieService = inject(CookieService);
  public ref: DynamicDialogRef = inject(DynamicDialogRef);

  public doNotShow(): void {
    this.#cookies.put('fizjoPro:acceptCookies', 'true', {
      expires: DateTime.now().plus({ year: 1 }).toJSDate(),
    });
    this.ref.close();
  }
}
