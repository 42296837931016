import { Routes } from '@angular/router';
import { Permission } from '@fizjo-pro/data-auth';
import { equipmentProviders, EquipmentResolver } from '@fizjo-pro/data-equipment';
import { ProcedureTemplateResolver, templatesProviders } from '@fizjo-pro/data-procedure-template';
import { RoleGuard } from '@fizjo-pro/util-auth';

export const templateRoutes: Routes = [
  {
    loadComponent: () =>
      import('./components/procedure-template/procedure-template.component').then(m => m.ProcedureTemplateComponent),
    path: 'create',
    canActivate: [RoleGuard],
    data: {
      permission: Permission.ProcedureTemplatesEditor,
    },
    providers: [...equipmentProviders, ...templatesProviders],
    resolve: {
      equipment: EquipmentResolver,
    },
  },
  {
    loadComponent: () =>
      import('./components/procedure-template/procedure-template.component').then(m => m.ProcedureTemplateComponent),
    path: ':templateId/edit',
    canActivate: [RoleGuard],
    data: {
      permission: Permission.ProcedureTemplatesEditor,
    },
    providers: [...equipmentProviders, ...templatesProviders],
    resolve: {
      equipment: EquipmentResolver,
      templates: ProcedureTemplateResolver,
    },
  },
  {
    loadComponent: () =>
      import('./components/templates-list/templates-list.component').then(m => m.TemplatesListComponent),
    path: '',
    providers: [...equipmentProviders, ...templatesProviders],
    resolve: {
      equipment: EquipmentResolver,
      templates: ProcedureTemplateResolver,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];
