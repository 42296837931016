import { clearState } from '@fizjo-pro/data-auth';
import { createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as ProcedureTemplateAction from './procedure-template.actions';
import { ProcedureTemplateFilter, ProcedureTemplateState } from './procedure-template.model';
import { ProcedureTemplateDto } from '../api/models/procedure-template-dto';

export const procedureTemplateAdapter = createEntityAdapter<ProcedureTemplateDto>({
  selectId: (template: ProcedureTemplateDto) => template._id,
});

export const procedureTemplateInitialState: ProcedureTemplateState = {
  ...procedureTemplateAdapter.getInitialState(),
  filter: ProcedureTemplateFilter.ALL,
  ready: false,
};

const reducer = createReducer(
  procedureTemplateInitialState,
  on(ProcedureTemplateAction.createSuccess, (state: ProcedureTemplateState, action) => ({
    ...procedureTemplateAdapter.upsertOne(action.procedureTemplate, state),
    ready: true,
  })),
  on(ProcedureTemplateAction.fetchListSuccess, (state: ProcedureTemplateState, action) => ({
    ...procedureTemplateAdapter.addMany(action.templates, state),
    ready: true,
  })),
  on(ProcedureTemplateAction.updateSuccess, (state: ProcedureTemplateState, action) => {
    const update: Update<ProcedureTemplateDto> = {
      changes: action.procedureTemplate,
      id: action.procedureTemplate._id,
    };

    return procedureTemplateAdapter.updateOne<ProcedureTemplateState>(update, state);
  }),
  on(ProcedureTemplateAction.deleteTemplateSuccess, (state: ProcedureTemplateState, action) => {
    const update: Update<ProcedureTemplateDto> = {
      changes: action.template,
      id: action.template._id,
    };

    return procedureTemplateAdapter.updateOne<ProcedureTemplateState>(update, state);
  }),
  on(ProcedureTemplateAction.restoreTemplateSuccess, (state: ProcedureTemplateState, action) => {
    const update: Update<ProcedureTemplateDto> = {
      changes: action.template,
      id: action.template._id,
    };

    return procedureTemplateAdapter.updateOne<ProcedureTemplateState>(update, state);
  }),
  on(clearState, () => procedureTemplateInitialState)
);

export function procedureTemplateReducer(
  state: ProcedureTemplateState | undefined,
  action: Action
): ProcedureTemplateState {
  return reducer(state, action);
}

export const { selectAll } = procedureTemplateAdapter.getSelectors();
