import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import { FORM_TEMPLATE_FEATURE_KEY, FormTemplateFilter } from './form-template.model';
import { FormTemplateDto } from '../api/models/form-template-dto';
import { FormTemplatePayloadDto } from '../api/models/form-template-payload-dto';

export const formTemplateActions = createActionGroup({
  events: {
    create: props<{ payload: FormTemplatePayloadDto }>(),
    'create success': props<{ formTemplate: FormTemplateDto }>(),
    delete: props<{ templateId: string }>(),
    'delete success': props<{ templateId: string }>(),
    read: emptyProps(),
    'read success': props<{ templates: FormTemplateDto[] }>(),
  },
  source: FORM_TEMPLATE_FEATURE_KEY,
});

export const update = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] update form template`,
  props<{ templateId: string; payload: FormTemplatePayloadDto }>()
);

export const updateSuccess = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] update form template success`,
  props<{ formTemplate: FormTemplateDto }>()
);

export const setSelection = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] set selection for template`,
  props<{ templateId: string; selectedFor: 'GENERAL' | 'CONSULTATION' | null }>()
);

export const setSelectionSuccess = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] set selection for template success`,
  props<{ templates: FormTemplateDto[] }>()
);

export const message = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] present message`,
  props<{ message: string; severity: string }>()
);

export const deleteTemplate = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] delete form template`,
  props<{ templateId: string }>()
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deleteTemplateSuccess = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] delete form template success`,
  props<{ template: FormTemplateDto }>()
);

export const hideTemplate = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] hide form template`,
  props<{ templateId: string }>()
);

export const hideTemplateSuccess = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] hide form template success`,
  props<{ template: FormTemplateDto }>()
);

export const restoreTemplate = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] delete form template`,
  props<{ templateId: string }>()
);

export const restoreTemplateSuccess = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] delete form template success`,
  props<{ template: FormTemplateDto }>()
);

export const setFilter = createAction(
  `[${FORM_TEMPLATE_FEATURE_KEY}] set templates filter value`,
  props<{ filter: FormTemplateFilter }>()
);
