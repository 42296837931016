import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { ProcedureTemplateDto, ProcedureTemplateFacade } from '@fizjo-pro/data-procedure-template';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { Observable } from 'rxjs';

@Component({
  imports: [CommonModule, ButtonModule, DialogModule, TableModule, TranslateModule],
  selector: 'kf-procedure-selector',
  standalone: true,
  styleUrls: ['./procedure-selector.component.scss'],
  templateUrl: './procedure-selector.component.html',
})
export class ProcedureSelectorComponent implements OnInit {
  #procedureTemplateFacade: ProcedureTemplateFacade = inject(ProcedureTemplateFacade);
  #messagesService: MessageService = inject(MessageService);
  #translateService: TranslateService = inject(TranslateService);

  @Output() public templateSelect: EventEmitter<ProcedureTemplateDto> = new EventEmitter<ProcedureTemplateDto>();
  public isVisible = false;

  public template$!: Observable<ProcedureTemplateDto[]>;

  public ngOnInit(): void {
    this.template$ = this.#procedureTemplateFacade.activeOnly$;
  }

  public selectHandler(data: ProcedureTemplateDto): void {
    this.templateSelect.emit(data);
    this.isVisible = false;
    this.#messagesService.add({
      severity: 'success',
      detail: this.#translateService.instant('procedureTemplate.procedureHasBeenAdded', { name: data?.name || '' }),
      summary: this.#translateService.instant('procedureTemplate.procedure'),
    });
  }
}
