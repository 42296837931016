export const mimeTypeToIcon: (mimeType: string) => string = (mimeType: string): string => {
  switch (mimeType) {
    case 'application/pdf':
      return 'pi pi-file-pdf';
    case 'image/png':
      return 'pi pi-image';
    case 'image/jpeg':
      return 'pi pi-image';
    case 'image/svg+xml':
      return 'pi pi-image';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'pi pi-file-word';
    case 'application/msword':
      return 'pi pi-file-word';
    case 'video/mp4':
      return 'pi pi-video';
    case 'audio/mpeg':
      return 'pi pi-microphone';
    default:
      return 'pi pi-file';
  }
};
