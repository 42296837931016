import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import plLocale from '@angular/common/locales/pl';
import { LOCALE_ID, Provider, enableProdMode, importProvidersFrom, APP_INITIALIZER } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { authProviders } from '@fizjo-pro/data-auth';
import { appMessagesProviders } from '@fizjo-pro/data-messages';
import { notificationsProviders } from '@fizjo-pro/data-notifications';
import { BlockUiFacade, BlockUiFeature } from '@fizjo-pro/shared/ui';
import { AppDomainService } from '@fizjo-pro/shared/util-app-domain';
import { bearerTokenInterceptor, httpErrorInterceptor } from '@fizjo-pro/shared/util-intrceptors';
import { LocalCacheService } from '@fizjo-pro/util-appointment';
import { RoleGuard, utilAuthProviders } from '@fizjo-pro/util-auth';
import { appConfigProviders } from '@kate-fizjo/practice-shared/app-config';
import { ApiConfigurationToken } from '@kate-fizjo/practice-shared/environment';
import { MessagesModule } from '@kate-fizjo/practice-shared/message';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule } from 'ngx-cookie';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { environment } from './environments/environment';
import { accountProviders } from '../../../libs/data-account/src/lib/account.providers';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(plLocale);

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appDomainProviderFactory(appDomainService: AppDomainService) {
  return (): Observable<string> => appDomainService.tenantId$();
}

const guards: Provider[] = [RoleGuard];

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pl',
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      useFactory: () => appDomainProviderFactory,
      multi: true,
    },
    {
      provide: ApiConfigurationToken,
      useValue: environment,
    },
    provideRouter(appRoutes, withPreloading(PreloadAllModules)),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([bearerTokenInterceptor, httpErrorInterceptor])),
    importProvidersFrom([
      AngularFireModule.initializeApp(environment.firebaseOptions),
      AngularFirestoreModule.enablePersistence(),
      BrowserModule,
      BrowserAnimationsModule,
      MessagesModule.forRoot(),
      CookieModule.withOptions(),
      TranslateModule.forRoot({
        loader: {
          deps: [HttpClient],
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
        },
      }),
    ]),
    provideStore({}),
    provideState(BlockUiFeature),
    provideStoreDevtools({
      maxAge: 20,
    }),
    ...guards,
    ...accountProviders,
    ...authProviders,
    ...utilAuthProviders,
    ...appConfigProviders,
    ...appMessagesProviders,
    ...notificationsProviders,
    BlockUiFacade,
    DialogService,
    LocalCacheService,
  ],
});
