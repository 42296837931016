import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthFacade } from '../auth.facade';
import { authActions } from '../state/auth.actions';

@Injectable()
export class UserDataResolver extends ResolverHelper {
  constructor(
    store: Store,
    private authFacade: AuthFacade
  ) {
    super(store);
  }

  public override resolve(): Observable<any> {
    return this.authFacade.userReady$.pipe(this.helpResolve(authActions.fetchOwnUser()));
  }
}
