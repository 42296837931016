import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { APPOINTMENT_FEATURE_KEY, GENERAL_FEATURE_KEY } from './appointment.models';
import {
  AppointmentDto,
  AppointmentGeneralPayloadDto,
  AppointmentsListItemDto,
  MovePayloadDto,
  ProcedurePayloadDto,
} from '../api/models';

export const appointmentActions = createActionGroup({
  events: {
    createNote: props<{ appointmentId: string; note: string }>(),
    createNoteSuccess: props<{ appointmentDto: AppointmentDto }>(),
    delete: props<{ appointmentId: string }>(),
    deleteNote: props<{ appointmentId: string; noteId: string }>(),
    deleteNoteSuccess: props<{ appointmentDto: AppointmentDto }>(),
    deleteSuccess: props<{ appointmentId: string }>(),
    fetch: emptyProps(),
    fetchRange: props<{ from: Date; to: Date }>(),
    fetchSingleAppointment: props<{ appointmentId: string }>(),
    fetchSingleAppointmentSuccess: props<{ appointmentDto: AppointmentDto }>(),
    fetchSuccess: props<{ appointments: AppointmentsListItemDto[] }>(),
    fetchAllForPatientSuccess: props<{ appointments: AppointmentsListItemDto[] }>(),
    fetchAllForPatient: props<{ patientId: string }>(),
    shareReport: props<{ emails: string[]; message: string; appointmentId: string }>(),
    shareReportSuccess: emptyProps(),
    updateNote: props<{ appointmentId: string; noteId: string; note: string }>(),
    updateNoteSuccess: props<{ appointmentDto: AppointmentDto }>(),
    move: props<{ payload: MovePayloadDto; appointmentId: string }>(),
    moveSuccess: props<{ appointmentDto: AppointmentDto }>(),
  },
  source: APPOINTMENT_FEATURE_KEY,
});

export const attachmentActions = createActionGroup({
  events: {
    'delete attachments': props<{ appointmentId: string; attachmentId: string[] }>(),
  },
  source: APPOINTMENT_FEATURE_KEY,
});

export const generalActions = createActionGroup({
  events: {
    createGeneral: props<{ payload: AppointmentGeneralPayloadDto; files?: File[]; eventId: string | null }>(),
    'append files': props<{ appointmentId: string; files: File[] }>(),
    'append files success': props<{ appointment: AppointmentDto }>(),
    'create general success': props<{ appointment: AppointmentsListItemDto }>(),
    'update general': props<{
      appointmentId: string;
      payload: AppointmentGeneralPayloadDto;
      files?: File[];
      filesToRemove?: string[];
    }>(),
    'update general success': props<{ appointment: AppointmentDto }>(),
  },
  source: GENERAL_FEATURE_KEY,
});

export const procedureActions = createActionGroup({
  events: {
    createProcedure: props<{ payload: ProcedurePayloadDto; eventId: string | null }>(),
    createProcedureSuccess: props<{ appointment: AppointmentDto }>(),
    updateProcedure: props<{ appointmentId: string; payload: ProcedurePayloadDto }>(),
    updateProcedureSuccess: props<{ appointment: AppointmentDto }>(),
  },
  source: APPOINTMENT_FEATURE_KEY,
});
