import { AbstractControl, ValidationErrors } from '@angular/forms';

import { AddressPayloadDto } from '../../api/models';

export function addressValidator(control: AbstractControl<AddressPayloadDto>): null | ValidationErrors {
  const address: AddressPayloadDto = control.getRawValue();
  const isInvalid: boolean = address.street === '' || address.city === '' || address.postalCode === '';

  return isInvalid ? { address: true } : null;
}
