import { inject, Injectable } from '@angular/core';
import { AccountService } from '@fizjo-pro/admin/data-accounts';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { resourceActions } from './actions';

@Injectable()
export class AccountResourcesEffects {
  #action$: Actions = inject(Actions);
  #accountsService: AccountService = inject(AccountService);
  #messageService: MessageService = inject(MessageService);

  updateResource$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(resourceActions.update),
      switchMap(({ tenantId, payload }) =>
        this.#accountsService.resourceControllerUpdateResource({ tenantId, body: payload })
      ),
      map(account => resourceActions.updateSuccess({ account }))
    );
  });

  updateResourceSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(resourceActions.updateSuccess),
        map(() => {
          this.#messageService.add({ severity: 'success', summary: 'Resource updated' });
        })
      );
    },
    { dispatch: false }
  );
}
