/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { CALENDAR_FEATURE_KEY, CalendarEventState } from './calendar.model';
import { selectAll } from './calendar.reducers';
import { CalendarEventDto } from '../api/models';

export const selectCalendarFeature = createFeatureSelector<CalendarEventState>(CALENDAR_FEATURE_KEY);

export const selectReady = createSelector(selectCalendarFeature, (state: CalendarEventState) => state.ready);

export const selectCalendarEvents = createSelector(selectCalendarFeature, (state: CalendarEventState) =>
  selectAll(state)
);

export const selectCalendarEventsFor = (year: number, month: number) =>
  createSelector(selectCalendarFeature, (state: CalendarEventState) => {
    return selectAll(state).filter(filterEventsByDate(month, year));
  });

export const selectEvent = (eventId: string) =>
  createSelector(selectCalendarFeature, (state: CalendarEventState) => state.entities[eventId]);

export function filterEventsByDate(month: number, year: number): (event: CalendarEventDto) => boolean {
  const dt: DateTime = DateTime.now().set({ month, year });

  return (cEvent: CalendarEventDto) =>
    DateTime.fromISO(cEvent.start) >= dt.startOf('month') && DateTime.fromISO(cEvent.end) <= dt.endOf('month');
}
