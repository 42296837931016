import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NotificationDto } from './api/models/notification-dto';
import { NotificationsService } from './api/services';

@Injectable({ providedIn: 'root' })
export class NotificationsFacade {
  #notificationsService: NotificationsService = inject(NotificationsService);

  public getNotificationsForGivenEvent$(eventId: string): Observable<NotificationDto[]> {
    return this.#notificationsService.notificationsControllerReadForCalendarEvent({ eventId });
  }
}
