export interface Attachment {
  _id: string;
  createdAt: string;
  encoding: string;
  mimetype: string;
  originalname: string;
}

export enum AcceptMime {
  IMAGE = 'image/*',
  VIDEO = 'video/*',
  DOCUMENTS = 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
  AUDIO = 'audio/*',
  ALL = 'images/*,video/*,audio/*,application/pdf application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
}
