import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import ms from 'ms';
import { CookieService } from 'ngx-cookie';
import { delay, Observable, of, Subscription, tap } from 'rxjs';

import { authActions } from './state/auth.actions';
import { ScreenLockType } from './state/auth.model';
import { selectScreenLock } from './state/auth.selectors';
import { TokenPayload } from './token-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class ScreenLockService {
  #store: Store = inject(Store);
  #checkTokenSubscription!: Subscription;
  #cookies: CookieService = inject(CookieService);
  public readonly screenLock$: Observable<ScreenLockType> = this.#store.select(selectScreenLock);

  public checkToken(): void {
    this.#checkTokenSubscription = this.#checkToken$().subscribe();
  }

  public unsubscribeCheckToken(): void {
    this.#checkTokenSubscription?.unsubscribe();
  }

  public unlock(): void {
    this.#store.dispatch(authActions.unlockScreen());
  }

  public lock(lockType: ScreenLockType): void {
    this.#store.dispatch(authActions.lockScreen({ lockType }));
    this.unsubscribeCheckToken();
    this.#cookies.remove('jwt');
  }

  #checkToken$(): Observable<void> {
    const token: string | undefined = this.#cookies.get('jwt');

    if (token) {
      const payload: TokenPayload = jwtDecode<TokenPayload>(token);

      if (payload && DateTime.fromSeconds(payload.exp).minus({ second: 5 }) >= DateTime.local()) {
        return of(void 0).pipe(
          delay(ms('2s')),
          tap(() => {
            const sessionTimeout: number = DateTime.fromSeconds(payload.exp)
              .diff(DateTime.local())
              .minus({ second: 5 })
              .as('minutes');

            if (sessionTimeout <= 6) {
              this.#store.dispatch(authActions.unlockScreen());
              this.#store.dispatch(authActions.setSessionTimeout({ sessionTimeout }));
            }
            this.checkToken();
          })
        );
      } else {
        this.#store.dispatch(authActions.lockScreen({ lockType: ScreenLockType.EXP }));
        this.#store.dispatch(authActions.setSessionTimeout({ sessionTimeout: 0 }));
      }
    } else {
      this.#store.dispatch(authActions.lockScreen({ lockType: ScreenLockType.NONE }));
    }
    this.#checkTokenSubscription?.unsubscribe();

    return of(void 0);
  }
}
