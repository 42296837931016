import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import humanizeDuration from 'humanize-duration';
import { Duration } from 'luxon';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  imports: [CommonModule, TooltipModule, TranslateModule],
  selector: 'fizjo-pro-progress-bar',
  standalone: true,
  styleUrls: ['./progress-bar.component.scss'],
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent {
  @Input() timeout!: number;
  @Input() total!: number;

  public get timeLeft(): string {
    const milis: number = Duration.fromObject({ minutes: this.timeout }).as('milliseconds');

    return humanizeDuration(milis, { language: 'pl', round: true, units: ['m', 's'] });
  }

  public get widthStyle(): Record<string, string> {
    return {
      width: `${(this.timeout / this.total) * 100}%`,
    };
  }
}
