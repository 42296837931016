import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { FormTemplateFacade } from './form-template.facade';
import { FormTemplateResolver } from './form-template.resolver';
import { FormTemplateEffects } from './state/form-template.effects';
import { FORM_TEMPLATE_FEATURE_KEY } from './state/form-template.model';
import { formTemplateReducer } from './state/form-template.reducer';

export const formTemplateProviders: (Provider | EnvironmentProviders)[] = [
  FormTemplateFacade,
  FormTemplateResolver,
  provideState(FORM_TEMPLATE_FEATURE_KEY, formTemplateReducer),
  provideEffects([FormTemplateEffects]),
  importProvidersFrom(ApiModule),
];
