import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { loadPatients } from './state/patient.actions';
import { selectReady } from './state/patient.selectors';

@Injectable()
export class AllPatientsResolver extends ResolverHelper {
  constructor(store: Store) {
    super(store);
  }

  public override resolve(): Observable<any> {
    return this.store.select(selectReady).pipe(this.helpResolve(loadPatients()));
  }
}
