<router-outlet></router-outlet>
<p-toast></p-toast>
<fizjo-pro-block-ui></fizjo-pro-block-ui>
<fizjo-pro-screen-lock></fizjo-pro-screen-lock>
<ng-container *rxLet="sessionTimout$; let sessionTimeout">
  <fizjo-pro-progress-bar
    class=""
    *rxIf="timeoutVisible$"
    [timeout]="sessionTimeout"
    [total]="5"></fizjo-pro-progress-bar>
</ng-container>
<div class="fixed top-0 left-0 w-full" *ngIf="isProgressVisible()">
  <p-progressBar mode="indeterminate" [style]="{ height: '8px' }"></p-progressBar>
</div>
