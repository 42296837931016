/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CanActivateDto } from '../models/can-activate-dto';
import { RefreshTokenPayloadDto } from '../models/refresh-token-payload-dto';
import { SigninDto } from '../models/signin-dto';
import { TokenDto } from '../models/token-dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation authControllerAuthenticate
   */
  static readonly AuthControllerAuthenticatePath = '/api/auth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerAuthenticate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerAuthenticate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: SigninDto;
  }): Observable<StrictHttpResponse<TokenDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthControllerAuthenticatePath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TokenDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerAuthenticate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerAuthenticate(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: SigninDto;
  }): Observable<TokenDto> {
    return this.authControllerAuthenticate$Response(params).pipe(
      map((r: StrictHttpResponse<TokenDto>) => r.body as TokenDto)
    );
  }

  /**
   * Path part for operation authControllerCheckEmail
   */
  static readonly AuthControllerCheckEmailPath = '/api/auth/check-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerCheckEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerCheckEmail$Response(params: {
    /**
     * User email address
     */
    email: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthControllerCheckEmailPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerCheckEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerCheckEmail(params: {
    /**
     * User email address
     */
    email: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.authControllerCheckEmail$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation authControllerRefreshAuthToken
   */
  static readonly AuthControllerRefreshAuthTokenPath = '/api/auth/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRefreshAuthToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRefreshAuthToken$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: RefreshTokenPayloadDto;
  }): Observable<StrictHttpResponse<TokenDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthControllerRefreshAuthTokenPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TokenDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerRefreshAuthToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRefreshAuthToken(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: RefreshTokenPayloadDto;
  }): Observable<TokenDto> {
    return this.authControllerRefreshAuthToken$Response(params).pipe(
      map((r: StrictHttpResponse<TokenDto>) => r.body as TokenDto)
    );
  }

  /**
   * Path part for operation authControllerRequestMfa
   */
  static readonly AuthControllerRequestMfaPath = '/api/auth/mfa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRequestMfa()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerRequestMfa$Response(params: {
    'x-tenant-id': string;

    /**
     * User email address
     */
    email: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthControllerRequestMfaPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.query('email', params.email, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerRequestMfa$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerRequestMfa(params: {
    'x-tenant-id': string;

    /**
     * User email address
     */
    email: string;
    context?: HttpContext;
  }): Observable<{}> {
    return this.authControllerRequestMfa$Response(params).pipe(map((r: StrictHttpResponse<{}>) => r.body as {}));
  }

  /**
   * Path part for operation authControllerCanActivate
   */
  static readonly AuthControllerCanActivatePath = '/api/auth/can-activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerCanActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerCanActivate$Response(params: {
    /**
     * The path to be checked
     */
    path: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<CanActivateDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthControllerCanActivatePath, 'get');
    if (params) {
      rb.query('path', params.path, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CanActivateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerCanActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerCanActivate(params: {
    /**
     * The path to be checked
     */
    path: string;
    context?: HttpContext;
  }): Observable<CanActivateDto> {
    return this.authControllerCanActivate$Response(params).pipe(
      map((r: StrictHttpResponse<CanActivateDto>) => r.body as CanActivateDto)
    );
  }
}
