import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

export enum RtfEditorButtons {
  FONT = 'ql-font',
  SIZE = 'ql-size',
  BOLD = 'ql-bold',
  ITALIC = 'ql-italic',
  UNDERLINE = 'ql-underline',
  STRIKE = 'ql-strike',
  COLOR = 'ql-color',
  BACKGROUND = 'ql-background',
  LIST_ORDERED = 'ql-list_ordered',
  LIST_BULLET = 'ql-list_bullet',
  ALIGN_CENTER = 'ql-align-center',
  ALIGN = 'ql-align',
  ALIGN_RIGHT = 'ql-align-right',
  JUSTIFY = 'ql-align-justify',
  LINK = 'ql-link',
}

@Component({
  selector: 'fizjo-pro-rtf-editor-buttons',
  template: ` <div class="ql-formats">
      <select class="ql-font" *ngIf="has(bttns.FONT)"></select>
      <select class="ql-size" *ngIf="has(bttns.SIZE)"></select>
    </div>
    <div class="ql-formats">
      <button class="ql-bold" *ngIf="has(bttns.BOLD)"></button>
      <button class="ql-italic" *ngIf="has(bttns.ITALIC)"></button>
      <button class="ql-underline" *ngIf="has(bttns.UNDERLINE)"></button>
      <button class="ql-strike" *ngIf="has(bttns.STRIKE)"></button>
      <select class="ql-color" *ngIf="has(bttns.COLOR)"></select>
      <select class="ql-background" *ngIf="has(bttns.BACKGROUND)"></select>
    </div>
    <div class="ql-formats">
      <button class="ql-list" value="ordered" type="button" *ngIf="has(bttns.LIST_ORDERED)"></button>
      <button class="ql-list" value="bullet" type="button" *ngIf="has(bttns.LIST_BULLET)"></button>
      <select class="ql-align" *ngIf="has(bttns.ALIGN)">
        <option selected></option>
        <option value="center" *ngIf="has(bttns.ALIGN_CENTER)"></option>
        <option value="right" *ngIf="has(bttns.ALIGN_RIGHT)"></option>
        <option value="justify" *ngIf="has(bttns.JUSTIFY)"></option>
      </select>
    </div>
    <div class="ql-formats">
      <button class="ql-link" *ngIf="has(bttns.LINK)"></button>
    </div>`,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class RtfEditorButtonSetComponent {
  public static BaseButtons: RtfEditorButtons[] = [
    RtfEditorButtons.ALIGN,
    RtfEditorButtons.ALIGN_RIGHT,
    RtfEditorButtons.ALIGN_CENTER,
    RtfEditorButtons.JUSTIFY,
    RtfEditorButtons.BOLD,
    RtfEditorButtons.STRIKE,
    RtfEditorButtons.ITALIC,
    RtfEditorButtons.UNDERLINE,
  ];
  public static ListButtons: RtfEditorButtons[] = [RtfEditorButtons.LIST_BULLET, RtfEditorButtons.LIST_ORDERED];
  public static ColorButtons: RtfEditorButtons[] = [RtfEditorButtons.BACKGROUND, RtfEditorButtons.COLOR];

  public static FullButtonsSet: RtfEditorButtons[] = [
    ...RtfEditorButtonSetComponent.BaseButtons,
    ...RtfEditorButtonSetComponent.ListButtons,
    ...RtfEditorButtonSetComponent.ColorButtons,
    RtfEditorButtons.LINK,
  ];

  @Input() public buttons: RtfEditorButtons[] = RtfEditorButtonSetComponent.FullButtonsSet;
  protected bttns: typeof RtfEditorButtons = RtfEditorButtons;

  protected has(type: RtfEditorButtons): boolean {
    return this.buttons && this.buttons.length > 0 && this.buttons.includes(type);
  }
}
