import { clearState } from '@fizjo-pro/data-auth';
import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { appUsersActions } from './app-users.actions';
import { AppUsersState } from './app-users.model';
import { UserDto } from '../api/models/user-dto';

export const appUserAdapter = createEntityAdapter<UserDto>({
  selectId: (userDto: UserDto) => userDto._id,
});

export const initialState: AppUsersState = {
  ...appUserAdapter.getInitialState(),
  ready: false,
};

export const reducer = createReducer(
  initialState,
  on(appUsersActions.createSuccess, (state, action) => ({
    ...appUserAdapter.upsertOne(action.user, state),
    ready: true,
  })),
  on(appUsersActions.fetchSuccess, (state, action) => ({
    ...appUserAdapter.upsertMany(action.users, state),
    ready: true,
  })),
  on(appUsersActions.updateSuccess, appUsersActions.saveMyDataSuccess, (state, action) => ({
    ...state,
    ...appUserAdapter.updateOne({ changes: action.user, id: action.user._id }, state),
  })),
  on(appUsersActions.patchPinCodeSuccess, (state, action) => ({
    ...state,
    ...appUserAdapter.upsertOne(action.user, state),
  })),
  on(clearState, () => initialState)
);

export function appUsersReducers(state: AppUsersState | undefined, action: Action): AppUsersState {
  return reducer(state, action);
}

export const { selectAll } = appUserAdapter.getSelectors();
