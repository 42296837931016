import { createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from './form-template.actions';
import { formTemplateActions } from './form-template.actions';
import { FormTemplateFilter, FormTemplateState } from './form-template.model';
import { FormTemplateDto } from '../api/models/form-template-dto';

export const formTemplateAdapter = createEntityAdapter<FormTemplateDto>({
  selectId: (template: FormTemplateDto) => template._id,
});

export const formTemplateInitialState: FormTemplateState = {
  ...formTemplateAdapter.getInitialState(),
  filter: FormTemplateFilter.ALL,
  ready: false,
};

const reducer = createReducer(
  formTemplateInitialState,
  on(formTemplateActions.createSuccess, (state: FormTemplateState, action) => ({
    ...formTemplateAdapter.upsertOne(action.formTemplate, state),
    ready: true,
  })),
  on(formTemplateActions.readSuccess, (state: FormTemplateState, action) => ({
    ...formTemplateAdapter.addMany(action.templates, state),
    ready: true,
  })),
  on(Actions.updateSuccess, (state: FormTemplateState, action) => {
    const update: Update<FormTemplateDto> = {
      changes: action.formTemplate,
      id: action.formTemplate._id,
    };

    return formTemplateAdapter.updateOne<FormTemplateState>(update, state);
  }),
  on(Actions.setSelectionSuccess, (state: FormTemplateState, action) => {
    const updates: Update<FormTemplateDto>[] = action.templates.map((template: FormTemplateDto) => ({
      changes: template,
      id: template._id,
    }));

    return formTemplateAdapter.updateMany(updates, state);
  }),
  on(Actions.hideTemplateSuccess, (state: FormTemplateState, action) => {
    const update: Update<FormTemplateDto> = {
      changes: action.template,
      id: action.template._id,
    };

    return formTemplateAdapter.updateOne(update, state);
  }),
  on(Actions.restoreTemplateSuccess, (state: FormTemplateState, action) => {
    const update: Update<FormTemplateDto> = {
      changes: action.template,
      id: action.template._id,
    };

    return formTemplateAdapter.updateOne(update, state);
  }),
  on(formTemplateActions.deleteSuccess, (state: FormTemplateState, { templateId }) =>
    formTemplateAdapter.removeOne(templateId, state)
  )
);

export function formTemplateReducer(state: FormTemplateState | undefined, action: Action): FormTemplateState {
  return reducer(state, action);
}

export const { selectAll } = formTemplateAdapter.getSelectors();
