import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { adminAccountsActions, licenseActions, resourceActions } from './actions';
import { AdminAccountsState } from './model';
import { AccountDto } from '../api/models/account-dto';

export const accountsAdapter = createEntityAdapter<AccountDto>({
  selectId: (account: AccountDto) => account._id,
});
export const initialState: AdminAccountsState = {
  accounts: accountsAdapter.getInitialState(),
  multiSelect: [],
  ready: false,
  selectedAccount: null,
};
export const reducers = createReducer(
  initialState,
  on(adminAccountsActions.readSuccess, (state: AdminAccountsState, action) => ({
    ...state,
    accounts: accountsAdapter.upsertMany(action.accounts, state.accounts),
    ready: true,
  })),
  on(adminAccountsActions.selectAccount, (state: AdminAccountsState, action) => ({
    ...state,
    multiSelect: [],
    selectedAccount: action.accountId,
  })),
  on(adminAccountsActions.createAccountSuccess, (state: AdminAccountsState, action) => ({
    ...state,
    accounts: accountsAdapter.upsertOne(action.account, state.accounts),
  })),
  on(adminAccountsActions.selectMulti, (state: AdminAccountsState, action) => ({
    ...state,
    multiSelect: action.accounts,
    selectedAccount: null,
  })),
  on(
    resourceActions.updateSuccess,
    licenseActions.updateSuccess,
    licenseActions.replaceSuccess,
    (state: AdminAccountsState, action) => ({
      ...state,
      accounts: accountsAdapter.updateOne({ id: action.account._id, changes: action.account }, state.accounts),
    })
  )
);

export const { selectAll } = accountsAdapter.getSelectors();

export function accountsReducer(state: AdminAccountsState | undefined, action: Action): AdminAccountsState {
  return reducers(state, action);
}
