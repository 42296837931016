import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AccountFacade } from '@fizjo-pro/data-account';
import { Permission, Role } from '@fizjo-pro/data-auth';
import { MeService } from '@fizjo-pro/util-auth';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Observable, map, combineLatest } from 'rxjs';

import { settingsMenuItems } from './settings-items';

@Injectable({ providedIn: 'root' })
export class SettingsItemsService {
  #translateService: TranslateService = inject(TranslateService);
  #accountFacade: AccountFacade = inject(AccountFacade);

  public readonly items$: Observable<MenuItem[]>;

  currentLicenseProductCode$ = this.#accountFacade.selectAccountCurrentLicenseProductCode$;
  currentLicenseProductCode = toSignal(this.currentLicenseProductCode$);

  constructor(private meService: MeService) {
    this.items$ = this.myItem$(settingsMenuItems).pipe(
      map(items => this.#disableItemsBasedOnLicenseProductCode(items)),
      map(items => this.#i18nRecursive(items))
    );
  }

  public myItem$(items: MenuItem[]): Observable<MenuItem[]> {
    return combineLatest([this.meService.role$, this.meService.permissions$, this.currentLicenseProductCode$]).pipe(
      map(([role, permissions]) => this.#meMenuItems(items, role, permissions))
    );
  }

  #disableItemsBasedOnLicenseProductCode(items: MenuItem[]): MenuItem[] {
    return items.map(item => {
      if (item.state?.['disabledForLicenses']) {
        item.disabled = item.state?.['disabledForLicenses'].includes(this.currentLicenseProductCode());
      }
      if (item.state?.['isDisabledWhenNoValidLicense']) {
        item.disabled = !this.currentLicenseProductCode();
      }

      return {
        ...item,
        items: item.items === undefined ? undefined : this.#disableItemsBasedOnLicenseProductCode(item.items),
      };
    });
  }
  #meMenuItems(items: MenuItem[], role: Role, permissions: Permission[]): MenuItem[] {
    if (role === Role.Owner) return items;
    const _items: MenuItem[] = [];

    items.forEach((item: MenuItem) => {
      const itemPermission: Permission | undefined = item.state?.['permission'];

      if (itemPermission === undefined || permissions.includes(itemPermission)) {
        const _item: MenuItem = {
          ...item,
          items: item.items === undefined ? undefined : this.#meMenuItems(item.items, role, permissions),
        };

        if (_item.routerLink || _item.items) {
          _items.push(_item.items?.length === 1 ? _item.items[0] : _item);
        }
      }
    });

    return _items.length > 0 ? _items : [];
  }

  #i18nRecursive(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map((item: MenuItem) => ({
      ...item,
      label: item.label ? this.#translateService.instant(item.label) : undefined,
      items: item.items === undefined ? undefined : this.#i18nRecursive(item.items),
    }));
  }
}
