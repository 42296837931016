import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { appConfigFeature } from './app-config.feature';
import { AppConfigResolver } from './app-config.resolver';
import { AppConfigService } from './app-config.service';
import { PermissionMatrixResolver } from './permission-matrix.resolver';
import { AppConfigEffects, AppConfigFacade } from './state';
import { NotificationsSettingsEffects } from './state/notifications-settings.effects';

export const appConfigProviders: (Provider | EnvironmentProviders)[] = [
  AppConfigResolver,
  PermissionMatrixResolver,
  AppConfigService,
  AppConfigFacade,
  provideState(appConfigFeature),
  provideEffects([AppConfigEffects, NotificationsSettingsEffects]),
  importProvidersFrom(ApiModule),
];
