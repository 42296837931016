import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { appConfigActions as actions, contactPointActions } from './app-config.actions';
import {
  selectCompanyName,
  selectContactEmail,
  selectContactPhoneNumber,
  selectFeats,
  selectHasFeature,
  selectPermissionMatrix,
} from './app-config.selectors';
import { AppFeatureDto, ContactPointDto, ContactPointPayloadDto } from '../api/models';

@Injectable()
export class AppConfigFacade {
  public feat$!: Observable<AppFeatureDto[]>;
  public companyName$!: Observable<string>;
  public contactEmail$!: Observable<ContactPointDto | undefined>;
  public contactPhoneNumber$!: Observable<ContactPointDto | undefined>;
  public permissionMatrix$!: Observable<Record<string, string[]> | null>;

  constructor(private store: Store) {
    this.feat$ = this.store.select(selectFeats);
    this.companyName$ = this.store.select(selectCompanyName);
    this.contactEmail$ = this.store.select(selectContactEmail);
    this.contactPhoneNumber$ = this.store.select(selectContactPhoneNumber);
    this.permissionMatrix$ = this.store.select(selectPermissionMatrix);
  }

  public init(): void {
    this.store.dispatch(actions.fetchAppConfig());
  }

  public createContactPoint(contactPoint: ContactPointPayloadDto): void {
    this.store.dispatch(contactPointActions.createContactPoint({ contactPoint }));
  }

  public updateContactPoint(contactPoint: ContactPointPayloadDto, id: string): void {
    this.store.dispatch(contactPointActions.updateContactPoint({ contactPoint, id }));
  }

  public deleteContactPoint(contactPointId: string): void {
    this.store.dispatch(contactPointActions.deleteContactPoint({ contactPointId }));
  }
  public hasFeature$(featName: string): Observable<boolean> {
    return this.store.select(selectHasFeature(featName));
  }
}
