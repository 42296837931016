import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export interface PasswordForm {
  password: FormControl<string>;
  passwordRepeat: FormControl<string>;
}

export function passwordFormValidator(control: AbstractControl): null | ValidationErrors {
  const passwordForm: FormGroup<PasswordForm> = control as FormGroup<PasswordForm>;

  if (passwordForm.controls.password.value !== passwordForm.controls.passwordRepeat.value) {
    return {
      'password-mismatch': true,
    };
  }

  return null;
}

export function passwordFormFactory(): FormGroup<PasswordForm> {
  return new FormGroup<PasswordForm>(
    {
      password: new FormControl<string>('', { nonNullable: true }),
      passwordRepeat: new FormControl<string>('', { nonNullable: true }),
    },
    { validators: passwordFormValidator }
  );
}
