import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppointmentsListItemDto } from '../api/models/appointments-list-item-dto';
import { AppointmentService } from '../api/services';
import { appointmentActions } from '../state/appointment.actions';

@Injectable()
export class PatientAppointmentsResolver implements Resolve<Date[]> {
  #api = inject(AppointmentService);
  #store: Store = inject(Store);

  resolve(route: ActivatedRouteSnapshot): Observable<Date[]> {
    return this.#api
      .appointmentsControllerReadAllForPatient({
        patientId: route.paramMap.get('patientId') || '',
      })
      .pipe(
        tap((appointments: AppointmentsListItemDto[]) => {
          this.#store.dispatch(appointmentActions.fetchSuccess({ appointments }));
        }),
        map((appointments: AppointmentsListItemDto[]) => [
          DateTime.fromISO(appointments[appointments.length - 1].start).toJSDate(),
          DateTime.fromISO(appointments[0].start).toJSDate(),
        ])
      );
  }
}
